import React from "react";
import styled from "styled-components";
import { BsThreeDots } from "react-icons/bs";
import {Dropdown, Menu} from "antd";

const Line = styled.div`
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    flex-grow: ${props => props.expand ? 1 : 0};
    width: ${props => props.expand ? 'auto' : '20px'};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    ${p => p.main &&
    `
      margin: 20px 0;
      ${Line}{
        background-color: black;
        height: 2px;
      }
    `
}
`;

const Content = styled.div`
    padding: 0 15px;
    flex-shrink: 0;
    &:first-child {
        padding-left: 0;
        text-align: left;
    }
    &:last-child {
        padding-right: 0;
        text-align: right;
    }
`;

const CustomDivider = ({ leftContent, rightContent, menuItems, main = false }) => {
  const menu = (
    <Menu items={menuItems} />
  );
  
  const menuDropdown = (
    <Dropdown dropdownRender={() => menu} trigger={['click']} placement="bottom">
      <div style={{ cursor: 'pointer' }}>
        <BsThreeDots size={18} />
      </div>
    </Dropdown>
  );
  
  return (
    <Wrapper main={main}>
      <Line />
      <Content>{leftContent}</Content>
      <Line expand />
      {(menuItems || rightContent) && <Content>{menuItems ? menuDropdown : rightContent}</Content>}
      <Line />
    </Wrapper>
  );
};

export default CustomDivider;
