import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import CustomDivider from "../../../../shared/components/CustomDivider";
import translation from "../../../../shared/utils/translation";
import ParagraphBold from "../../../../shared/components/text/ParagraphBold";
import OutlinePlusIcon from "../../../../shared/components/icons/OutlinePlusIcon";
import ModalStore from "../../../../shared/stores/ModalStore";
import ProjectStore from "../../../../shared/stores/ProjectStore";
import TextDisplay from "../../../../shared/components/TextDisplay";
import EditIconFillPen from "../../../../shared/components/icons/EditIconFillPen";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import {useQuery} from "../../../../shared/utils/useQuery";
import ComponentStore from "../../../../shared/stores/ComponentStore";


const NotesContent = () => {
  const queryParams = useQuery();
  const componentId = queryParams.get("component_id");
  
  let {currentNote: projectCurrentNote, isFetching: projectNoteFetching} = ProjectStore
  let {currentNote: componentCurrentNote, isFetching: componentNoteFetching} = ComponentStore
  
  useEffect(() => {
    if(!componentId){
      ProjectStore.getNote();
    }else{
      ComponentStore.getNote(componentId);
    }
  }, [componentId]);
  
  const addNote = () => {
    ModalStore.addModal({ modalTopic: componentId ? "component" : "project", modalName: "addNote" });
  }
  const editNote = () => {
    ModalStore.addModal({ modalTopic: componentId ? "component" : "project", modalName: "editNote" });
  }
  
  const currentNote = componentId ? componentCurrentNote : projectCurrentNote;
  const noteExists = currentNote && currentNote.length > 0;
  
  return (
    <>
      <CustomDivider
        main={true}
        leftContent={<ParagraphBold>{translation("project.notes.private")}</ParagraphBold>}
        rightContent={noteExists ? <EditIconFillPen onClick={editNote}/> : <OutlinePlusIcon onClick={addNote}/>}
      />
      <TextDisplay text={currentNote} />
    </>
  );
}

export default observer(NotesContent)