import translation from "./translation";

export const validateMessages = {
  empty: translation("form.field.error.message.empty"),
  email:{
    required: translation("form.field.error.message.empty"),
    invalid: translation("form.email.error.message.incorrectFormat")
  },
  user:{
    doNotExist: translation("form.field.error.message.userDoesNotExist")
  },
  role: {
    doesNotExist: translation("form.field.error.message.roleDoesNotExist")
  },
  unavailable: {
    email: translation("form.email.error.message.notAvailable"),
    username: translation("form.username.error.message.notAvailable")
  },
  deleteModalInput:{
    permSafeCheck: translation("form.field.perm.delete.error.wrongInput"),
    softSafeCheck: translation("form.field.soft.delete.error.wrongInput")
  }
};