export const AVAILABLE_ROLES = {
  project: [
    {key: 0, value: "1 - Viewer"},
    {key: 1, value: "2 - Editor"},
    {key: 2, value: "3 - Admin"}
  ],
  groups: [
    {key: 0, value: "1 - Viewer"},
    {key: 1, value: "2 - Editor"},
    {key: 2, value: "3 - Admin"}
  ]
}

export const ADMINISTRATOR_ROLES = ["admin", "owner"]