import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { IoReturnUpBack } from "react-icons/io5";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ProjectStore from "../../shared/stores/ProjectStore";
import ModalStore from "../../shared/stores/ModalStore";
import ComponentStore from "../../shared/stores/ComponentStore";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import { MdGroupAdd, MdDelete } from "react-icons/md";
import {useQuery} from "../../shared/utils/useQuery";
import { IoSettingsSharp } from "react-icons/io5";
import Dropdown from "../../shared/components/Dropdown";
import translation from "../../shared/utils/translation";
import ProjectOverviewContent from "./content/project/ProjectOverviewContent";
import ProjectUsersContent from "./content/project/ProjectUsersContent";
import ProjectHistoryContent from "./content/project/ProjectHistoryContent";
import {ADMINISTRATOR_ROLES, AVAILABLE_ROLES} from "../../shared/utils/Constants";
import ProjectInvitationsContent from "./content/project/ProjectInvitationsContent";
import { IoExit } from "react-icons/io5";
import {Alert, Input} from "antd";
import {debounce} from "lodash";
import NotesContent from "./content/shared/NotesContent";


const Wrapper = styled.div`
  margin-top: 48px;
`;
const ReturnIcon = styled(IoReturnUpBack)`
  font-size: 25px;
  cursor: pointer;
`;
const EditIcon = styled(AiFillEdit)`
  font-size: 25px;
  cursor: pointer;
  margin-left: 25px;
`;
const SettingsIcon = styled(IoSettingsSharp)`
    font-size: 22px;
    cursor: pointer;
    margin-left: 25px;
`

const AddUserToProjectIcon = styled(MdGroupAdd)`
    font-size: 25px;
    cursor: pointer;
    margin-left: 25px;
`
const LeaveIcon = styled(IoExit)`
    font-size: 25px;
    cursor: pointer;
    margin-left: 25px;
`

const DeleteIcon = styled(MdDelete)`
    font-size: 25px;
`

const DeleteWrapper = styled.div`
    display: flex;
    align-content: center;
    cursor: pointer;
    font-weight: 800;
`

const IconWrapper = styled.div`
  display: flex;
    flex-direction: row;
    margin-bottom: 25px;
`

const DropdownWrapper = styled.div`

`

const ProjectHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

`
const HeaderLeftSide = styled.div`
  display:flex;
  flex-direction: row;
`
const HeaderRightSide = styled.div`
`
const CustomInput = styled(Input)`
    width: 200px;
    border-radius: 8px;
`

const AlertWrapper = styled.div`
  margin-bottom: 25px;
`

const ProjectOverview = observer(() => {
  const navigate = useNavigate();
  const queryParams = useQuery();
  const projectId = queryParams.get("project_id");
  const pageContent = queryParams.get("content");
  const status = queryParams.get("active");
  const [currentProject, setCurrentProject] = useState(null);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  
  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedSearch = debounce(() => {
    if(pageContent == "users"){
      ProjectStore.getProjectUsers(projectId, searchTerm)
    }else if(pageContent == "invitations"){
      ProjectStore.getInvitedUsers(projectId, status, searchTerm)
    }
    
  }, 500);
  
  useEffect(() => {
    if (searchTerm != null) {
      debouncedSearch();
    }
    
    return () => debouncedSearch.cancel();
  }, [searchTerm]);
  
  useEffect(() => {
    ComponentStore.reset();
    getProject();
  }, [projectId]);
  
  const getProject = async () => {
    let currentProject = await ProjectStore.getProject(projectId);
    setCurrentProject(currentProject);
    setProjectLoaded(true);
  };
  
  const returnIconClick = () => {
    if(pageContent == "history" || pageContent == "users"){
      return navigate(`/project?project_id=${projectId}`)
    }
    
    let path = "/project"
    const activeTab = sessionStorage.getItem('projectActiveTab');
    if(activeTab != null && activeTab != "all" ){
      path = `/project?activeTab=${activeTab}`
    }
    navigate(path)
  }
  
  const editProject = () => {
    ModalStore.addModal({ modalTopic: "project", modalName: "edit" });
  };
  
  const addUserOnClick = useCallback(() => {
    ModalStore.addModal({modalTopic: "project", modalName: "inviteUser"});
  }, []);
  
  const leaveProject = () => {
    ModalStore.addModal({ modalTopic: "project", modalName: "leaveProject" });
  }
  
  const deleteProject = () => {
    ModalStore.addModal({ modalTopic: "project", modalName: "deleteProject" });
  }
  
  const dropdownOptions = [
    {
      key: 1,
      text: translation("dropdown.project.users"),
      onClick: () => {
        navigate(`/project?project_id=${projectId}&content=users`)
      },
      display: currentProject && (ADMINISTRATOR_ROLES.includes(currentProject.role) && !currentProject.is_deleted)
    },
    {
      key: 2,
      text: translation("dropdown.project.history"),
      onClick: () => {
        navigate(`/project?project_id=${projectId}&content=history`)
      },
      display: currentProject && (ADMINISTRATOR_ROLES.includes(currentProject.role) && !currentProject.is_deleted)
    },
    {
      key: 3,
      text: translation("dropdown.project.invitations"),
      onClick: () => {
        navigate(`/project?project_id=${projectId}&content=invitations&active=true`)
      },
      display: currentProject && (ADMINISTRATOR_ROLES.includes(currentProject.role) && !currentProject.is_deleted)
    },
    {
      key: 4,
      text: translation("dropdown.project.notes"),
      onClick: () => {
        navigate(`/project?project_id=${projectId}&content=notes`)
      },
      display: currentProject && currentProject.role
    }
  ]
  
  const loadPageContent = () => {
    switch(pageContent){
      case "users":
        return <ProjectUsersContent projectId={projectId}/>
      case "history":
        return <ProjectHistoryContent/>
      case "invitations":
        return <ProjectInvitationsContent/>
      case "notes":
        return <NotesContent currentProject={currentProject}/>
      default:
        return <ProjectOverviewContent currentProject={currentProject}/>
    }
  }
  
  const onSearchInput = (value) => {
    setSearchTerm(value);
  };
  
  if (!currentProject || ProjectStore.isLoading || !projectLoaded || (currentProject && currentProject.length == 0)) {
    return <LoadingSpinner/>;
  }
  
  return (
    <Wrapper>
      {
        currentProject.is_deleted === 1 &&
        <AlertWrapper>
          <Alert
            message={translation("alert.project.being.deleted")}
            type={"error"}
            description={translation("alert.project.being.deleted.description")}
          />
        </AlertWrapper>
      }

      <IconWrapper>
        <ProjectHeaderWrapper>
          <HeaderLeftSide>
            <ReturnIcon onClick={() => returnIconClick()} />
            {(currentProject.is_deleted === 0 && currentProject.canEdit) && <EditIcon onClick={editProject}/>}
            {(currentProject.is_deleted === 0 && ADMINISTRATOR_ROLES.includes(currentProject.role)) && <AddUserToProjectIcon onClick={addUserOnClick}/>}
            {currentProject.project_type == "shared" && <LeaveIcon onClick={() => leaveProject()} />}
            <DropdownWrapper>
              <SettingsIcon onClick={() => setDropdownIsOpen(true)}/>
              <Dropdown isOpen={dropdownIsOpen} setIsOpen={setDropdownIsOpen} options={dropdownOptions}/>
            </DropdownWrapper>
          </HeaderLeftSide>
          <HeaderRightSide>
            {(currentProject.is_deleted === 0 && ADMINISTRATOR_ROLES.includes(currentProject.role)) &&
              <DeleteWrapper onClick={() => deleteProject()}>
                <DeleteIcon />
                {translation("common.delete")}
              </DeleteWrapper>
            }
            {(pageContent == "users" || pageContent == "invitations") &&
              <CustomInput
                placeholder={translation("input.placeholder.searchUser")}
                onChange={(e) => onSearchInput(e.target.value)}
              />}
          </HeaderRightSide>
        </ProjectHeaderWrapper>
      </IconWrapper>
      <div>
        {loadPageContent()}
      </div>
    </Wrapper>
  );
});

export default ProjectOverview;