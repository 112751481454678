import React from "react";
import { sizes } from "../../../../shared/utils/media";
import TextButton from "../../../../shared/components/buttons/TextButton";
import { gutter, menuTextColor } from "../../../../shared/utils/vars";
import { IoSettingsOutline } from "react-icons/io5";
import styled from "styled-components";
import translation from "../../../../shared/utils/translation";
import PersonIcon from "../../../../shared/components/icons/PersonIcon";
import { useNavigate } from "react-router-dom";
import { MdGroups, MdDelete } from "react-icons/md";
import { useQuery } from "../../../../shared/utils/useQuery";

const MenuContentContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    @media only screen and (max-width: ${sizes.tablet}px) {
        width: 100vw;
        flex-direction: row;
        flex-wrap: wrap;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
`;

const MenuTextButton = styled(TextButton)`
    color: ${p => p.color ? p.color : menuTextColor};
    font-size: 24px;
    margin-bottom: ${gutter * 2}px;
    &:hover {
        opacity: .6;
    }
    &:focus {
        outline-offset: 5px;
        outline: 1px solid ${p => p.color ? p.color : menuTextColor};
    }
    ${p => p.active &&`
    border-bottom: 2px solid lightblue;
  `};
    @media only screen and (max-width: ${sizes.tablet}px) {
        width: 50%;
        display: flex;
        justify-content: center;
    }
`;

const ProfileMenuContent = () => {
  let queryParams = useQuery();
  let contentToDisplay = queryParams.get("content");
  let navigate = useNavigate();
  
  const navigateToPage = (page) => {
    navigate(`/profile?content=${page}`);
  }
  
  return (
    <MenuContentContainer>
      <MenuTextButton active={contentToDisplay == "" || contentToDisplay == "home"} onClick={() => navigateToPage("home")} color={"black"} icon={<PersonIcon/>} text={translation("common.profile")}/>
      <MenuTextButton active={contentToDisplay == "settings"} onClick={() => navigateToPage("settings")} color={"black"} icon={<IoSettingsOutline />} text={translation("common.settings")}/>
      <MenuTextButton active={contentToDisplay == "groups"} onClick={() => navigateToPage("groups")} color={"black"} icon={<MdGroups />} text={translation("common.groups")}/>
      <MenuTextButton active={contentToDisplay == "deleted"} onClick={() => navigateToPage("deleted")} color={"black"} icon={<MdDelete />} text={translation("common.deleted")}/>
    </MenuContentContainer>
  );
};

export default ProfileMenuContent;