import CustomDivider from "../../../shared/components/PageSectionDivider";
import ParagraphBold from "../../../shared/components/text/ParagraphBold";
import translation from "../../../shared/utils/translation";
import OutlinePlusIcon from "../../../shared/components/icons/OutlinePlusIcon";
import * as React from "react";
import styled from "styled-components"
import ModalStore from "../../../shared/stores/ModalStore";
import UserStore from "../../../shared/stores/UserStore";

const ProjectSectionWrapper = styled.div`
  display: flex;
    align-items: center;
`

const AddProjectSection = () => {
  
  const { isLoggedIn } = UserStore
  
  const onAddProjectClick = () => {
    ModalStore.addModal({modalTopic: "project", modalName: "create"})
  }
  
  const openLoginModal = () => {
    ModalStore.addModal({modalTopic: "user", modalName: "signIn"})
  }
  
  return(
    <ProjectSectionWrapper>
      <CustomDivider>
        <ParagraphBold
        pointer={true}
        style={{display: "flex", alignItems: "center"}}
        onClick={() => isLoggedIn ? onAddProjectClick() : openLoginModal()}
        >
          {translation("divider.text.addProject")}
          <OutlinePlusIcon/>
        </ParagraphBold>
      </CustomDivider>
    </ProjectSectionWrapper>
    
  )
}

export default AddProjectSection