import * as React from "react"
import { BiLogOut } from "react-icons/bi";
import styled from "styled-components";


const SignOutBold = styled(BiLogOut)`
  font-size: 26px;
`

const SignOutIcon = ({className, onClick}) => {
  return (
      <SignOutBold onClick={onClick} className={className}/>
  )
}

export default SignOutIcon