
import * as React from "react"
import { IoReturnUpBack } from "react-icons/io5";
import styled from "styled-components";
import {useState} from "react";


const ReturnIconWrapper = styled(IoReturnUpBack)`
  font-size: 18px;
`

const ReturnIcon = ({onClick, className}) => {

  return (
      <ReturnIconWrapper onClick={onClick} className={className}/>
  )
}

export default ReturnIcon