import * as React from "react"
import styled from "styled-components"
import DefaultFormInput from "../../../../../../shared/components/input/DefaultFormInput";
import translation from "../../../../../../shared/utils/translation";
import CustomDivider from "../../../../../../shared/components/PageSectionDivider";
import ParagraphBold from "../../../../../../shared/components/text/ParagraphBold";
import {Form} from "antd";
import {observer} from "mobx-react";
import WhiteButton from "../../../../../../shared/components/buttons/WhiteButton";
import ProfileStore from "../../../../../../shared/stores/ProfileStore";

const AccountSettingsSectionWrapper = styled.div`

`

const AccountUpdatePasswordSection = () => {
  
  
  const onSubmitResetPassword = (values) => {
    ProfileStore.updateUserPassword(values)
  }
  
  return (
    <AccountSettingsSectionWrapper>
      <CustomDivider><ParagraphBold>{translation("profile.settings.resetPassword")}</ParagraphBold></CustomDivider>
      <Form
        name="resetPassword"
        initialValues={{ remember: true }}
        onFinish={onSubmitResetPassword}
        scrollToFirstError
      >
        <DefaultFormInput
          field={{
            maxLength: 50,
            name:"currentPassword",
            type: "password",
            rules:[
              { required: true, message: translation("form.field.error.message.empty") },
            ],
            label: translation("form.label.currentPassword")
          }}
        />
        <WhiteButton type="primary" htmlType="submit" disabled={true}>{translation("button.changePassword")}</WhiteButton>
      </Form>
    </AccountSettingsSectionWrapper>
  )
}

export default observer(AccountUpdatePasswordSection)
