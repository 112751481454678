import React, {useEffect} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import GroupStore from "../../../../../../shared/stores/GroupStore";
import {useQuery} from "../../../../../../shared/utils/useQuery";
import translation from "../../../../../../shared/utils/translation";
import ParagraphPrimary from "../../../../../../shared/components/text/ParagraphPrimary";
import { format } from "date-fns";
import LoadingSpinner from "../../../../../../shared/components/LoadingSpinner";

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UpdateCard = styled.div`
  background-color: #f9f9f9;
  border-left: 4px solid lightblue; /* Color indicates change type */
  margin: 10px 0;
  padding: 20px;
  width: 80%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CardAffectedUser = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const ChangeTitle = styled.h3`
  margin: 0;
  font-size: 18px;
`;


const ChangeDetail = styled.details`
  font-size: 14px;
`;

const ChangeSummary = styled.summary`
  cursor: pointer;
`;

const Timestamp = styled.div`
  font-size: 12px;
  text-align: right;
  color: #666;
`;

const StateComparison = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StateColumn = styled.div`
  width: 48%; /* Slightly less than half to accommodate spacing */
  padding: 10px;
  background-color: #efefef;
  border-radius: 4px;
`;


const GroupHistoryContent = () => {
  const queryParams = useQuery();
  const groupId = queryParams.get("group");
  
  const { groupHistory, isLoading } = GroupStore
  
  useEffect(() => {
    GroupStore.getGroupHistory(groupId);
  }, [groupId]);
  
  const renderStateDetails = (state) => {
    return Object.entries(state).map(([key, value]) => (
      <p key={key}><strong>{key}:</strong> {value}</p>
    ));
  };
  
  if(isLoading){
    return <LoadingSpinner/>
  }
  
  return (
    <TimelineWrapper>
      {
        groupHistory.map((history, index) => (
        <UpdateCard key={index}>
          <CardHeader>
            <ChangeTitle>{history.action_type}</ChangeTitle>
            <ChangeTitle>{`${translation("project.history.title.by")}: ${history.action_performed_by} - ${history.action_performed_by_name}`}</ChangeTitle>
          </CardHeader>
          
          <CardHeader>
            <CardAffectedUser>{`${translation("project.history.title.affected")}: ${history.affected_user_id} - ${history.affected_user_name}`}</CardAffectedUser>
          </CardHeader>
          <ChangeDetail>
            <ChangeSummary>{translation("project.history.viewDetails")}</ChangeSummary>
            <StateComparison>
              <StateColumn>
                <ParagraphPrimary small={true}>{translation("project.history.previousState")}:</ParagraphPrimary>
                {renderStateDetails(JSON.parse(history.previous_state))}
              </StateColumn>
              <StateColumn>
                <ParagraphPrimary small={true}>{translation("project.history.currentState")}:</ParagraphPrimary>
                {renderStateDetails(JSON.parse(history.current_state))}
              </StateColumn>
            </StateComparison>
          </ChangeDetail>
          <Timestamp>{format(new Date(history.action_timestamp), 'PPPpp')}</Timestamp>
        </UpdateCard>
      ))
      
      }
    </TimelineWrapper>
  );
};

export default observer(GroupHistoryContent);