import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import AccountSettingsSection from "./sections/AccountSettingsSection";
import AccountUpdatePasswordSection from "./sections/AccountPasswordSection";
import {useEffect} from "react";
import ProfileStore from "../../../../../shared/stores/ProfileStore";

const SettingsWrapper = styled.div`
    margin-top: 25px;
    width: 80%;
`

const ProfileSettingsWrapper = () => {
  
  useEffect(() => {
    ProfileStore.fetchUserDetails()
  }, [])
  
  return (
    <SettingsWrapper>
      <AccountSettingsSection/>
      <AccountUpdatePasswordSection/>
    </SettingsWrapper>
  )
}

export default observer(ProfileSettingsWrapper)
