import { makeAutoObservable } from "mobx";
import ApiGateway from "../api/ApiGateway";
import UserStore from "./UserStore";
import ModalStore from "./ModalStore";


class GroupStore {
  
  groups = []
  currentGroup = null
  groupMembers = []
  groupHistory = []
  isLoading = false;
  isFetchingGroup = false
  selectedUser = null
  isFetchingGroups = false;
  
  constructor() {
    makeAutoObservable(this);
  }
  
  async createGroup(values){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)

    let result = await ApiGateway.groups.createGroup(values);
    let newGroup = {
      group_id: result.groupId,
      group_name: values.groupName,
      description: values.description,
      role_name: result.role_name,
      role_description: result.role_description,
      role_id: result.role_id,
      last_activity: result.last_activity,
      created_by_id: result.created_by_id,
      created_by_name: result.created_by_name,
      total_members: result.total_members
    }
    
    this.groups.push(newGroup);
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
  }
  
  async fetchUserGroups(groupId){
    if(this.isFetchingGroups) return
    this.isFetchingGroups = true;
    this.currentGroup = null;
    let result = await ApiGateway.groups.fetchUserGroups();
    if(result.success){
      if(groupId){
        this.currentGroup = result.groups.filter((group) => group.group_id == groupId)[0]
      }
      this.groups = result.groups;
    }
    this.isFetchingGroups = false;
  }
  
  async getGroup(groupId) {
    if(this.isFetchingGroup){
      return
    }
    this.isFetchingGroup = true
    const foundGroup = this.groups.find(group => group.group_id == groupId);
    this.currentGroup = foundGroup
    this.isFetchingGroup = false;
    return foundGroup;
  }
  
  async inviteUserToGroup(values){
    if(this.isLoading) return
    this.isLoading = true
    let data = {
      groupRole: values.groupRole,
      groupId: this.currentGroup.group_id,
      groupName: this.currentGroup.group_name
    }
    await ApiGateway.groups.inviteUserToGroup(data, values.userToInvite);
    this.isLoading = false
  }
  
  async acceptInvitation(token, onSuccessCallback){
    if(this.isLoading){return}
    this.isLoading = true
    let data = {token: token}
    let result = await ApiGateway.groups.acceptInvitation(data);
    this.isLoading = false
    if (result.success && onSuccessCallback) {
      onSuccessCallback();
    }
  }
  
  async leaveGroup(){
    if(this.isLoading){return}
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    let result = await ApiGateway.groups.leaveGroup(this.currentGroup.group_id, UserStore.user.user_id);
    if(result.success){
      this.groups = this.groups.filter(group => group.group_id != this.currentGroup.group_id);
    }
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  setSelectedUser(userId){
    this.selectedUser = userId
  }
  
  async fetchGroupMembers(groupId, user){
    if(this.isLoading){return}
    this.isLoading = true
    
    let result = await ApiGateway.groups.fetchGroupMembers(groupId, user);
    
    this.groupMembers = result.result
    
    this.isLoading = false
    return result;
  }
  
  async editMemberRole(values){
    if(this.isLoading){return}
    this.isLoading = true
    ModalStore.setModalLoading(true)
    let data = {newRole: values.values.groupRole}
    let result = await ApiGateway.groups.editMemberRole(data, this.currentGroup.group_id, this.selectedUser);
    
    const userIndex = this.groupMembers.findIndex(member => member.user_id === this.selectedUser);
    if(userIndex !== -1) {
      this.groupMembers[userIndex].role_name = result.newRoleName;
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async getGroupHistory(groupId){
    if(this.isLoading){return}
    this.isLoading = true
    let result = await ApiGateway.groups.getGroupHistory(groupId);
    this.groupHistory = result.result
    
    this.isLoading = false
    return result;
  }
  async removeMember(){
    if(this.isLoading){return}
    this.isLoading = true
    ModalStore.setModalLoading(true)
    let result = await ApiGateway.groups.removeMember(this.currentGroup.group_id, this.selectedUser);
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
}

export default new GroupStore()