import * as React from "react"
import styled from "styled-components"
import ProfileBannerComponent from "./components/header/ProfileBannerComponent";
import ProfilePictureComponent from "./components/header/ProfilePictureComponent";
import ProfileMenu from "./components/menu/ProfileMenu";
import ProfileStandardContent from "./components/content/profile-home/ProfileStandardContent";
import ProfileSettingsWrapper from "./components/content/settings/ProfileSettingsWrapper";
import {observer} from "mobx-react";
import {sizes} from "../../shared/utils/media";
import GroupWrapper from "./components/content/group/GroupWrapper";
import {useQuery} from "../../shared/utils/useQuery";
import DeletedWrapper from "./components/content/deleted/DeletedWrapper";

const ProfileContainer = styled.div``

const ProfileTopWrapper = styled.div``

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media only screen and (max-width:${sizes.tablet}px){
        flex-direction: column;
    }
`

const ContentWrapper = styled.div`
    display:flex;
    
    justify-content: center;
    width: calc(100vw - 200px);
    @media only screen and (min-width:${sizes.tablet}px){
        margin-top: 100px;
    }
    @media only screen and (max-width:${sizes.tablet}px){
        width: 100vw;
    }
`

const Profile = () => {
  let queryParams = useQuery();
  let contentToDisplay = queryParams.get("content")
  
  const contentToLoad = () => {
    switch(contentToDisplay){
      case "settings":
        return <ProfileSettingsWrapper/>
      case "groups":
        return <GroupWrapper/>
      case "deleted":
        return <DeletedWrapper/>
      case "home":
      default:
        return <ProfileStandardContent/>
    }
  }

  return (
    <ProfileContainer>
      <ProfileTopWrapper>
        <ProfileBannerComponent/>
        <ProfilePictureComponent/>
      </ProfileTopWrapper>
      
      <BodyWrapper>
        <ProfileMenu/>
        <ContentWrapper>
          {contentToLoad()}
        </ContentWrapper>
      </BodyWrapper>
    </ProfileContainer>
  )
}

export default observer(Profile)
