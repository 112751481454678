import { observable, action } from "mobx"
import {displayCookieModal, getCookiePreferences, setCookieLanguage} from "./CookieStore";
import {blankUser} from "../utils/blankUser";


export const hostname = observable.box("")

export const setHostname = action((newHostname) => {
  hostname.set(newHostname)
})

export const defaultLanguage = observable.box("en")

export const setDefaultLanguage = action((lang = "en") => {
  defaultLanguage.set(lang)
})



export const loadPageConfig = action(() => {
  if(!getCookiePreferences()){
    displayCookieModal.set(true)
  }
});

export const selectedLanguage = observable.box("en")

export const setSelectedLanguage = action((lang) => {
  selectedLanguage.set(lang)
  setCookieLanguage(lang)
})

export const userIsLoggedIn = observable.box(false);
export const setUserIsLoggedIn = action((isLoggedIn) => {
  userIsLoggedIn.set(isLoggedIn)
})

export const imageBuffer = observable.box(null);
export const setImageBuffer = action((arrayBuffer) => {
  imageBuffer.set(arrayBuffer)
})

export const user = observable.box(blankUser);
export const setUser = action((newUser) => {
  user.set(newUser)
})

export const menuIsOpen = observable.box(false)

export const setMenuIsOpen = action((isOpen) => {
  menuIsOpen.set(isOpen)
})
