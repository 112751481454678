import React from "react";
import styled from "styled-components";

import {observer} from "mobx-react";
import { IoPersonRemoveSharp } from "react-icons/io5";
import { AiFillEdit } from "react-icons/ai";
import ProjectStore from "../../../../../../shared/stores/ProjectStore";
import translation from "../../../../../../shared/utils/translation";
import {ADMINISTRATOR_ROLES} from "../../../../../../shared/utils/Constants";
import UserStore from "../../../../../../shared/stores/UserStore";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
    text-align: center;
`;

const Tr = styled.tr`
  ${p => p.isCurrentUser && `
    background-color: #ECECEC;
  `}
`

const Th = styled.th`
  background-color: #f4f4f4;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const RemoveUserIcon = styled(IoPersonRemoveSharp)`
  font-size: 20px;
  cursor: pointer;
`

const EditUserIcon = styled(AiFillEdit)`
    font-size: 20px;
    cursor: pointer;
`

const ActionButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const ProjectUsersContent = ({projectUsers, editUserOnClick, removeUserOnClick}) => {
  
  const { selectedProject } = ProjectStore
  
  return (
    <Table>
      <thead>
      <tr>
        <Th>{translation("common.name")}</Th>
        <Th>{translation("common.username")}</Th>
        <Th>{translation("common.email")}</Th>
        <Th>{translation("common.role")}</Th>
        <Th>{translation("common.actions")}</Th>
      </tr>
      </thead>
      <tbody>
      {projectUsers.map((user) => (
        <Tr key={user.user_id} isCurrentUser={user.user_id === UserStore.user.user_id}>
          <Td>{user.name}</Td>
          <Td>{user.username}</Td>
          <Td>{user.email}</Td>
          <Td>{user.role}</Td>
          <Td>
            {
              user.role != "owner" &&
              <ActionButtonWrapper>
                {ADMINISTRATOR_ROLES.includes(selectedProject.role) && <EditUserIcon onClick={() => editUserOnClick(user.user_id)}/>}
                {ADMINISTRATOR_ROLES.includes(selectedProject.role) && <RemoveUserIcon onClick={() => removeUserOnClick(user.user_id)}/>}
              </ActionButtonWrapper>
            }
          
          </Td>
        </Tr>
      ))}
      </tbody>
    </Table>
  );
}

export default observer(ProjectUsersContent)