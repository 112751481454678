import * as React from "react"
import styled from "styled-components"
import {useQuery} from "../utils/useQuery";
import ParagraphBold from "./text/ParagraphBold";

const Wrapper = styled.div`
    margin: 25px 0;
    display: flex;
    justify-content: space-around;
`

const OptionWrapper = styled.div`
    cursor: pointer;
    width: 150px;
    text-align: center;
`

const UnderLine = styled.div`
    border: 2px solid ${p => p.isActive ? "lightblue" : "black"};
    border-radius: 8px;
    ${OptionWrapper}:hover & {
        border: 2px solid lightblue;
    }
`

const CustomTab = ({tabs}) => {
  const queryParams = useQuery();
  return (
    <Wrapper>
      {tabs.map((tab) => {
        
        let isActive = queryParams.get(tab.isActiveQuery);
        
        return(
          <OptionWrapper key={tab.key}>
            <ParagraphBold onClick={() => tab.onClick()}>{tab.text}</ParagraphBold>
            <UnderLine isActive={isActive == tab.resultToBeActive}/>
          </OptionWrapper>
        )
      })}
    </Wrapper>
  );
};

export default CustomTab