import translation from "./translation";
import userStore from "../stores/UserStore";
import {validateMessages} from "./validateMessage";
import UserStore from "../stores/UserStore";
import ProjectStore from "../stores/ProjectStore";
import SectionStore from "../stores/SectionStore";
import ComponentStore from "../stores/ComponentStore";
import ProfileStore from "../stores/ProfileStore";
import GroupStore from "../stores/GroupStore";

export const ModalContent = {

  user:{
    signIn:{
      type: "input",
      name: "signIn",
      title: translation( "common.signIn"),
      action: "signin",
      submitFunction: (values) => {
        return userStore.signIn(values)
      },
      validationFields: ["email", "password"],
      fields: [
        {
          name: "email",
          maxLength: 75,
          required: true,
          value: ""
        },
        {
          name: "password",
          maxLength: 50,
          required: true,
          type: "password",
          value: "",
        }
      ],
      messages: {
        success: translation("signIn.welcomeBack"),
        error: translation("signIn.error")
      },
      buttonText: {
        submit: translation("common.signIn"),
        cancel: translation("form.cancel")
      }
    },
    signOut:{
      type: "confirmation",
      name: "signOut",
      title: translation("common.signOut"),
      action: "signout",
      submitFunction: () => {
        return userStore.signOut()
      },
      text: translation("signOut.form.areYouSure")
    },
    signUp: {
      type: "input",
      name: "signUp",
      title: translation( "common.signUp"),
      action: "signup",
      submitFunction: async(values) => {
        return await userStore.signUp(values)
      },
      multiStep: true,
      validationFields: ["name", "email", "password", "repassword"],
      steps: [
        {
          fields: [
            {
              name: "name",
              required: true,
              value: "",
              maxLength: 50,
              extraLabel: true,
              rules: [
                {
                  required: true,
                  message: validateMessages.empty
                }
              ]
            },
            {
              name: "email",
              required: true,
              value: "",
              maxLength: 75,
              rules: [
                {
                  required: true,
                  message: validateMessages.empty,
                },
                {
                  type: 'email',
                  message: validateMessages.email.invalid,
                },
                () => ({
                  async validator(_, value) {
                    if(value){
                      if(value.length > 0 && value.includes("@")){
                        if (await UserStore.verifyEmailNotExist(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(validateMessages.unavailable.email));
                      }
                    }
                  },
                })
              ]
            }

          ],
        },
        {
          fields: [
            {
              name: "password",
              required: true,
              value: "",
              maxLength: 50,
              type: "password",
              rules: [
                {
                  required: true,
                  message: validateMessages.empty
                },
              ],
            },
            {
              name: "repassword",
              required: true,
              value: "",
              maxLength: 50,
              type: "password",
              rules: [
                {
                  required: true,
                  message: validateMessages.empty
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(translation("form.passwords.notMatch")));
                  },
                })
              ],
            },
          ],
        }
      ],
      messages: {
        success: translation("signIn.welcomeBack"),
        error: translation("signIn.error")
      },
    },
    forgotPassword: {
      type: "input",
      name: "forgotPassword",
      title: translation( "common.forgotPassword"),
      action: "forgotPassword",
      submitFunction: (values) => {
        return userStore.requestResetPassword(values)
      },
      description: translation("form.description.forgotPassword"),
      validationFields: ["email"],
      fields: [
        {
          name: "email",
          maxLength: 75,
          required: true,
          value: ""
        }
      ],
      messages: {
        success: translation("signIn.welcomeBack"),
        error: translation("signIn.error")
      },
      singleButton: true,
      buttonText: translation("common.resetPassword")
    }
  },

  project: {
    create:{
      type: "input",
      name: "createProject",
      title: translation( "form.title.createProject"),
      action: "createProject",
      submitFunction: async(values) => {
        return ProjectStore.createProject(values)
      },
      validationFields: ["name", "image", "description", "isPublic"],
      multiStep: true,
      fileAccept: "image/*",
      steps: [
        {
          fields: [
            {
              name: "name",
              required: true,
              label: translation("form.label.projectName"),
              value: "",
              maxLength: 50,
              rules: [
                {
                  required: true,
                  message: validateMessages.empty
                }
              ]
            },
            {
              name: "image",
              value: "",
              isUpload: true,
              maxLength: 1,
              rules: [
                () => ({
                  async validator(_, fileList) {

                    if(fileList == undefined || fileList.length == 0){
                      return Promise.resolve();
                    }
                    // Check if the file type is an image
                    const allImages = fileList.every((file) => file.type.startsWith('image/'));

                    if (!allImages) {
                      return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                    }
                  },
                })

              ]
            }

          ],
        },
        {
          fields: [
            {
              name: "description",
              value: "",
              type: "textEditor",
              maxLength: 1000,
            },
          ],
        },
        {
          fields: [
            {
              name: "isPublic",
              value: "",
              type: "switch",
            },
          ]
        }
      ],
    },
    edit:{
      type: "input",
      name: "editProject",
      title: `${translation( "form.title.editProject")}`,
      action: "editProject",
      submitFunction: async(values) => {
        return ProjectStore.editProject(values.values)
      },
      fileAccept: "image/*",
      validationFields: ["name", "image", "description", "is_public"],
      multiStep: true,
      steps: [
        {
          fields: [
            {
              name: "name",
              label: translation("form.label.projectName"),
              value: "",
              initialValue: ()  => {return ProjectStore.selectedProject.name},
              maxLength: 50,
            },
            {
              name: "image",
              value: "",
              previewImage: () => {
                if((ProjectStore.selectedProject.image_name !== undefined && ProjectStore.selectedProject.image_name !== null) && ProjectStore.selectedProject.image_name.length !== 0){
                  return {
                    uid: ProjectStore.selectedProject.project_id,
                    url: ProjectStore.selectedProject.thumbImageUrl,
                    name: ProjectStore.selectedProject.image_name
                  }
                }else{
                  return false
                }
              },
              isUpload: true,
              maxLength: 1,
              rules: [
                () => ({
                  async validator(_, fileList) {

                    if(fileList == undefined || fileList.length == 0){
                      return Promise.resolve();
                    }
                    const allImages = fileList.every((file) => file.type.startsWith('image/'));

                    if (!allImages) {
                      return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                    }
                  },
                })

              ]
            }

          ],
        },
        {
          fields: [
            {
              name: "description",
              value: "",
              initialValue: ()  => {return ProjectStore.selectedProject.description},
              type: "textEditor",
              maxLength: 1000,
            },
          ],
        },
        {
          fields: [
            {
              name: "is_public",
              initialValue: () => {return ProjectStore.selectedProject.is_public},
              value: "",
              type: "switch",
            },
          ]
        }
      ],
    },
    delete:{
      type: "confirmation",
      name: "deleteProject",
      title: translation("common.delete"),
      action: "delete",
      submitFunction: async(doDelete) => {
        if(!doDelete){
          return
        }
        return await ProjectStore.deleteProject()
      },
      text: translation("project.form.deleteConfirm")
    },
    addImage:{
      type: "input",
      name: "addImage",
      title: translation("form.title.addImage"),
      action: "addImage",
      submitFunction: async(values) => {
        return await ProjectStore.addImage(values)
      },
      validationFields: ["image"],
      fileAccept: "image/*",
      fields: [
        {
          name: "image",
          value: "",
          isUpload: true,
          maxLength: 1,
          rules: [
            () => ({
              async validator(_, fileList) {
                
                if(fileList == undefined || fileList.length == 0){
                  return Promise.resolve();
                }
                // Check if the file type is an image
                const allImages = fileList.every((file) => file.type.startsWith('image/'));
                
                if (!allImages) {
                  return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                }
              },
            })
          
          ]
        },
      ],
    },
    inviteUser: {
      type: "input",
      name: "inviteUser",
      title: translation("form.title.inviteUser"),
      action: "inviteUser",
      submitFunction: async(values) => {
        return await ProjectStore.inviteUserToProject(values)
      },
      displayUserInformation: true,
      validationFields: ["userToInvite", "projectRole"],
      fields: [
        {
          value: "",
          name: "userToInvite",
          maxLength: 75,
          rules: [
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if (UserStore.verifyUserExist(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(validateMessages.user.doNotExist));
                }
              },
            })
          ]
        },
        {
          name: "projectRole",
          displayRoleInformation: true,
          roleType: "project",
          rules: [
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value < 0 || value > 3){
                    return Promise.reject(new Error(validateMessages.role.doesNotExist));
                  }
                  return Promise.resolve();
                }
              },
            })
          ],
        },
      ],
    },
    removeUser:{
      type: "confirmation",
      name: "removeUser",
      title: translation("project.modal.removeUser"),
      action: "removeUser",
      submitFunction: () => {
        return ProjectStore.removeUser()
      },
      text: translation("signOut.form.areYouSure")
    },
    editUserRole: {
      type: "input",
      name: "editUserRole",
      title: translation("form.title.editUserRole"),
      action: "editUserRole",
      submitFunction: async(values) => {
        return await ProjectStore.editUserRole(values)
      },
      validationFields: ["projectRole"],
      fields: [
        {
          name: "projectRole",
          displayRoleInformation: true,
          roleType: "project",
          rules: [
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value < 0 || value > 3){
                    return Promise.reject(new Error(validateMessages.role.doesNotExist));
                  }
                  return Promise.resolve();
                }
              },
            })
          ],
        },
      ],
    },
    cancelInvite: {
      type: "confirmation",
      name: "cancelInvite",
      title: translation("modal.title.cancelInvite"),
      action: "cancelInvite",
      submitFunction: async () => {
        return await ProjectStore.cancelInvitation()
      },
      text: translation("project.cancel.invite.areYouSure")
    },
    leaveProject: {
      type: "confirmation",
      name: "leaveProject",
      title: translation("modal.title.leaveProject"),
      action: "leaveProject",
      submitFunction: async () => {
        return await ProjectStore.leaveProject()
      },
      text: translation("project.leave.areYouSure")
    },
    deleteProject: {
      type: "input",
      name: "deleteProject",
      title: translation("modal.title.deleteProject"),
      action: "deleteProject",
      submitFunction: async () => {
        return await ProjectStore.deleteProject()
      },
      alertType: "warning",
      alertText: translation("project.delete.areYouSure"),
      softDescription: translation("modal.softDelete.description"),
      fields: [
        {
          name: "confirm",
          label: translation("form.label.delete.confirm"),
          value: "",
          rules:[
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value === "soft delete"){
                    return Promise.resolve();
                  }
                  else{
                    return Promise.reject(new Error(validateMessages.deleteModalInput.softSafeCheck));
                  }
                }
              },
            })
          ],
        },
      ],
    },
    restoreProject: {
      type: "confirmation",
      name: "restoreProject",
      title: translation("modal.title.restoreProject"),
      action: "restoreProject",
      submitFunction: async () => {
        return await ProjectStore.restoreProject()
      },
      text: translation("project.restore.areYouSure")
    },
    permDeleteProject: {
      type: "input",
      name: "permDeleteProject",
      title: translation("modal.title.permDeleteProject"),
      action: "permDeleteProject",
      alertType: "warning",
      submitFunction: async () => {
        return await ProjectStore.permDeleteProject()
      },
      softDescription: translation("modal.permDelete.description"),
      alertText: translation("project.permDeleteProject.areYouSure"),
      fields: [
        {
          name: "confirm",
          label: translation("form.label.delete.confirm"),
          value: "",
          rules:[
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value === "permanently delete"){
                    return Promise.resolve();
                  }
                  else{
                    return Promise.reject(new Error(validateMessages.deleteModalInput.permSafeCheck));
                  }
                }
              },
            })
          ],
        },
      ],
    },
    addFile:{
      type: "input",
      name: "addFile",
      title: translation("form.title.addFile"),
      action: "addFile",
      submitFunction: async(values) => {
        return await ProjectStore.addFile(values)
      },
      validationFields: ["file"],
      fileAccept: "image/*, .doc, .docx, .xml, .txt, application/msword",
      fields: [
        {
          name: "file",
          value: "",
          isUpload: true,
          maxLength: 5,
          rules: [
            () => ({
              async validator(_, fileList) {
                
                if(fileList == undefined || fileList.length == 0){
                  return Promise.resolve();
                }
              },
            })
          
          ]
        },
      ],
    },
    addNote:{
      type: "input",
      name: "addNote",
      title: translation( "form.title.addNote"),
      action: "addNote",
      submitFunction: async(values) => {
        return ProjectStore.addNote(values)
      },
      validationFields: ["note"],
      fields: [
        {
          name: "note",
          value: "",
          type: "textEditor",
          maxLength: 1000,
        },
      ],
    },
    editNote:{
      type: "input",
      name: "editNote",
      title: translation( "form.title.addNote"),
      action: "editNote",
      submitFunction: async(values) => {
        return ProjectStore.editNote(values)
      },
      validationFields: ["note"],
      fields: [
        {
          name: "note",
          value: "",
          initialValue: ()  => {return ProjectStore.currentNote},
          type: "textEditor",
          maxLength: 1000,
        },
      ],
    }
  },

  section: {
    create: {
      type: "input",
      name: "createSection",
      title: translation( "form.title.createSection"),
      action: "createSection",
      submitFunction: async(values) => {
        return await SectionStore.addSection(values)
      },
      validationFields: ["name"],
      fields: [
        {
          name: "name",
          maxLength: 30,
          rules: [
            {
              required: true,
              message: validateMessages.empty
            }
          ],
          value: ""
        }
      ],
      messages: {
        success: translation("section.created"),
        error: translation("section.error")
      },
      buttonText: {
        submit: translation("section.create"),
        cancel: translation("section.cancel")
      }
    },
    edit: {
      type: "input",
      name: "editSection",
      title: translation( "form.title.editSection"),
      action: "editSection",
      submitFunction: async(values) => {
        return await SectionStore.editSection(values)
      },
      validationFields: ["name"],
      fields: [
        {
          name: "name",
          maxLength: 30,
          rules: [
            {
              required: true,
              message: validateMessages.empty
            }
          ],
          initialValue:() => SectionStore.selectedSection.name
        }
      ],
    },
    softDelete: {
      type: "input",
      name: "deleteSection",
      title: translation("modal.title.deleteSection"),
      action: "deleteSection",
      submitFunction: async () => {
        return await SectionStore.softDeleteSection()
      },
      alertType: "warning",
      alertText: translation("section.delete.areYouSure"),
      softDescription: translation("modal.softDelete.description"),
      fields: [
        {
          name: "confirm",
          label: translation("form.label.delete.confirm"),
          value: "",
          rules:[
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value === "soft delete"){
                    return Promise.resolve();
                  }
                  else{
                    return Promise.reject(new Error(validateMessages.deleteModalInput.softSafeCheck));
                  }
                }
              },
            })
          ],
        },
      ],
    },
    restoreSection: {
      type: "confirmation",
      name: "restoreSection",
      title: translation("modal.title.restoreSection"),
      action: "restoreSection",
      submitFunction: async () => {
        return await SectionStore.restoreSection()
      },
      text: translation("section.restore.areYouSure")
    },
    permDeleteSection: {
      type: "input",
      name: "permDeleteSection",
      title: translation("modal.title.deleteSection"),
      action: "permDeleteSection",
      alertType: "warning",
      submitFunction: async () => {
        return await SectionStore.permDeleteSection()
      },
      softDescription: translation("modal.permDelete.description"),
      alertText: translation("section.permDeleteSection.areYouSure"),
      fields: [
        {
          name: "confirm",
          label: translation("form.label.delete.confirm"),
          value: "",
          rules:[
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value === "permanently delete"){
                    return Promise.resolve();
                  }
                  else{
                    return Promise.reject(new Error(validateMessages.deleteModalInput.permSafeCheck));
                  }
                }
              },
            })
          ],
        },
      ],
    },
  },

  component: {
    create:{
      type: "input",
      name: "createComponent",
      title: translation( "form.title.createComponent"),
      action: "createComponent",
      submitFunction: (values) => {
        return ComponentStore.addComponent(values)
      },
      validationFields: ["name", "image", "description"],
      fileAccept: "image/*",
      multiStep: true,
      steps: [
        {
          fields: [
            {
              name: "name",
              required: true,
              label: translation("form.label.componentName"),
              value: "",
              maxLength: 50,
              rules: [
                {
                  required: true,
                  message: validateMessages.empty
                }
              ]
            },
            {
              name: "image",
              value: "",
              isUpload: true,
              maxLength: 1,
              rules: [
                () => ({
                  async validator(_, fileList) {

                    if(fileList == undefined || fileList.length == 0){
                      return Promise.resolve();
                    }
                    // Check if the file type is an image
                    const allImages = fileList.every((file) => file.type.startsWith('image/'));

                    if (!allImages) {
                      return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                    }
                  },
                })

              ]
            }

          ],
        },
        {
          fields: [
            {
              name: "description",
              value: "",
              type: "textEditor",
              maxLength: 1000,
            },
          ],
        }
      ],
    },
    edit:{
      type: "input",
      name: "editComponent",
      title: `${translation( "form.title.editComponent")}`,
      action: "editComponent",
      submitFunction: async(values) => {
        return await ComponentStore.editComponent(values.values)
      },
      validationFields: ["name", "image", "description"],
      multiStep: true,
      fileAccept: "image/*",
      steps: [
        {
          fields: [
            {
              name: "name",
              label: translation("form.label.projectName"),
              value: "",
              initialValue: ()  => {return ComponentStore.currentComponent.name},
              maxLength: 50,
            },
            {
              name: "image",
              value: "",
              isUpload: true,
              maxLength: 1,
              previewImage: () => {
                if(ComponentStore.currentComponent.image_name.length !== 0 && ComponentStore.currentComponent.image_name.length !== undefined){
                  return {
                    uid: ComponentStore.currentComponent.project_id,
                    url: ComponentStore.currentComponent.thumbImageUrl,
                    name: ComponentStore.currentComponent.image_name
                  }
                }else{
                  return false
                }
              },
              rules: [
                () => ({
                  async validator(_, fileList) {
                    
                    if(fileList == undefined || fileList.length == 0){
                      return Promise.resolve();
                    }
                    // Check if the file type is an image
                    const allImages = fileList.every((file) => file.type.startsWith('image/'));
                    
                    if (!allImages) {
                      return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                    }
                  },
                })
              
              ]
            }
          
          ],
        },
        {
          fields: [
            {
              name: "description",
              value: "",
              type: "textEditor",
              initialValue: ()  => {return ComponentStore.currentComponent.description},
              maxLength: 1000,
            },
          ],
        }
      ],
    },
    addImage:{
      type: "input",
      name: "addImage",
      title: translation("form.title.addImage"),
      action: "addImage",
      submitFunction: async(values) => {
        return await ComponentStore.addImage(values)
      },
      validationFields: ["image"],
      fileAccept: "image/*",
      fields: [
        {
          name: "image",
          value: "",
          isUpload: true,
          maxLength: 1,
          rules: [
            () => ({
              async validator(_, fileList) {
                
                if(fileList == undefined || fileList.length == 0){
                  return Promise.resolve();
                }
                // Check if the file type is an image
                const allImages = fileList.every((file) => file.type.startsWith('image/'));
                
                if (!allImages) {
                  return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                }
              },
            })
          
          ]
        },
      ],
    },
    deleteComponent: {
      type: "input",
      name: "deleteComponent",
      title: translation("modal.title.deleteComponent"),
      action: "deleteComponent",
      submitFunction: async () => {
        return await ComponentStore.softDeleteComponent()
      },
      alertType: "warning",
      alertText: translation("component.delete.areYouSure"),
      softDescription: translation("modal.softDelete.description"),
      fields: [
        {
          name: "confirm",
          label: translation("form.label.delete.confirm"),
          value: "",
          rules:[
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value === "soft delete"){
                    return Promise.resolve();
                  }
                  else{
                    return Promise.reject(new Error(validateMessages.deleteModalInput.softSafeCheck));
                  }
                }
              },
            })
          ],
        },
      ],
    },
    restoreComponent: {
      type: "confirmation",
      name: "restoreComponent",
      title: translation("modal.title.restoreComponent"),
      action: "restoreComponent",
      submitFunction: async () => {
        return await ComponentStore.restoreComponent()
      },
      text: translation("component.restore.areYouSure")
    },
    permDeleteComponent: {
      type: "input",
      name: "permDeleteComponent",
      title: translation("modal.title.deleteComponent"),
      action: "permDeleteComponent",
      alertType: "warning",
      submitFunction: async () => {
        return await ComponentStore.permDeleteComponent()
      },
      softDescription: translation("modal.permDelete.description"),
      alertText: translation("component.permDeleteComponent.areYouSure"),
      fields: [
        {
          name: "confirm",
          label: translation("form.label.delete.confirm"),
          value: "",
          rules:[
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value === "permanently delete"){
                    return Promise.resolve();
                  }
                  else{
                    return Promise.reject(new Error(validateMessages.deleteModalInput.permSafeCheck));
                  }
                }
              },
            })
          ],
        },
      ],
    },
    addFile:{
      type: "input",
      name: "addFile",
      title: translation("form.title.addFile"),
      action: "addFile",
      submitFunction: async(values) => {
        console.log(values)
        /*
              return await ComponentStore.addImage(values)
         */
  
      },
      validationFields: ["file"],
      fileAccept: "image/*, .doc, .docx, .xml, .txt, application/msword",
      fields: [
        {
          name: "file",
          value: "",
          isUpload: true,
          maxLength: 5,
          rules: [
            () => ({
              async validator(_, fileList) {
                
                if(fileList == undefined || fileList.length == 0){
                  return Promise.resolve();
                }
              },
            })
          
          ]
        },
      ],
    },
    addNote:{
      type: "input",
      name: "addNote",
      title: translation( "form.title.addNote"),
      action: "addNote",
      submitFunction: async(values) => {
        return ComponentStore.addNote(values)
      },
      validationFields: ["note"],
      fields: [
        {
          name: "note",
          value: "",
          type: "textEditor",
          maxLength: 1000,
        },
      ],
    },
    editNote:{
      type: "input",
      name: "editNote",
      title: translation( "form.title.addNote"),
      action: "editNote",
      submitFunction: async(values) => {
        return ComponentStore.editNote(values)
      },
      validationFields: ["note"],
      fields: [
        {
          name: "note",
          value: "",
          initialValue: ()  => {return ComponentStore.currentNote},
          type: "textEditor",
          maxLength: 1000,
        },
      ],
    }
  },
  
  profile:{
    addProfilePicture:{
        type: "input",
        name: "addImage",
        title: translation("form.title.addImage"),
        action: "addImage",
        submitFunction: async(values) => {
          return await ProfileStore.addProfilePicture(values)
        },
        validationFields: ["image"],
        fileAccept: "image/*",
        fields: [
          {
            name: "image",
            value: "",
            isUpload: true,
            maxLength: 1,
            rules: [
              () => ({
                async validator(_, fileList) {
                  
                  if(fileList == undefined || fileList.length == 0){
                    return Promise.resolve();
                  }
                  // Check if the file type is an image
                  const allImages = fileList.every((file) => file.type.startsWith('image/'));
                  
                  if (!allImages) {
                    return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                  }
                },
              })
            
            ]
          },
        ],
      },
    addProfileBanner:{
      type: "input",
      name: "addImage",
      title: translation("form.title.addImage"),
      action: "addImage",
      submitFunction: async(values) => {
        return await ProfileStore.addProfileBanner(values)
      },
      validationFields: ["image"],
      fileAccept: "image/*",
      fields: [
        {
          name: "image",
          value: "",
          isUpload: true,
          maxLength: 1,
          rules: [
            () => ({
              async validator(_, fileList) {
                
                if(fileList == undefined || fileList.length == 0){
                  return Promise.resolve();
                }
                // Check if the file type is an image
                const allImages = fileList.every((file) => file.type.startsWith('image/'));
                
                if (!allImages) {
                  return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                }
              },
            })
          
          ]
        },
      ],
    },
    editProfileBanner:{
      type: "input",
      name: "editImage",
      title: translation("form.title.editBanner"),
      action: "editImage",
      submitFunction: async(values) => {
        return await ProfileStore.editProfileBanner(values)
      },
      validationFields: ["image"],
      fileAccept: "image/*",
      fields: [
        {
          name: "image",
          value: "",
          isUpload: true,
          maxLength: 1,
          rules: [
            () => ({
              async validator(_, fileList) {
                
                if(fileList == undefined || fileList.length == 0){
                  return Promise.resolve();
                }
                // Check if the file type is an image
                const allImages = fileList.every((file) => file.type.startsWith('image/'));
                
                if (!allImages) {
                  return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                }
              },
            })
          
          ]
        },
      ],
    },
    editProfilePicture:{
      type: "input",
      name: "editImage",
      title: translation("form.title.editProfilePicture"),
      action: "editImage",
      submitFunction: async(values) => {
        return await ProfileStore.editProfilePicture(values)
      },
      validationFields: ["image"],
      fileAccept: "image/*",
      fields: [
        {
          name: "image",
          value: "",
          isUpload: true,
          maxLength: 1,
          rules: [
            () => ({
              async validator(_, fileList) {
                
                if(fileList == undefined || fileList.length == 0){
                  return Promise.resolve();
                }
                // Check if the file type is an image
                const allImages = fileList.every((file) => file.type.startsWith('image/'));
                
                if (!allImages) {
                  return Promise.reject(new Error(translation("form.field.error.upload.wrongFileType")));
                }
              },
            })
          
          ]
        },
      ],
    },
    createGroup:{
      type: "input",
      name: "createGroup",
      title: translation("form.title.createGroup"),
      action: "createGroup",
      submitFunction: async({values}) => {
        return await GroupStore.createGroup(values)
      },
      validationFields: ["description", "groupName"],
      fields: [
        {
          maxLength: 30,
          name: "groupName",
          value: "",
        },
        {
          name: "description",
          value: "",
          type: "textarea",
          maxLength: 500,
        },
      ],
    }
  },
  
  group:{
    leaveGroup: {
      type: "confirmation",
      name: "leaveGroup",
      title: translation("profile.group.detailed.leaveGroup"),
      action: "leaveGroup",
      submitFunction: async () => {
        return await GroupStore.leaveGroup()
      },
      text: translation("group.leave.areYouSure")
    },
    editMemberRole: {
      type: "input",
      name: "editUserRole",
      title: translation("form.title.editUserRole"),
      action: "editUserRole",
      submitFunction: async(values) => {
        return await GroupStore.editMemberRole(values)
      },
      validationFields: ["groupRole"],
      fields: [
        {
          name: "groupRole",
          displayRoleInformation: true,
          roleType: "groups",
          rules: [
            {
              required: true,
              message: validateMessages.empty
            },
            () => ({
              async validator(_, value) {
                if(value){
                  if(value < 0 || value > 3){
                    return Promise.reject(new Error(validateMessages.role.doesNotExist));
                  }
                  return Promise.resolve();
                }
              },
            })
          ],
        },
      ],
    },
    removeMember: {
      type: "confirmation",
      name: "removeMember",
      title: translation("profile.group.member.removeMember"),
      action: "removeMember",
      submitFunction: async () => {
        return await GroupStore.removeMember()
      },
      text: translation("group.removeMember.areYouSure")
    }
  }
  
}