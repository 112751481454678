import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-self: center;
    margin: auto;
    background-color: ${p => p.color };
    width: ${p => p.width};
    height: ${p => p.height}
`
const Box = styled.div`

`

const WelcomeText = styled.span`
  color:#181816; //#181816;
  font-size: 28px;
  font-weight: bold;
`

const ExtraText = styled.span`
  color: #aac4e2;
`

const CenteredBox = ({children, color = "#F5F5F5", height = "auto", width = "60%"}) => {
  
  return (
    <Wrapper color={color} height={height} width={width}>
      {children}
    </Wrapper>
  )
}

export default observer(CenteredBox)