import IdeasApi from "./IdeasApi";

export const BASE_URL = "ideas-api/api"

export default {
  project: {
    getProject: async (projectId) => {
      const data = await IdeasApi(`${BASE_URL}/projects/${projectId}`)
      return data
    },
    loadProject: async (projectType, name) => {
      const data = await IdeasApi(`${BASE_URL}/projects?projectType=${projectType}${name && `&name=${name}`}`)
      return data
    },
    editProject: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/`, "PATCH", body)
      return data
    },
    createProject: async (body)=> {
      const  data  = await IdeasApi(`${BASE_URL}/projects/`, "POST", body)
      return data
    },
    getProjectIds: async(projectType, name) => {
      const data = await IdeasApi(`${BASE_URL}/projects/ids?projectType=${projectType}${name ? `&name=${name}` : ``}`)
      return data.projectIds
    },
    addProjectImage: async (body, projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/images`, "POST", body)
      return data
    },
    getLatestProjects: async () => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/latest`)
      return data
    },
    inviteUserToProject: async (body, projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/invite`, "POST", body)
      return data
    },
    acceptProjectInvitation: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/invites/accept`, "POST", body)
      return data
    },
    compareStoredProjectsLastUpdate: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/compare-updates`, "POST", body)
      return data
    },
    getProjectUsers: async (projectId, user) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/users${user ? `?user=${user}` : ''}`)
      return data
    },
    removeUser: async (projectId, userId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/users/${userId}`, "DELETE")
      return data
    },
    editUserRole: async (body, projectId, userId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/users/${userId}/role`, "PATCH",  body)
      return data
    },
    getProjectHistory: async (projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/history`)
      return data
    },
    getInvitedUsers: async (projectId, fetchActive, user) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/invitations?fetchActive=${fetchActive}${user ? `&user=${user}` : ''}`)
      return data
    },
    cancelInvite: async (projectId, invitationId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/invitations/${invitationId}/cancel`, "PATCH")
      return data
    },
    leaveProject: async (projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/leave`, "POST")
      return data
    },
    deleteProject: async (projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/delete`, "PUT")
      return data
    },
    getDeleted: async () => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/deleted`)
      return data
    },
    restoreProject: async (projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/restore`, "PUT")
      return data
    },
    permDeleteProject: async (projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}`, "DELETE")
      return data
    },
    addFile: async (projectId, body) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/file`, "PUT", body)
      return data
    },
    addNote: async (projectId, body) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/note`, "POST", body)
      return data
    },
    editNote: async (projectId, body) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/note`, "PATCH", body)
      return data
    },
    getNote: async (projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/projects/${projectId}/note`)
      return data
    },
  },
  component: {
    addComponent: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/`, "POST", body)
      return data
    },
    getComponent: async (componentId) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}`)
      return data
    },
    editComponent: async (body, componentId) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}`, "PATCH", body)
      return data
    },
    addComponentImage: async (body, componentId) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}/images`, "POST", body)
      return data
    },
    loadSectionComponents: async (body, fromIndex = 0) => {
      const  data  = await IdeasApi(`${BASE_URL}/components?sectionId=${body}&fromIndex=${fromIndex}`)
      return data
    },
    deleteComponent: async (componentId) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}/delete`, "PUT")
      return data
    },
    getDeleted: async () => {
      const  data  = await IdeasApi(`${BASE_URL}/components/deleted`)
      return data
    },
    restoreComponent: async (componentId) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}/restore`, "PUT")
      return data
    },
    permDeleteComponent: async (componentId) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}`, "DELETE")
      return data
    },
    addNote: async (componentId, body) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}/note`, "POST", body)
      return data
    },
    editNote: async (componentId, body) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}/note`, "PATCH", body)
      return data
    },
    getNote: async (componentId) => {
      const  data  = await IdeasApi(`${BASE_URL}/components/${componentId}/note`)
      return data
    },
  },
  section: {
    addSection: async (body, projectId) => {
      const  data  = await IdeasApi(`${BASE_URL}/sections/${projectId}`, "POST", body)
      return data
    },
    editSection: async (body, sectionId) => {
      const  data  = await IdeasApi(`${BASE_URL}/sections/${sectionId}`, "PUT", body)
      return data
    },
    getSection: async (projectId, componentId) => {
      const  data  = await IdeasApi(`${BASE_URL}/sections/${projectId}${componentId ? `?componentId=${componentId}` : ''}`)
      return data
    },
    softDeleteSection: async (sectionId) => {
      const  data  = await IdeasApi(`${BASE_URL}/sections/${sectionId}/delete`, "PUT")
      return data
    },
    getDeleted: async () => {
      const  data  = await IdeasApi(`${BASE_URL}/sections/deleted`)
      return data
    },
    restoreSection: async (sectionId) => {
      const  data  = await IdeasApi(`${BASE_URL}/sections/${sectionId}/restore`, "PUT")
      return data
    },
    permDeleteSection: async (sectionId) => {
      const  data  = await IdeasApi(`${BASE_URL}/sections/${sectionId}`, "DELETE")
      return data
    },
  },
  user: {
    login: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/login`, "POST", body)
      return data
    },
    register: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/register`, "POST", body)
      return data
    },
    verifyUsername: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/verify-username-exist`, "POST", body)
      return data
    },
    verifyEmailExist: async (email) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/exists?email=${email}`)
      return data
    },
    verifyEmail: async (token) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/verify-email?token=${token}`, )
      return data
    },
    requestResetPassword: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/request-password-reset`, "POST", body)
      return data
    },
    resetPassword: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/reset-password`, "POST", body)
      return data
    },
    verifyUserExist: async (user) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/verify-existence?user=${user}`)
      return data
    },
    resendEmailVerification: async (user) => {
      const  data  = await IdeasApi(`${BASE_URL}/users/resend-verification-email`, "POST", user)
      return data
    },
  },
  auth: {
    authToken: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/auth/authenticate-token`, "POST", body)
      return data
    },
  },
  profile: {
    getUserData: async () => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/`)
      return data
    },
    updateUserData: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/`, "PATCH", body)
      return data
    },
    verifyUpdateUserEmail: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/email`, "POST", body)
      return data
    },
    updateUserPassword: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/password`, "PATCH", body)
      return data
    },
    verifyUpdateUserPassword: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/verify-password`, "POST", body)
      return data
    },
    fetchProfilePicture: async () => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/picture`)
      return data
    },
    fetchProfileBanner: async () => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/banner`)
      return data
    },
    addProfilePicture: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/picture`, "POST", body)
      return data
    },
    addProfileBanner: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/banner`, "POST", body)
      return data
    },
    editProfileBanner: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/banner`, "PATCH", body)
      return data
    },
    editProfilePicture: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/profile/picture`, "PATCH", body)
      return data
    },
  },
  groups:{
    createGroup: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/groups/`, "POST", body)
      return data
    },
    fetchUserGroups: async () => {
      const  data  = await IdeasApi(`${BASE_URL}/groups/`)
      return data
    },
    inviteUserToGroup: async (body, user) => {
      const  data  = await IdeasApi(`${BASE_URL}/groups/${user}/invitations`, "POST", body)
      return data
    },
    acceptInvitation: async (body) => {
      const  data  = await IdeasApi(`${BASE_URL}/groups/accept-invitation`, "POST", body)
      return data
    },
    leaveGroup: async (groupId, userId) => {
      const  data  = await IdeasApi(`${BASE_URL}/groups/${groupId}/members/${userId}`, "DELETE")
      return data
    },
    fetchGroupMembers: async (groupId, user) => {
      const  data  = await IdeasApi(`${BASE_URL}/groups/${groupId}/members${user ? `?user=${user}` : ''}`)
      return data
    },
    editMemberRole: async (body, groupId, userId) => {
      const  data  = await IdeasApi(`${BASE_URL}/groups/${groupId}/members/${userId}`, "PATCH", body)
      return data
    },
    getGroupHistory: async (groupId) => {
      const  data  = await IdeasApi(`${BASE_URL}/groups/${groupId}/history`)
      return data
    },
    removeMember: async (groupId, userId) => {
      const  data  = await IdeasApi(`${BASE_URL}/${groupId}/members/${userId}`, "DELETE")
      return data
    },
  }
}