import * as React from "react";
import styled from "styled-components";
import translation from "../../../../../../shared/utils/translation";
import { format } from "date-fns";
import {useNavigate} from "react-router-dom";

const GroupCardWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const GroupName = styled.h3`
  margin: 0 0 10px 0;
`;

const GroupDescription = styled.p`
  color: #666;
`;

const GroupMeta = styled.div`
  font-size: 0.9em;
  color: #666;
`;

const Timestamp = styled.div`
  font-size: 12px;
  text-align: right;
  color: #666;
`;

const DisplayGroupInfo = ({group}) => {
  const navigate = useNavigate();
  
  const handleGroupClick = (groupId) => {
    navigate(`/profile?content=groups&group=${groupId}`)
  };
  
  return(
    <GroupCardWrapper onClick={() => handleGroupClick(group.group_id)}>
      <GroupName>{group.group_name}</GroupName>
      <GroupDescription>{group.description}</GroupDescription>
      <GroupMeta>{`${translation("settings.group.createdBy")}: ${group.created_by_id} - ${group.created_by_name}`}</GroupMeta>
      <GroupMeta>{`${translation("settings.group.members")}: ${group.total_members}`}</GroupMeta>
      <Timestamp>{`${translation("settings.group.latestActivity")}: ${format(new Date(group.last_activity), 'PPPpp')}`}</Timestamp>
    </GroupCardWrapper>
  )
}

export default DisplayGroupInfo