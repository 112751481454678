import React, {useEffect} from "react";
import styled from "styled-components";
import { format } from "date-fns";
import ProjectStore from "../../../../shared/stores/ProjectStore";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import translation from "../../../../shared/utils/translation";
import ParagraphPrimary from "../../../../shared/components/text/ParagraphPrimary";
import {observer} from "mobx-react"; // For date formatting

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UpdateCard = styled.div`
  background-color: #f9f9f9;
  border-left: 4px solid lightblue; /* Color indicates change type */
  margin: 10px 0;
  padding: 20px;
  width: 80%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const CardHeader = styled.div`
  display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const ChangeTitle = styled.h3`
  margin: 0;
  font-size: 18px;
`;

const Description = styled.p`
  margin: 5px 0;
`;

const ChangeDetail = styled.details`
  font-size: 14px;
`;

const ChangeSummary = styled.summary`
  cursor: pointer;
`;

const Timestamp = styled.div`
  font-size: 12px;
  text-align: right;
  color: #666;
`;

const StateComparison = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StateColumn = styled.div`
  width: 48%; /* Slightly less than half to accommodate spacing */
  padding: 10px;
  background-color: #efefef;
  border-radius: 4px;
`;


const ProjectHistoryContent = () => {
  
  const { projectHistory, isFetching } = ProjectStore
  
  useEffect(() => {
    ProjectStore.getProjectHistory();
  }, []);
  
  const renderStateDetails = (stateJson) => {
    try {
      const state = JSON.parse(stateJson);
      if (typeof state === 'object' && state !== null && !Array.isArray(state)) {
        return Object.entries(state).map(([key, value]) => (
          <p key={key}><strong>{key}:</strong> {value}</p>
        ));
      } else {
        // Handle non-object JSON or other unexpected conditions
        return <p>Error: State details are not in expected format.</p>;
      }
    } catch (error) {
      // Handle cases where JSON parsing fails
      return <p>Error parsing state details: {error.message}</p>;
    }
  };
  
  if(isFetching || !projectHistory){
    return <LoadingSpinner/>
  }
  
  return (
    <TimelineWrapper>
      {projectHistory.map((update, index) => (
        <UpdateCard key={index}>
          <CardHeader>
            <ChangeTitle>{update.change_type}</ChangeTitle>
            <ChangeTitle>{`${translation("project.history.title.by")}: ${update.changed_by_user_id} - ${update.changed_by_name}`}</ChangeTitle>
          </CardHeader>
          
          <Description>{update.change_description}</Description>
          <ChangeDetail>
            <ChangeSummary>{translation("project.history.viewDetails")}</ChangeSummary>
            <StateComparison>
              <StateColumn>
                <ParagraphPrimary small={true}>{translation("project.history.previousState")}:</ParagraphPrimary>
                {update.previous_state && renderStateDetails(JSON.parse(update.previous_state))}
              </StateColumn>
              <StateColumn>
                <ParagraphPrimary small={true}>{translation("project.history.currentState")}:</ParagraphPrimary>
                {update.previous_state && renderStateDetails(JSON.parse(update.current_state))}
              </StateColumn>
            </StateComparison>
          </ChangeDetail>
          <Timestamp>{format(new Date(update.change_timestamp), 'PPPpp')}</Timestamp>
        </UpdateCard>
      ))}
    </TimelineWrapper>
  );
};

export default observer(ProjectHistoryContent);