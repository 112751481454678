import React from "react"
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {darkFocusBorder, whiteButtonHoverColor} from "../../utils/vars";
import ReturnIcon from "../icons/ReturnIcon";
import translation from "../../utils/translation";
import SmallText from "../text/SmallText";

const ReturnTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
`

const Icon = styled(ReturnIcon)`
`

const ReturnButtonComponent = styled.button`
  background-color: inherit;
  border: none;
  cursor: pointer;
  margin: 5px;
  border-radius: 75px;
  
  &:hover {
    background-color: ${whiteButtonHoverColor};

  }
  &:focus {
    background-color: ${whiteButtonHoverColor};
    border: ${darkFocusBorder};
  }

`

const ReturnButton = ({onClick}) => {
  return(
      <ReturnButtonComponent onClick={onClick}>
        <ReturnTextWrapper>
          <Icon/>
          <SmallText bold={true} margin={"0 5px"} small={true}>{translation("common.return")}</SmallText>
        </ReturnTextWrapper>
      </ReturnButtonComponent>

  )
}

export default observer(ReturnButton)