import React from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import { IoPersonRemoveSharp } from "react-icons/io5";
import ProjectStore from "../../../../../../shared/stores/ProjectStore";
import translation from "../../../../../../shared/utils/translation";
import {ADMINISTRATOR_ROLES} from "../../../../../../shared/utils/Constants";
import StatusText from "../../../../../../shared/components/text/StatusText";
import { format } from "date-fns";

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: center;
`;

const Th = styled.th`
    background-color: #f4f4f4;
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const RemoveUserIcon = styled(IoPersonRemoveSharp)`
    font-size: 20px;
    cursor: pointer;
`



const ActionButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`
const ProjectInvitationsTable = ({projectInvitations, cancelInviteClick, status}) => {
  
  const { selectedProject } = ProjectStore
  
  return (
    <Table>
      <thead>
      <tr>
        <Th>{translation("common.name")}</Th>
        <Th>{translation("common.email")}</Th>
        <Th>{translation("common.role")}</Th>
        <Th>{translation("common.status")}</Th>
        <Th>{translation("common.created_at")}</Th>
        {(ADMINISTRATOR_ROLES.includes(selectedProject.role) && status == "true") &&
          <Th>{translation("common.actions")}</Th>
        }
        {
          status == "false" &&<Th>{translation("common.canceledBy")}</Th>
        }
      </tr>
      </thead>
      <tbody>
      {projectInvitations.map((invitation) => (
        <tr key={invitation.invitations_id}>
          <Td>{invitation.name}</Td>
          <Td>{invitation.invited_email}</Td>
          <Td>{invitation.role_name}</Td>
          <Td><StatusText status={invitation.invitation_status}>{invitation.invitation_status}</StatusText></Td>
          <Td>{format(new Date(invitation.created_at), 'PPPpp')}</Td>
          {(ADMINISTRATOR_ROLES.includes(selectedProject.role) && status == "true") &&
            <Td>
              <ActionButtonWrapper>
                <RemoveUserIcon onClick={() => cancelInviteClick(invitation.invitation_id)}/>
              </ActionButtonWrapper>
            </Td>
          }
          {
            status == "false" &&<Td>{invitation.canceled_by_name}</Td>
          }
        
        </tr>
      ))}
      </tbody>
    </Table>
  );
}

export default observer(ProjectInvitationsTable)