import {Row} from "antd";
import ImageNameSection from "../../../../shared/components/sections/ImageNameSection";
import ProjectStore from "../../../../shared/stores/ProjectStore";
import SectionComponentSection from "../../../../shared/components/sections/SectionComponentSection";
import DescriptionSection from "../../../../shared/components/sections/DescriptionSection";
import React, {useCallback} from "react";
import ModalStore from "../../../../shared/stores/ModalStore";
import FileSection from "../../../../shared/components/sections/FileSection";
import ComponentStore from "../../../../shared/stores/ComponentStore";
import ComponentListSection from "../../../../shared/components/sections/SectionComponentSection";

const ComponentOverviewContent = ({currentComponent}) => {
  const { selectedProject } = ProjectStore
  
  const addImageOnClick = useCallback(() => {
    ModalStore.addModal({modalTopic: "project", modalName: "addImage"});
  }, []);
  
  return(
    <>
      <Row>
        <ImageNameSection object={ComponentStore.currentComponent} addImageOnClick={addImageOnClick} type={"component"} />
      </Row>
      <Row>
        <ComponentListSection canEdit={(currentComponent.project_deleted === 0 && currentComponent.component_deleted === 0 ) && selectedProject.canEdit}/>
      </Row>
      <Row>
        <DescriptionSection object={ComponentStore.currentComponent} />
      </Row>
    </>
  )
}

export default ComponentOverviewContent