import * as React from "react";
import styled from "styled-components";
import Required from "../Required";
import FormLabel from "../text/FormLabel";
import translation from "../../utils/translation";
import {useState} from "react";
import {Upload, Form} from "antd";
import WhiteButton from "../buttons/WhiteButton";
import {setImageBuffer} from "../../stores/ConfigStore";
import { Buffer } from 'buffer';
import Notifications from "../../utils/Notifications";
const CustomInputContainer = styled.div`
  position: relative;
  width: 100%;
`

const LabelSuffix = styled(FormLabel)`
  display: flex;
  justify-content: end;
  width: calc(100% - 10px);
`

const Label = styled.div`
  pointer-events: none;
  opacity: ${p => (p.disabled ? 0.3 : 1)};
  margin-bottom: 10px;
`
export const InputArea = styled.div`
  margin-top: 15px;
  overflow: hidden;
`

const normFile = (e) => {

  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};

const UploadItem =({required, field, accept}) => {
  const [extraLabel, setExtraLabel] = useState(false)
  const {
    maxLength,
    rules,
    name,
    label,
    previewImage = () => {return false},
    maxSize = 5
  } = field
  
  const MAX_FILE_SIZE = maxSize * 1024 * 1024;

  const beforeUpload = async(file) => {
    
    const fileSizeInMB = file.size / (1024 * 1024);
    if (file.size > MAX_FILE_SIZE) {
      console.log(file.size);
      Notifications("error", `notification.error.fileTooBig`, `notification.error.fileTooBig.description`, "", {fileSize: fileSizeInMB.toFixed(2), maxFileSize: maxSize});
      return false;
    }
    
    return new Promise((resolve, reject) => {

      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const bufferData = Buffer.from(arrayBuffer);
        setImageBuffer(bufferData)

        resolve(false);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);

    });
  }

  return (
      <CustomInputContainer>
        <InputArea>
          <Label>
            <FormLabel small={true} alignLine={true} labelInside={false}>
              {label ? label : translation(`form.label.${name}`)}
              {extraLabel && translation("form.label.name.extra")}

            </FormLabel>
            <LabelSuffix small={true}>
              {maxLength && `${translation("form.label.maxLength")} - ${maxLength}`}
              {required && <Required/>}
            </LabelSuffix>
          </Label>
          <Form.Item
              rules={rules}
              name={name}
              valuePropName="fileList"
              getValueFromEvent={normFile}
          >
            <Upload
              name={name}
              accept={accept}
              listType="picture"
              multiple={maxLength > 1}
              beforeUpload={beforeUpload}
              maxCount={maxLength}
              defaultFileList={previewImage() ? [previewImage()] : null}
              directory={false}
            >
              <WhiteButton>{translation("upload.text.clickHere")}</WhiteButton>
            </Upload>
          </Form.Item>
        </InputArea>
      </CustomInputContainer>

  )

}

export default UploadItem