import React from "react"
import styled from "styled-components"
import TitleSecondary from "../text/TitleSecondary";

const TextButtonComponent = styled.button`
  cursor: pointer;
  background-color: inherit;
  border: 0;
  display: flex;
  flex-direction: ${p => p.align};
  align-items: center;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`
const TextWrapper = styled.div`
  margin: ${p => p.margin ? p.margin : 0}px;
`

const TextButton = ({onClick, text, disabled, loading, children, props, icon, className, iconMargin, textMargin, align = "row"}) => {

  const disableClick = disabled || loading;
  const buttonClick = disableClick ? undefined : onClick;

  return (
      <TextButtonComponent {...props} onClick={buttonClick} className={className} align={align}>
        {icon &&
            <IconWrapper margin={iconMargin}>
              {icon}
            </IconWrapper>
        }
        {children || text &&
            <TextWrapper margin={textMargin}>
              {children && children}
              {text && text}
            </TextWrapper>
        }
      </TextButtonComponent>
  )
}

export default TextButton;
