import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import ThemeContainer from "./shared/components/ThemeContainer";
import GlobalStyle from "./shared/styles/GlobalStyle";
import 'animate.css';
import MenuWrapper from "./shared/components/menu/header-menu/MenuWrapper";
import ModalOverlay from "./shared/components/modal/ModalOverlay";
import ErrorHandler from "./shared/components/ErrorHandler";
import CookieConsent from "./shared/components/CookieConsent";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ThemeContainer>
        <MenuWrapper/>
        <CookieConsent/>
        <ErrorHandler/>
        <App />
        <ModalOverlay/>
        <GlobalStyle/>
      </ThemeContainer>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
