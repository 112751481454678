import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import translation from "../utils/translation";
import { useLocation } from "react-router-dom";

const SEO = () => {
  const location = useLocation();
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescription, setSeoDescription] = useState('');
  const image = "";
  
  useEffect(() => {
    setPageMetadata(location);
  }, [location.pathname, location.search]);
  
  const setPageMetadata = (location) => {
    let page = location.pathname.split("/")[1];
    let title = translation(`seo.${page === "" ? "start" : page}`) || "Default Title";
    let description = translation(`seo.start.description`) || "Default description";
    
    if (location.search.includes("project_id") && !location.search.includes("component_id")) {
      title = translation("seo.projectView") || title;
      description = translation("seo.project.description") || description;
    } else if (location.search.includes("project_id") && location.search.includes("component_id")) {
      title = translation("seo.componentView") || title;
      description = translation("seo.component.description") || description;
    }
    
    if (location.search.includes("content")) {
      const content = new URLSearchParams(location.search).get("content");
      if (content) {
        title = `${title} - ${translation(`seo.${content}`) || content}`;
        description = translation(`seo.${content}.description`) || description;
      }
    }
    
    setSeoTitle(title);
    setSeoDescription(description);
  };
  
  const url = `https://${window.location.hostname}${location.pathname}${location.search}`;
  
  return (
    <HelmetProvider>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href={url} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        {image && <meta property="og:image" content={image} />}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        {image && <meta name="twitter:image" content={image} />}
      </Helmet>
    </HelmetProvider>
  );
};

export default SEO;
