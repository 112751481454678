import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import TitlePrimary from "../../../shared/components/text/TitlePrimary";
import ParagraphPrimary from "../../../shared/components/text/ParagraphPrimary";

const Wrapper = styled.div`
  text-align: center;
    display: flex;
    flex-direction: column;
`

const TitleSection = () => {
  
  return (
    <Wrapper>
      <TitlePrimary styling={{letterSpacing: "5px"}}>Depth-Planner</TitlePrimary>
      <ParagraphPrimary styling={{letterSpacing: "2px"}} small={true}>The only planning tool you will need</ParagraphPrimary>
    </Wrapper>
  )
}

export default observer(TitleSection)