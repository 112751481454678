import React from 'react';
import {
  selectedLanguage as configStoreSelectedLanguage,
  defaultLanguage as configStoreDefaultLanguage
} from "../stores/ConfigStore";
import {languages} from "../locales/languages";

export default function translation(key, extra) {
  let selectedLanguage = configStoreSelectedLanguage.get() ? configStoreSelectedLanguage.get() : configStoreDefaultLanguage.get();
  let rawText = languages[selectedLanguage][key] || key;
  
  // Define a regex to match newline, placeholders, escaped quotes, and margin markers
  const parts = rawText.split(/(\n|\{\w+\}|(?<!\\)\\"|\{mt-\d+\}|\{mb-\d+\})/).map((part, index) => {
    if (part === '\n') {
      return <br key={index} />;
    } else if (part.match(/\{\w+\}/)) {
      const placeholder = part.slice(1, -1);
      const replacement = extra && extra[placeholder];
      return replacement !== undefined ? replacement : part;
    } else if (part === '\\"') {
      return { type: 'italic-toggle', key: index };
    } else if (part.match(/\{mt-\d+\}/)) {
      const marginValue = part.slice(4, -1);
      return <div key={index} style={{ marginTop: `${marginValue}px` }} />;
    } else if (part.match(/\{mb-\d+\}/)) {
      const marginValue = part.slice(4, -1);
      return <div key={index} style={{ marginBottom: `${marginValue}px` }} />;
    }
    return part;
  });
  
  const processedParts = [];
  let italicMode = false;
  
  parts.forEach((part, index) => {
    if (part.type === 'italic-toggle') {
      italicMode = !italicMode;
    } else if (italicMode && typeof part === 'string') {
      processedParts.push(<i key={index}>{part}</i>);
    } else if (italicMode && React.isValidElement(part)) {
      processedParts.push(<i key={index}>{part}</i>);
    } else {
      processedParts.push(part);
    }
  });
  
  const isAnyPartJsx = processedParts.some(part => React.isValidElement(part));
  
  if (isAnyPartJsx) {
    return <>{processedParts}</>;
  } else {
    return processedParts.join('');
  }
}