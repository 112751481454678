import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import ParagraphBold from "../../../../../../shared/components/text/ParagraphBold";
import translation from "../../../../../../shared/utils/translation";

const Wrapper = styled.div`
    margin: 25px 0;
    display: flex;
    justify-content: space-around;
`

const OptionWrapper = styled.div`
    cursor: pointer;
    width: 150px;
    text-align: center;
`

const UnderLine = styled.div`
    border: 2px solid ${p => p.isActive ? "lightblue" : "black"};
    border-radius: 8px;
    ${OptionWrapper}:hover & {
        border: 2px solid lightblue;
    }
`

const DeletedTabsSection = () => {
  const activeTab = sessionStorage.getItem('deletedActiveTab');
  const [currentActiveTab, setCurrentProjects] = useState(activeTab == "project" ? null : activeTab)
  const navigate = useNavigate();
  
  const onTabClick = (deletedTypeToFetch) => {
    sessionStorage.setItem('deletedActiveTab', deletedTypeToFetch);
    if(deletedTypeToFetch === "project"){
      setCurrentProjects(null)
      navigate(`/profile?content=deleted`)
      return
    }
    setCurrentProjects(deletedTypeToFetch)
    navigate(`/profile?content=deleted&activeTab=${deletedTypeToFetch}`)
  }
  
  return (
    <Wrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("project")}>{translation("profile.deleted.tab.project")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == null || currentActiveTab == "project"}/>
      </OptionWrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("section")}>{translation("profile.deleted.tab.section")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == "section"}/>
      </OptionWrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("component")}>{translation("profile.deleted.tab.component")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == "component"}/>
      </OptionWrapper>
    </Wrapper>
  );
};

export default observer(DeletedTabsSection);