import React from "react"
import styled from "styled-components"

const Text = styled.p`
  font-size: ${p => p.small ? 12 : 14}px;
  font-weight: ${p => p.bold ? "normal": "bold"};
  overflow-wrap: break-word;
  margin: ${p => p.margin ? p.margin : 0};
`

const SmallText = ({ children, margin = false, small = false, alignLine = false }) => {
  return(
      <Text margin={margin} small={small.toString()} alignLine={alignLine} >
        {children}
      </Text>
  )
}

export default SmallText