import React from "react"
import {observer} from "mobx-react-lite";
import OutlineCloseIcon from "../icons/OutlineCloseIcon";
import styled from "styled-components";
import {darkFocusBorder, menuTextColor, whiteButtonHoverColor} from "../../utils/vars";

const Icon = styled(OutlineCloseIcon)`
  transition: rotate .2s ease-in-out;
  border-radius: 100%;
`

const ClosedButtonComponent = styled.button`
  background-color: inherit;
  border: none;
  cursor: pointer;
  margin: 5px;
  
  color: ${p => p.position == "modal" ? "black" : menuTextColor};
  
  
  &:hover ${Icon}{
    rotate: 90deg;
    background-color: ${whiteButtonHoverColor};
  }
  &:focus ${Icon}{
    rotate: 90deg;
    background-color: ${whiteButtonHoverColor};
    border: ${darkFocusBorder};
  }

`

const CloseButton = ({onClick, position="modal"}) => {
  return(
    <ClosedButtonComponent onClick={onClick} position={position}>
      <Icon/>
    </ClosedButtonComponent>

  )
}

export default observer(CloseButton)