import {makeAutoObservable, runInAction} from "mobx";
import ApiGateway from "../api/ApiGateway";
import ComponentStore from "./ComponentStore";
import ProjectStore from "./ProjectStore";
import ModalStore from "./ModalStore";

class SectionStore {

  section = []
  sectionIsLoading = false;
  selectedSectionId = null
  totalCountBySection = {}
  sectionForIndex = {}
  selectedSection = {}
  deletedSections = []
  
  isLoading = false;
  isFetchingDeleted = false;
  constructor() {
    makeAutoObservable(this)
    this.sectionPagination = this.sectionPagination.bind(this);
  }
  
  async loadSection(projectId, componentId) {
    if (this.sectionIsLoading) return;
    runInAction(() => {
      this.sectionIsLoading = true;
      this.sectionForIndex = {};
      this.section = []
    });
    
    try {
      const result = await ApiGateway.section.getSection(projectId, componentId);
      
      if (result.result.length < 1) {
        runInAction(() => {
          this.section = [];
          this.sectionIsLoading = false;
        });
        return;
      }
      
      const sectionIds = result.result.map(value => value.section_id);
      const loadComponentsResult = await ComponentStore.loadSectionComponents({ sectionIds });
      this.totalCountBySection = loadComponentsResult.totalCountBySection;
      
      runInAction(() => {
        this.section = result.result.map((section) => {
          this.sectionForIndex[section.section_id] = 0;
          return {
            ...section,
            components: loadComponentsResult.componentsBySection[section.section_id] || [],
          };
        });
        this.sectionIsLoading = false;
      });
    } catch (error) {
      console.error("Failed to load sections:", error);
      runInAction(() => {
        this.sectionIsLoading = false;
      });
    }
  }

  async addSection(data){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    if(ComponentStore.currentComponent.component_id){
      data.values.componentId = ComponentStore.currentComponent.component_id
    }
    
    let result = await ApiGateway.section.addSection(data, ProjectStore.selectedProject.project_id);
    if(result.success){
      this.section.push({section_id: result.insertId, name:data.values.name})
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async editSection(data){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    let index = this.section.findIndex(section => section.section_id === this.selectedSection.section_id);
    
    data.values.projectId = ProjectStore.selectedProject.project_id
    
    let result = await ApiGateway.section.editSection(data, this.selectedSection.section_id);
    if(result.success){
      this.section[index].name = data.values.name;
      
      this.selectedSection = {}
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async softDeleteSection(){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    let result = await ApiGateway.section.softDeleteSection(this.selectedSection.section_id);
    
    this.section = this.section.filter(section => section.section_id !== this.selectedSection.section_id);
    this.selectedSection = null;
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }

  async sectionPagination(direction, sectionId){
    let indexShift = 0;
    switch(direction){
      case "left":
        indexShift = -6;
        break;
      case "right":
        indexShift = 6;
        break;
      default:
        indexShift = 0;
        break;
    }
    
    let fromIndex = this.sectionForIndex[sectionId] + indexShift;
    this.sectionForIndex[sectionId] = this.sectionForIndex[sectionId] + indexShift;
    let result = await ComponentStore.loadSectionComponents(sectionId, fromIndex);
    let foundSection = this.section.find(section => section.section_id == sectionId);
    if (foundSection) {
      if (!foundSection.components) {
        foundSection.components = [];
      }
      foundSection.components = result.componentsBySection[sectionId];
    } else {
      console.log("Section not found");
    }
  }
  
  setSelectedSection(sectionId){
    this.selectedSection = sectionId;
  }
  
  async getDeleted(){
    if(this.isFetchingDeleted) return
    this.isFetchingDeleted = true
    
    let result = await ApiGateway.section.getDeleted();
    
    this.deletedSections = result.result;
    
    this.isFetchingDeleted = false
  }
  
  async restoreSection(){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    let result = await ApiGateway.section.restoreSection(this.selectedSection.section_id);
    
    if(result.success){
      this.deletedSections = this.deletedSections.filter(section => section.section_id !== this.selectedSection.section_id);
      this.selectedSection = null;
    }
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async permDeleteSection(){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    let result = await ApiGateway.section.permDeleteSection(this.selectedSection.section_id);
    
    if(result.success){
      this.deletedSections = this.deletedSections.filter(section => section.section_id !== this.selectedSection.section_id);
      this.selectedSection = null;
    }
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async reset(){
    this.section = []
    this.selectedProject = []
    this.selectedSectionId = null
    this.totalCountBySection = {}
    this.sectionForIndex = {}
  }

}

export default new SectionStore()