import * as React from "react"
import {Alert, Form} from "antd";
import TitlePrimary from "../../text/TitlePrimary";
import DefaultFormInput from "../../input/DefaultFormInput";
import styled from "styled-components";
import DangerButton from "../../buttons/DangerButton";
import Spacer from "../../Spacer";
import LinkText from "../../text/LinkText";
import translation from "../../../utils/translation";
import ModalStore from "../../../stores/ModalStore";
import WhiteButton from "../../buttons/WhiteButton";
import {useMemo} from "react";
import UploadItem from "../../input/UploadItem";
import UserStore from "../../../stores/UserStore";
import {observer} from "mobx-react";
import ParagraphBold from "../../text/ParagraphBold";
import LoadingSpinner from "../../LoadingSpinner";

const ModalActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const SignInOrSignUpWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`


const DescriptionWrapper = styled.div`
  margin: 10px 0 10px 0;
`

const DescriptionText = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
`

const SoftDescriptionText = styled.p`

`

const UserDisplayBox = styled.div`
  border: 1px solid #ccc; /* Light grey border */
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px; /* Rounded corners */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9; /* Light background */
`;

const Overlay = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  color: white;
`;

const EmailNotVerifiedWrapper = styled.div`
  color: #FFA652;
`
const ResendLink = styled.a`
  color: blue;
`

const InputModalTemplate = ({ form, modalObject }) => {
  const { modal, currentStep } = modalObject;
  const { fileAccept = "*", title, alertType, currentFile, softDescription, alertText, action, emailNotVerified, fields, buttonText, multiStep, steps, validationFields, submitFunction, description, singleButton, displayUserInformation = false } = modal.length > 0 ? modal[modal.length - 1] : modal;
  const { userDataToBeAdded } = UserStore
  const isSignUpOrSignInForm = action === "signup" || action === "signin";
  const { isLoading } = ModalStore
  
  const openModal = useMemo(() => ({ modalTopic, modalName }) => {
    ModalStore.addModal({ modalTopic, modalName });
  }, []);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields(validationFields);
      let result = await submitFunction({ values });
  
      if(result.success){
        ModalStore.hideModal();
        form.resetFields();
      }
      
    } catch (errorInfo) {
      console.error("Validation Error:", errorInfo);
    }
  };

  const handleCancel = () => {
    ModalStore.hideModal();
  };

  const nextStep = async (e) => {
    try {
      await form.validateFields(validationFields);
      ModalStore.continueModal();
    } catch (errorInfo) {
      e.preventDefault();
    }
  };

  const renderModalContent = () => {
    const contentFields = multiStep ? steps[currentStep].fields : fields;
    if(!contentFields) {return}
    
    return contentFields.map((field) => (
        field.isUpload ? <UploadItem file={currentFile} accept={fileAccept} key={field.name} field={field} /> : <DefaultFormInput form={form} key={field.name} field={field} />
    ));
  };

  return (
      <Form form={form}>
        <Overlay show={isLoading}><LoadingSpinner/></Overlay>
        <TitlePrimary small={true}>{title}</TitlePrimary>
        
        {alertType ?
          <Alert message={alertText} type={alertType}/>
          :
          alertText
        }
        {description && (
          <DescriptionWrapper>
            <DescriptionText>{description}</DescriptionText>
          </DescriptionWrapper>
        )}
        {softDescription && (
          <DescriptionWrapper>
            <SoftDescriptionText>{softDescription}</SoftDescriptionText>
          </DescriptionWrapper>
        )}
        
        {renderModalContent()}
        {emailNotVerified &&
          <EmailNotVerifiedWrapper>
            <span>{translation("modal.emailNotVerified.span", {
              link: <ResendLink key={"resend"}
                       onClick={() => UserStore.resendEmailVerification()}>{translation("link.clickHere")}</ResendLink>
            })}</span>
          
          </EmailNotVerifiedWrapper>
          
        }
        {isSignUpOrSignInForm && (
          <SignInOrSignUpWrapper>
            {action === "signin" ? (
                  <>
                    <LinkText onClick={() => openModal({ modalTopic: "user", modalName: "signUp" })}>
                      {translation("common.signUp")}
                    </LinkText>
                    <LinkText onClick={() => openModal({modalTopic: "user", modalName: "forgotPassword"})}>
                      {translation("form.forgotPassword")}
                    </LinkText>
                  </>
              ) : (
                  <LinkText onClick={() => openModal({ modalTopic: "user", modalName: "signIn" })}>
                    {translation("common.signIn")}
                  </LinkText>
              )}
            </SignInOrSignUpWrapper>
        )}
        
        {displayUserInformation == true && userDataToBeAdded != null && (
          <UserDisplayBox>
            <ParagraphBold>User-id: {userDataToBeAdded.user_id}</ParagraphBold>
            <ParagraphBold>{translation("form.label.name")}: {userDataToBeAdded.name}</ParagraphBold>
            <ParagraphBold>{translation("form.label.username")}: {userDataToBeAdded.username}</ParagraphBold>
          </UserDisplayBox>
        )}
        
        <ModalActionButtonWrapper>
          {multiStep && steps.length > currentStep + 1 ? (
              <WhiteButton loading={isLoading} text={buttonText?.cancel != undefined ? buttonText.cancel : translation("form.continue")} onClick={nextStep}/>
          ) :
            singleButton ?
              <WhiteButton loading={isLoading} text={buttonText} onClick={handleSubmit}/>
                :
            (
              <>
                <WhiteButton loading={isLoading} text={buttonText?.submit != undefined ? buttonText.submit : translation("form.submit")} onClick={handleSubmit}/>
                <Spacer />
                <DangerButton loading={isLoading} text={buttonText?.cancel != undefined ? buttonText.cancel : translation("form.cancel")} onClick={handleCancel}/>
              </>
          )}
        </ModalActionButtonWrapper>
      </Form>
  );
};

export default observer(InputModalTemplate);