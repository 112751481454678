import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import ProjectStore from "../../../../../shared/stores/ProjectStore";
import LoadingSpinner from "../../../../../shared/components/LoadingSpinner";
import DeletedProjectCard from "./components/DeletedCard";
import {Row} from "antd";
import DeletedTabsSection from "./components/DeletedTabsSection";
import DeletedProjectTab from "./components/tabs/DeletedProjectTab";
import {useQuery} from "../../../../../shared/utils/useQuery";
import DeletedSectionTab from "./components/tabs/DeletedSectionTab";
import DeletedComponentTab from "./components/tabs/DeletedComponentTab";

const DeletedWrapperComponent = styled.div`
    margin-top: 25px;
    width: 100%;
`;
const DeletedWrapper = () => {
  const queryParams = useQuery();
  const tab = queryParams.get("activeTab");
  const activeTab = sessionStorage.getItem('deletedActiveTab');
  
  const loadDetailedView = () => {
    switch(activeTab){
      case "section":
        return <DeletedSectionTab/>
      case "component":
        return <DeletedComponentTab/>
      case "project":
      default:
        return <DeletedProjectTab/>
    }
  }
  
  return (
    <DeletedWrapperComponent>
      <DeletedTabsSection/>
      {loadDetailedView()}
    </DeletedWrapperComponent>
  )
}

export default observer(DeletedWrapper)
