import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {gutter} from "../utils/vars";
import {useLocation} from "react-router-dom";

const Wrapper = styled.section`
`
const getMargin = (path, gutter) => {
  if (path === "/profile") {
    return '0';
  }
  return `0 ${gutter * 3}px`;
};

const Main = styled.main`
  overflow-x: auto;
  max-width: 1200px;
  
    ${p => p.path === "/profile" ?
            `margin: ${({margin}) => margin};`
            :
            `margin: auto;`
    };
  

  @media (max-width: 1300px) {
    margin: ${({ margin }) => margin};
  }
`;

const PageWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const margin = getMargin(pathname, gutter);
  
  return (
    <Wrapper>
      <Main margin={margin} path={pathname}>{children}</Main>
    </Wrapper>
  );
};

export default observer(PageWrapper);