import * as React from "react"
import {observer} from "mobx-react-lite";
import TitlePrimary from "../../text/TitlePrimary";
import SubmitButton from "../../buttons/FormSubmitButton";
import styled from "styled-components";
import DangerButton from "../../buttons/DangerButton";
import Spacer from "../../Spacer";
import translation from "../../../utils/translation";
import modalStore from "../../../stores/ModalStore";
import {useNavigate} from "react-router-dom";
import ModalStore from "../../../stores/ModalStore";
import LoadingSpinner from "../../LoadingSpinner";
import {Alert} from "antd";

const ModalWrapper = styled.div`
`

const ModalActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ModalTextWrapper = styled.div`
  margin-top: 15px;
`
const Overlay = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  color: white;
`;

const ConfirmationModalTemplate = ({modalObject}) => {
  
  const navigate = useNavigate()

  const {
    modal,
  } = modalObject

  const {
    title= "",
    buttonText= {},
    text = "",
    submitFunction,
    alertType = false
  } = modal.length > 0 ? modal[modal.length - 1] : modal
  
  const { isLoading } = ModalStore

  const handleSubmit = async(values) => {
    let status = await submitFunction({values});
    if(status.success){
      modalStore.hideModal()
    }
    if(status.navigateTo){
      navigate(status.navigateTo)
    }
  }

  const handleCancel = () => {
    modalStore.hideModal();
  }

  return (
      <ModalWrapper>
        <Overlay show={isLoading}><LoadingSpinner/></Overlay>
        <TitlePrimary small={true}>{title}</TitlePrimary>

        <ModalTextWrapper>
          {alertType ?
            <Alert message={text} type={alertType}/>
            :
            text
          }
         
        </ModalTextWrapper>

        <ModalActionButtonWrapper>
          <SubmitButton loading={isLoading} text={buttonText.submit != undefined ? buttonText.submit : translation("form.submit")} onClick={handleSubmit}/>
          <Spacer/>
          <DangerButton loading={isLoading} text={buttonText.cancel != undefined ? buttonText.cancel : translation("form.cancel")} onClick={handleCancel}/>
        </ModalActionButtonWrapper>
      </ModalWrapper>
  )
}

export default observer(ConfirmationModalTemplate)
