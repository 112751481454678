import React from "react"
import styled from "styled-components"

const Text = styled.p`
  font-size: 14px;
  font-weight: ${p => p.bold ? "normal": "bold"};
  overflow-wrap: break-word;
  margin: ${p => p.margin ? p.margin : 0};
    color: ${p => p.status == "accepted" ? "green" : p.status == "pending" ? "#E4BB3B" : p.status == "declined" ? "red" : "black"}
`

const StatusText = ({ children, status }) => {
  return(
    <Text status={status} >
      {children}
    </Text>
  )
}

export default StatusText