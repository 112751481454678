import styled from 'styled-components';
import { IoPersonRemoveSharp } from "react-icons/io5";
import { AiFillEdit } from "react-icons/ai";
import ProjectStore from "../../../../../../shared/stores/ProjectStore";
import translation from "../../../../../../shared/utils/translation";
import {ADMINISTRATOR_ROLES} from "../../../../../../shared/utils/Constants";

const UsersCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
`;

const UserInfo = styled.div`
  margin: 5px 0;
`;

const ActionWrapper = styled.div`
  display:flex;
  justify-content: space-between;
`

const InfoLabel = styled.span`
  font-weight: bold;
`;

const ActionButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const RemoveUserIcon = styled(IoPersonRemoveSharp)`
  font-size: 20px;
  cursor: pointer;
  margin-left: 25px;
`

const EditUserIcon = styled(AiFillEdit)`
  font-size: 20px;
  cursor: pointer;
`

const UsersCard = ({projectUsers, editUserOnClick, removeUserOnClick}) => {
  const { selectedProject } = ProjectStore
  
  return(
    <>
      {
        projectUsers.map((user) => (
          <UsersCardWrapper key={user.user_id}>
            <UserInfo><InfoLabel>{translation("common.name")}:</InfoLabel> {user.name}</UserInfo>
            <UserInfo><InfoLabel>{translation("common.email")}:</InfoLabel> {user.email}</UserInfo>
            <UserInfo><InfoLabel>{translation("common.role")}:</InfoLabel> {user.role_name}</UserInfo>
            {
              user.role != "owner" &&
            <ActionWrapper><InfoLabel>{translation("common.actions")}:</InfoLabel>
              <ActionButtonContainer>
                {ADMINISTRATOR_ROLES.includes(selectedProject.role) && <>
                  <EditUserIcon onClick={() => editUserOnClick(user.user_id)} />
                  <RemoveUserIcon onClick={() => removeUserOnClick(user.user_id)} />
                </>}
              </ActionButtonContainer>
            </ActionWrapper>
            }
          </UsersCardWrapper>
        ))
      }
    </>
  )
}

export default UsersCard