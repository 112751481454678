import * as React from "react"
import styled from "styled-components"
import {sizes} from "../../../../shared/utils/media";
import ProfileStore from "../../../../shared/stores/ProfileStore";
import {useEffect} from "react";
import ModalStore from "../../../../shared/stores/ModalStore";
import {observer} from "mobx-react";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";

const ProfilePictureContainer = styled.div`
    position: absolute;
    height: 150px;
    width: 150px;
    left: 25px;
    top: 80px;
    z-index: 3;
    @media only screen and (max-width:${sizes.tablet}px){
        left: calc(50vw - 75px);
    }
    @media only screen and (max-width:${sizes.phone}px){
        left: calc(50vw - 75px);
        top: 60px;
    }
    border-radius: 100%;
    cursor: pointer;
`

const ProfilePictureComponent = () => {
  const { userProfilePicture, isFetchingProfilePicture } = ProfileStore;
  
  useEffect(() => {
    if (!userProfilePicture) {
      ProfileStore.fetchProfilePicture();
    }
  }, [userProfilePicture]);
  
  const addProfilePicture = () => {
    ModalStore.addModal({ modalTopic: "profile", modalName: "addProfilePicture" });
  };
  
  const editProfilePicture = () => {
    ModalStore.addModal({ modalTopic: "profile", modalName: "editProfilePicture" });
  };
  
  if(isFetchingProfilePicture){
    return <LoadingSpinner/>
  }
  
  return (
    <ProfilePictureContainer
      onClick={() => !userProfilePicture ? addProfilePicture() : editProfilePicture()}
      style={{
        backgroundImage: userProfilePicture.length > 0 ? `url(${userProfilePicture})` : undefined,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: userProfilePicture.length > 0 ? undefined : "black",
      }}
    />
  );
};

export default observer(ProfilePictureComponent);
