import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react";
import ParagraphBold from "../../../shared/components/text/ParagraphBold";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useQuery} from "../../../shared/utils/useQuery";
import translation from "../../../shared/utils/translation";
import {setStorage} from "../../../services/setStorage";

const Wrapper = styled.div`
    margin: 25px 0;
    display: flex;
    justify-content: space-around;
`

const OptionWrapper = styled.div`
    cursor: pointer;
    width: 150px;
    text-align: center;
`

const UnderLine = styled.div`
    border: 2px solid ${p => p.isActive ? "lightblue" : "black"};
    border-radius: 8px;
    ${OptionWrapper}:hover & {
        border: 2px solid lightblue;
    }
`

const ProjectTabsSection = () => {
  const queryParams = useQuery();
  const tab = queryParams.get("activeTab");
  let activeTab = sessionStorage.getItem('projectActiveTab');
  const [currentActiveTab, setCurrentActiveTab] = useState(null )
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof activeTab === 'string') {
      activeTab = activeTab.replace(/^"|"$/g, ''); // Removes double quotes at the start and end if any
    }
    if(activeTab == "all"){
      setCurrentActiveTab(null)
      navigate(`/project`)
      return
    }
    setCurrentActiveTab(activeTab)
  },[tab, activeTab])
  
  const onTabClick = (projectsToFetch) => {
    if (typeof projectsToFetch === 'string') {
      projectsToFetch = projectsToFetch.replace(/^"|"$/g, ''); // Removes double quotes at the start and end if any
    }
    setStorage("projectActiveTab", projectsToFetch, "sessionStorage", "necessary")
    if(projectsToFetch == "all"){
      setCurrentActiveTab(null)
      navigate(`/project`)
      return
    }
    setCurrentActiveTab(projectsToFetch)
    navigate(`/project?activeTab=${projectsToFetch}`)
  }
  
  return (
    <Wrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("all")}>{translation("project.tab.all")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == null}/>
      </OptionWrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("myProjects")}>{translation("project.tab.myProjects")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == "myProjects"}/>
      </OptionWrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("shared")}>{translation("project.tab.shared")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == "shared"}/>
      </OptionWrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("group")}>{translation("project.tab.group")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == "group"}/>
      </OptionWrapper>
    </Wrapper>
  );
};

export default observer(ProjectTabsSection);