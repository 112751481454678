import React from "react"
import styled from "styled-components"

const MenuHeaderContainer = styled.div`
  display: ${p => p.isOpen ? "flex" : "none"};
  justify-content: end;
  margin: 10px 10px;
`

const MenuHeader = ({children, isOpen}) => {

  return (
      <MenuHeaderContainer isOpen={isOpen}>
        {children}
      </MenuHeaderContainer>
  );
}

export default MenuHeader;
