import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ProjectStore from "../../../../../shared/stores/ProjectStore";
import LoadingSpinner from "../../../../../shared/components/LoadingSpinner";
import styled from "styled-components"
import ProfileProjectsDisplayTable from "./ProfileProjectsDisplayTable";
import CustomDivider from "../../../../../shared/components/PageSectionDivider";
import ParagraphBold from "../../../../../shared/components/text/ParagraphBold";
import {sizes} from "../../../../../shared/utils/media";
import translation from "../../../../../shared/utils/translation";

const StandardContentWrapper = styled.div`
    @media only screen and (max-width:${sizes.tablet}px){
        width: 100vw;
    }
`

const ProfileStandardContent = observer(() => {
  
  const { latestCreatedProjects, latestUpdatedProjects, isLoading } = ProjectStore
  
  useEffect(() => {
    ProjectStore.getLatestProjects();
  }, []);
  
  if((latestCreatedProjects.length < 1 && latestUpdatedProjects.length < 1) || isLoading){
    return <LoadingSpinner/>
  }
  
  return (
    <StandardContentWrapper>
      <div>
        <CustomDivider orientation={"center"}><ParagraphBold>{translation("profile.home.latest.created.project")}</ParagraphBold></CustomDivider>
        <ProfileProjectsDisplayTable type={"latestCreated"} projects={latestCreatedProjects}/>
      </div>
      <div>
        <CustomDivider orientation={"center"}><ParagraphBold>{translation("profile.home.latest.updated.project")}</ParagraphBold></CustomDivider>
        <ProfileProjectsDisplayTable type={"latestUpdated"} projects={latestUpdatedProjects} />
      </div>
    </StandardContentWrapper>
  );
});

export default ProfileStandardContent;