

import * as React from "react"
import { GrProjects } from "react-icons/gr";
import styled from "styled-components";
import {menuTextColor} from "../../utils/vars";


const OutlineProjects = styled(GrProjects)`
  font-size: 24px;
  path {
    stroke: ${menuTextColor};
  }
`

const OutlineProjectsIcon = ({className, onClick}) => {
  return (
      <OutlineProjects onClick={onClick} className={className}/>
  )
}

export default OutlineProjectsIcon