import React from "react"
import styled from "styled-components"
import TitleSecondary from "../text/TitleSecondary";
import {baseButtonBackgroundColor, baseButtonHoverColor, darkFocusBorder, gutter} from "../../utils/vars";
import LoadingSpinner from "../LoadingSpinner";

const BaseButtonComponent = styled.button`
    background-color: ${baseButtonBackgroundColor};
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    min-width: 150px;
    text-align: center;
    transition: background 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
    padding: ${gutter}px 0;
    width: 100%;
    max-width: 100%;
    margin: 10px auto;

    &:disabled,
    &[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed; /* Ensures the cursor indicates the button is not interactive */
    }
    &:focus {
        border: ${darkFocusBorder};
    }
    &:not(:disabled):hover { /* This ensures hover effects are only applied if the button is not disabled */
        background-color: ${baseButtonHoverColor};
    }
`

const BaseButton = ({onClick, text, loading = false, children, type = "button", small, props, className}) => {

  const renderButtonContent = () => {
    return text ? (
        <TitleSecondary small={small}>
          {text}
        </TitleSecondary>
    ) :(
        <span>{children}</span>
    )
  }

  return (
      <BaseButtonComponent {...props} className={className} disabled={loading} type={type} onClick={onClick}>
        {!loading ? renderButtonContent() : <LoadingSpinner small={true}/>}
      </BaseButtonComponent>
  )
}

export default BaseButton;
