import {action, observable} from "mobx"
import {clearDataBasedOnPreferences, setStorage} from "../../services/setStorage";

export const setCookieLanguage = action((lang) => {
  if(!lang)throw Error("No entered language")
  setStorage('selectedLanguage', lang, "cookieStorage","necessary");
})

export const setUserLoggedInCookie = action((token) => {
  if(!token)throw "No entered token"
  setStorage('SID', token, "cookieStorage","necessary");
  setStorage('logged_in', true, "cookieStorage","necessary");
})

export const saveCookiePreferences = action((preferences) => {
  displayCookieModal.set(false)
  clearDataBasedOnPreferences(preferences)
  setStorage('cookiePreferences', preferences, "localStorage", "necessary");
})

export const getCookiePreferences = action(() => {
  const preferences = localStorage.getItem('cookiePreferences');
  return preferences ? JSON.parse(preferences) : null;
})

export const displayCookieModal = observable.box(false)
