import Cookie from "js-cookie";
import Notifications from "../utils/Notifications";
import errorStore from "../stores/ErrorStore";
import translation from "../utils/translation";
import UserStore from "../stores/UserStore";

export const API_URL = "https://www.studydeluxe.com/"

const returnHomeErrors = ["MISSING_PERMISSION"]

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  
  let timeString = '';
  if (hours > 0) {
    timeString += `${hours}h `;
  }
  if (minutes > 0) {
    timeString += `${minutes}m`;
  }
  if (hours === 0 && minutes === 0) {
    timeString = 'less than a minute';
  }
  return timeString.trim();
}

export default async function IdeasApi(path, method = 'GET', body = null, headers = {}) {
  let extraTitle = ""
  let extraDesc = ""
  const token = Cookie.get("SID");
  const finalHeaders = { ...headers };
  
  // If the body is an instance of FormData, let the browser set Content-Type header
  if (body instanceof FormData) {
    finalHeaders['token'] = token; // Add the token header
  } else if (body !== null) {
    finalHeaders['Content-Type'] = 'application/json';
    finalHeaders['token'] = token;
    body = JSON.stringify(body);
  } else {
    finalHeaders['token'] = token; // Still set the token but leave body as null
  }
  
  const config = {
    method: method,
    headers: finalHeaders,
    body: body
  };
  
  if (method.toUpperCase() === 'GET' || method.toUpperCase() === 'HEAD') {
    delete config.body; // Body should not be used with GET/HEAD requests
  }
  const response = await fetch(`${API_URL}${path}`, config);
  
  const result = await response.json();
  
  let description = "";
  if(result.message && result.message.includes("_LIMIT_REACHED")){
    description = "notification.subscription.limit.reached"
  }
  
  if ((response.status == 204 || response.status == 401) && result.message) {
    if(result.message == "EMAIL_NOT_VERIFIED"){
      description = "notification.EMAIL_NOT_VERIFIED.description";
      extraDesc = {
        link: <a style={{color: "blue"}} key={"resend"} onClick={() => UserStore.resendEmailVerification(result.email)}>{translation("link.sendNewEmailVerificationCode")}</a>
      }
    }
    Notifications("warning", `notification.warning.${result.message}`, description, extraTitle, extraDesc)
  }
  
  if (response.status >= 200 && response.status < 400 && result.message && response.status != 204) {
    if(result.message == "USER_REGISTERED"){
      description = "notification.USER_REGISTERED.description";
    }
    if(result.message == "PROJECT_SOFT_DELETED"){
      description = "notification.PROJECT_SOFT_DELETED.description";
    }
    if(result.message == "SECTION_SOFT_DELETED"){
      description = "notification.SECTION_SOFT_DELETED.description";
    }
    if(result.message == "COMPONENT_SOFT_DELETED"){
      description = "notification.COMPONENT_SOFT_DELETED.description";
    }
    Notifications("success", `notification.success.${result.message}`, description, extraTitle, extraDesc)
  }
  
  if (response.status >= 400 && result.message && response.status != 401) {
    if (returnHomeErrors.includes(result.message)) {
      errorStore.setNavigatePath('/');
    }
    if(result.message === "NOT_LOGGED_IN"){
      description = "notification.NOT_LOGGED_IN.description"
      errorStore.setNavigatePath('/');
    }
    if(response.status == 429 && result.message == "ACCOUNT_LOCKED"){
      description = "notification.ACCOUNT_LOCKED.description"
      extraDesc={
        time: formatTime(result.lockout_remaining)
      }
    }
    if(result.message == "RATE_LIMIT_EXCEEDED"){
      description = "notification.RATE_LIMIT_EXCEEDED.description"
      extraDesc = {
        time: result.time,
        limit: result.limit,
      }
    }
    Notifications("error", `notification.error.${result.message}`, description, extraTitle, extraDesc)
  }
  
  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    if (response.status == 500) {
      throw new Error(message);
    }
  }
  
  return result;
}
