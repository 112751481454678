import * as React from "react"
import styled from "styled-components"
import { observer } from 'mobx-react';
import {useQuery} from "../../shared/utils/useQuery";
import ProjectOverview from "./ProjectOverview";
import ComponentOverview from "./ComponentOverview";
import CustomBreadcrumb from "../../shared/components/CustomBreadcrumb";
import ComponentStore from "../../shared/stores/ComponentStore";
import {toJS} from "mobx";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 24px;
`

const OverviewDisplay = observer(() => {
  let queryParams = useQuery();
  let projectId = queryParams.get("project_id");
  let componentId = queryParams.get("component_id");
  
  const loadContent = () => {
    if(projectId !== null && componentId === null){
      return <ProjectOverview />;
    }
    if(projectId !== null && componentId !== null){
      return <ComponentOverview />;
    }
  };
  
  return (
    <Wrapper>
      <CustomBreadcrumb items={toJS(ComponentStore.breadcrumbComponentList)}/>
      {loadContent()}
    </Wrapper>
  );
});

export default OverviewDisplay;