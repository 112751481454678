import React from "react"
import styled from "styled-components"
import BaseButton from "./BaseButton";
import {
  redButtonBackgroundColor,
  whiteButtonBackgroundColor,
  dangerColor, redButtonHoverColor, whiteButtonHoverColor
} from "../../utils/vars";

const DangerButton = styled(BaseButton)`
  background-color: ${p => p.fill ? redButtonBackgroundColor : whiteButtonBackgroundColor};
  color: ${dangerColor};
  border: 1px solid black;
  
  &:hover, &:focus {
    background-color: ${p => p.fill ? redButtonHoverColor : whiteButtonHoverColor};
  }
`

export default DangerButton;
