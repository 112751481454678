import React from "react"
import styled from "styled-components"
import TitleSecondary from "../text/TitleSecondary";
import BaseButton from "./BaseButton";
import {whiteButtonBackgroundColor, whiteButtonHoverColor} from "../../utils/vars";

const PrimaryButtonComponent = styled(BaseButton)`
  background-color: ${whiteButtonBackgroundColor};
  border: 1px solid black;
  &:hover, &:focus {
    background-color: ${whiteButtonHoverColor}
  }
  
`

const WhiteButton = ({onClick, loading = false, text, children, type = "button", small = false, props}) => {

  const renderButtonContent = () => {
    return text ? (
        <TitleSecondary small={small}>
          {text}
        </TitleSecondary>
    ) :(
        <span>{children}</span>
    )
  }

  return (
      <PrimaryButtonComponent loading={loading} {...props} type={type} onClick={onClick}>
        {renderButtonContent()}
      </PrimaryButtonComponent>
  )
}

export default WhiteButton;
