import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {lightBgHover} from "../../utils/vars";
import { AiOutlinePlus } from "react-icons/ai";
import UserStore from "../../stores/UserStore";
import ModalStore from "../../stores/ModalStore";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: ${p => p.small === "true" ? "150px" : "250px"};
  width: ${p => p.small === "true" ? "100px" : "200px"};
  border: 2px solid black;
  cursor: pointer;
  border-radius: 5px;
  :hover{
    background-color: ${lightBgHover};
  }
`
const PlusIcon = styled(AiOutlinePlus)`
  font-size: 65px;
  margin: auto;
`

const ActionCard = ({onClick, small = false}) => {

  const cardClicked = () => {
    if(!UserStore.isLoggedIn){
      ModalStore.addModal({modalTopic: "user",modalName:"signIn"})
      return
    }
    onClick();
  }

  return (
    <Wrapper onClick={cardClicked} small={small.toString()}>
      <PlusIcon/>
    </Wrapper>
  )
}

export default observer(ActionCard)