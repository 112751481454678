import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {blackShadow, lightBgHover} from "../../utils/vars";
import { Col } from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";

const Card = styled.button`
  display: flex;
  height: ${p => p.small ? "150px" : "250px"};
  width: ${p => p.small ? "100px" : "200px"};
  border: 2px solid black;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  overflow-wrap: break-word;
  hyphens: manual;
  border-radius: 5px;
    ${
    p => p.small && `margin-left: 5px`
    };
    &:hover,
    &:focus-within {
      background-color: ${lightBgHover};
      width: ${p => p.small ? "120px" : "240px"};
      height: ${p => p.small ? "160px" : "280px"};
    }
`

const WrapperCol = styled(Col)`
    display: flex;
    justify-content: center;
    ${p => !p.small && `
        width: 100vw;
    `}
`

const TextWrapper = styled.div`
  
`

const CardText = styled.span`
  width: ${p => p.small ? "80px" : "180px"};
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  font-size: ${p => p.small ? "14px" : "24px"};
  color: white;
  text-shadow: ${blackShadow};
`

const DisplayCard = ({ object, small = false, contentToLoad, specialColSize = false }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project_id");

  const getKeyIndex = (id) => {
    const newPath = contentToLoad === "component" ?
        `/project?project_id=${projectId}&${contentToLoad}_id=${id}` :
        `/project?project_id=${id}`;
    navigate(newPath);
  };

  const renderText = (value) => (
      <TextWrapper>
        {typeof value.text === "object" ? (
            value.text.map((textValue, index) => (
                <CardText small={small} key={index}>
                  {textValue.includes("@") ? <a href={`mailto:${textValue}`}>{textValue}</a> : textValue}
                </CardText>
            ))
        ) : (
            (value.text || value.name) && <CardText small={small}>{value.name || value.text}</CardText>
        )}
      </TextWrapper>
  );
  
  return (
      <>
        {object.map((value, index) => (
            <WrapperCol
              key={index}
              style={{ marginBottom: "5px" }}
              small={small}
              
              xs={specialColSize && specialColSize.xs}
              sm={specialColSize && specialColSize.sm}
              md={specialColSize && specialColSize.md}
              lg={specialColSize && specialColSize.lg}
            >
              <Card
                  style={{
                    backgroundImage: value.image_name ? `url(${value.thumbImageUrl})` : undefined,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                  }}
                  small={small}
                  onClick={() => getKeyIndex(contentToLoad === "project" ? value.project_id : contentToLoad === "component" ? value.component_id : null)}
              >
                {value["textAbove"] && renderText(value)}
                {!value["textAbove"] && renderText(value)}
              </Card>
            </WrapperCol>
        ))}
      </>
  );
};

export default observer(DisplayCard);