import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import ModalContainer from "./ModalContainer";
import modalStore from "../../stores/ModalStore";

const ModalOverlayWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 800;
  top: 0;
  background-color: transparent;
`

const ModalOverlay = () => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(modalStore.modalVisible)
  }, [modalStore.modalVisible])

  return(
      <>
        {isOpen &&
          <ModalOverlayWrapper>
            <ModalContainer/>
          </ModalOverlayWrapper>
        }
      </>
  )
}

export default observer(ModalOverlay)