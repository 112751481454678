import './App.css';
import React from "react"
import {Routes, Route} from "react-router-dom";
import Layout from "./shared/components/Layout";
import language from "./shared/utils/language";
import PageWrapper from "./shared/components/PageWrapper";
import HomeWrapper from "./pages/home/HomeWrapper";
import Header from "./shared/components/Header";
import UserStore from "./shared/stores/UserStore";
import ProjectWrapper from "./pages/project/ProjectWrapper";
import Profile from "./pages/profile/Profile";
import {deleteExpiredProjects} from "./services/indexedDBService";
import VerifyEmailPage from "./pages/email-verification/VerifyEmail";
import UpdateEmailPage from "./pages/email-verification/UpdateEmail";
import ResetPasswordPage from "./pages/password-pages/ResetPassword";
import ChangePasswordPage from "./pages/password-pages/ChangePassword";
import ProjectAcceptInvitationPage from "./pages/project/accept/ProjectAcceptInvitationPage";
import AcceptGroupInvitePage from "./pages/group-verification/AcceptGroupInvite";
import {loadPageConfig} from "./shared/stores/ConfigStore";
import SEO from "./shared/components/Seo";


/*
GO OVER NOTES BELOW
PUSH TO GITHUB
GO OVER FLOW AND SEE THAT THINGS WORK
CONTINUE WORKING ON FIXING FEATURES, ETC
FIGURE OUT WHAT ELSE NEEDS TO BE DONE AND ADD TO NOTES/START WORKING ON
START WORKING ON LIST BELOW TO GET DONE WITH IT

NEXT

Click around and add/alter things that needs attention

Remove error if no notes

Add encryption to description

Figure out how to add planning tools and what to add. Aswell as what data is good to save to show users.
Add ability to chat
Add ability to assign users to components, etc
Add ability to link to other platforms. Aswell as reporting.

Add some more planing tools, such as time, date and that you can mark components/projects done

Add file upload (Limit based on subscription)

Figure out a way to cache components/sections in a good way

Add current subscription tier on profile page

Add pagination, or something reasonable for projects

(Maybe later. Figure out best way to do this, as can't select in modal. Or figure out way to select and then add a dropdown with all the users groups)
Add share projects to group (Figure out how to do this, if owner should get mail or what's best)
Add ability to remove group from project
Add ability to leave project as group

Add MFA to settings page

Add some script for BE to clear logs for projects and groups after a while and all other unnecessary data

Start working on landing page

Add more logic that needs to be added to finalize page:)

Figure out if possible to host own/via AWS
 */

/*Done
Add preview for image on edit
Fix can't send verification even if previous is "canceled"
Add seo.verify
Fix timer in deleted projects
Fix remove/reload expired saved things (cookies, localStorage, sessionStorage and indexedDb)
Add notes to projects and components - encrypted
 */

class App extends React.Component{
  
  render(){
    language()
    UserStore.loadUserData();
    deleteExpiredProjects();
    loadPageConfig()
    
    return (
      <Layout>
        <SEO/>
        <Header />
        <PageWrapper>
          <Routes>
            <Route exact path="/" element={<HomeWrapper />}/>
            <Route exact path="/project" element={<ProjectWrapper />}/>
            <Route exact path="/profile" element={<Profile />}/>
            <Route exact path="/verify" element={<VerifyEmailPage />}/>
            <Route exact path="/reset-password" element={<ResetPasswordPage />}/>
            <Route exact path="/verify-email-change" element={<UpdateEmailPage />}/>
            <Route exact path="/verify-password-change" element={<ChangePasswordPage />}/>
            <Route exact path="/accept-project-invitation" element={<ProjectAcceptInvitationPage />}/>
            <Route exact path="/accept-group-invitation" element={<AcceptGroupInvitePage />}/>
          </Routes >
        </PageWrapper>
      </Layout>
    );
  }
}

export default App;