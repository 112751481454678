import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import ParagraphBold from "../text/ParagraphBold";
import translation from "../../utils/translation";
import OutlinePlusIcon from "../icons/OutlinePlusIcon";
import CustomDivider from "../CustomDivider";
import {useQuery} from "../../utils/useQuery";
import ModalStore from "../../stores/ModalStore";

const SectionWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 50px;
    
`

const FileSection = observer(({canEdit}) => {
  const queryParams = useQuery();
  const componentId = queryParams.get("component_id");
  const modalTopic = componentId ? "component" : "project"
  
  const addFile = () => {
    ModalStore.addModal({modalTopic, modalName: "addFile"})
  }
  
  return (
    <SectionWrapper>
      <CustomDivider
        main={true}
        leftContent={<ParagraphBold>{translation("common.files")}</ParagraphBold>}
        rightContent={canEdit ? <ParagraphBold pointer={true} small={true} onClick={addFile}> <OutlinePlusIcon/> {translation("label.addFiles")}</ParagraphBold> : false}
      />
    </SectionWrapper>
  );
});

export default FileSection;
