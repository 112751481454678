import React from "react"
import styled from "styled-components"

const Text = styled.p`
  font-size: ${p => p.small ? 14 : 18}px;
  line-height: 1.25;
  font-weight: 700;
  overflow-wrap: break-word;
  margin: ${p => p.margin ? p.margin : 0};
  text-overflow: ellipsis;
    overflow: hidden;

    //white-space: nowrap;
  ${p => p.alignLine &&
      `
        display: flex;
        flex-direction: row;
      `
  }
  ${p => p.pointer &&
      `
        cursor: pointer;
      `
}

  ${p => p.textShadow &&
          `
        text-shadow: ${p.textShadow};
      `
  }
`

const ParagraphBold = ({ textShadow, children, margin = false, small = false, alignLine = false, pointer, onClick, style }) => {
  return(
    <Text margin={margin} small={small} alignLine={alignLine} pointer={pointer} onClick={onClick} textShadow={textShadow} style={style} >
      {children}
    </Text>
  )
}

export default ParagraphBold