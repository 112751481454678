import React, { useEffect, useRef, useState } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
  ContentState
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import styled from 'styled-components';
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaListUl,
  FaStrikethrough,
  FaCode,
  FaListOl,
  FaHeading,
  FaHighlighter,
  FaLink,
  FaUnlink
} from 'react-icons/fa';
import translation from "../utils/translation";

const TextEditorContainer = styled.div`
    margin-top: 25px;
`;

const EditorContainer = styled.div`
    border: 1px solid #ccc;
    padding: 10px;
    min-height: 150px;
    border-radius: 4px;
    background-color: white;
    cursor: text;
`;

const Toolbar = styled.div`
    margin-bottom: 10px;
    & > button {
        cursor: pointer;
        margin-right: 10px;
        border: none;
        background-color: #f3f3f3;
        border-radius: 4px;
        padding: 5px 10px;
        &:hover {
            background-color: #e2e2e2;
        }
    }
`;

const styleMap = {
  'HIGHLIGHT': {
    backgroundColor: '#fffe0d',
    padding: '2px 4px',
    color: 'black'
  },
  'MONOSPACE': {
    fontFamily: 'Monaco, Menlo, Consolas, "Courier New", monospace',
    padding: '2px 4px',
    backgroundColor: '#eee',
    borderRadius: '4px',
  },
  'CODE': {
    fontFamily: 'Monaco, Menlo, Consolas, "Courier New", monospace',
    background: '#f4f4f4',
    borderRadius: '4px',
    padding: '2px 4px'
  }
};

function findHighlight(contentBlock, callback) {
  contentBlock.findStyleRanges(
    (character) => {
      return character.hasStyle('HIGHLIGHT');
    },
    callback
  );
}

function HighlightSpan(props) {
  return <span className="highlighted-text">{props.children}</span>;
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{ color: 'blue', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

function isRawDraftContentState(raw) {
  try {
    const content = JSON.parse(raw);
    return content.hasOwnProperty('blocks') && content.hasOwnProperty('entityMap');
  } catch {
    return false;
  }
}

const decorator = new CompositeDecorator([
  {
    strategy: findHighlight,
    component: HighlightSpan,
  },
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

const TextEditor = ({ form, name, maxLength = 500, maxRows = 20, initialValue }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
  const editor = useRef(null);
  
  useEffect(() => {
    const storedRawData = initialValue ? initialValue : form.getFieldValue(name);
    if (storedRawData && isRawDraftContentState(storedRawData)) {
      const content = convertFromRaw(JSON.parse(storedRawData));
      setEditorState(EditorState.createWithContent(content, decorator));
    } else {
      const content = ContentState.createFromText(storedRawData || '');
      setEditorState(EditorState.createWithContent(content, decorator));
    }
  }, [form, name, initialValue]);
  
  useEffect(() => {
    const rawContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    if (form.getFieldValue(name) !== rawContent) {
      form.setFieldsValue({ [name]: rawContent });
    }
  }, [editorState, form, name]);
  
  const onChange = (newState) => {
    const contentState = newState.getCurrentContent();
    const plainText = contentState.getPlainText('');
    const length = plainText.length;
    const lineBreaks = (plainText.match(/\n/g) || []).length;
    
    if (length <= maxLength && lineBreaks <= maxRows) {
      setEditorState(newState);
    } else {
      const selection = newState.getSelection();
      if (editorState.getCurrentContent() !== contentState) {
        handleContentExceedingLimits(newState, contentState, selection);
      }
    }
  };
  
  function handleContentExceedingLimits(newState, contentState, selection) {
    const currentContent = editorState.getCurrentContent();
    const currentPlainText = currentContent.getPlainText('');
    const currentLength = currentPlainText.length;
    const currentLineBreaks = (currentPlainText.match(/\n/g) || []).length;
    
    if ((currentLength >= maxLength || currentLineBreaks >= maxRows) && !selection.isCollapsed()) {
      setEditorState(EditorState.forceSelection(editorState, selection));
    } else if (selection.isCollapsed()) {
      if (currentLineBreaks >= maxRows && plainTextHasIncreased(contentState, currentContent)) {
        setEditorState(EditorState.forceSelection(editorState, selection));
      } else {
        setEditorState(EditorState.forceSelection(newState, selection));
      }
    }
  }
  
  function plainTextHasIncreased(newContent, oldContent) {
    return newContent.getPlainText('').length > oldContent.getPlainText('').length;
  }
  
  const handlePastedText = (pastedText, html, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentText = currentContent.getPlainText('');
    const selection = editorState.getSelection();
    
    if (selection.isCollapsed()) {
      const currentTextLength = currentContentText.length;
      const selectedTextLength = selection.getEndOffset() - selection.getStartOffset();
      const availableSpace = maxLength - (currentTextLength - selectedTextLength);
      
      if (pastedText.length > availableSpace) {
        pastedText = pastedText.slice(0, availableSpace);
      }
    }
    
    const newContentState = Modifier.replaceText(
      currentContent,
      selection,
      pastedText,
    );
    
    setEditorState(
      EditorState.push(editorState, newContentState, 'insert-characters')
    );
    
    return 'handled';
  };
  
  const handleToggleStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };
  
  const handleToggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };
  
  const addLink = (editorState, url) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    
    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      selectionState,
      entityKey
    );
    
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithLink,
      'apply-entity'
    );
    
    return EditorState.forceSelection(newEditorState, selectionState);
  };
  
  const removeLink = (editorState) => {
    const selectionState = editorState.getSelection();
    const contentState = Modifier.applyEntity(
      editorState.getCurrentContent(),
      selectionState,
      null
    );
    return EditorState.push(editorState, contentState, 'apply-entity');
  };
  
  const handleAddLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const url = prompt(translation("editor.inputLink"));
      if (url) {
        setEditorState(addLink(editorState, url));
      }
    }
  };
  
  const handleRemoveLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState(removeLink(editorState));
    }
  };
  
  return (
    <TextEditorContainer>
      <Toolbar>
        <button onClick={() => handleToggleBlockType('header-one')} title="Header One"><FaHeading /></button>
        <button onClick={() => handleToggleBlockType('header-two')} title="Header Two"><FaHeading /></button>
        <button onClick={() => handleToggleBlockType('header-three')} title="Header Three"><FaHeading /></button>
        <button onClick={() => handleToggleStyle('BOLD')} title="Bold"><FaBold /></button>
        <button onClick={() => handleToggleStyle('ITALIC')} title="Italic"><FaItalic /></button>
        <button onClick={() => handleToggleStyle('UNDERLINE')} title="Underline"><FaUnderline /></button>
        <button onClick={() => handleToggleStyle('STRIKETHROUGH')} title="Strikethrough"><FaStrikethrough /></button>
        <button onClick={() => handleToggleStyle('CODE')} title="Code Block"><FaCode /></button>
        <button onClick={() => handleToggleStyle('HIGHLIGHT')} title="Highlight"><FaHighlighter /></button>
        <button onClick={() => handleToggleBlockType('unordered-list-item')} title="Bullet List"><FaListUl /></button>
        <button onClick={() => handleToggleBlockType('ordered-list-item')} title="Numbered List"><FaListOl /></button>
        <button onClick={handleAddLink} title="Add Link"><FaLink /></button>
        <button onClick={handleRemoveLink} title="Remove Link"><FaUnlink /></button>
      </Toolbar>
      
      <EditorContainer onClick={() => editor.current && editor.current.focus()}>
        <Editor
          customStyleMap={styleMap}
          ref={editor}
          editorState={editorState}
          onChange={onChange}
          handlePastedText={handlePastedText}
          spellCheck
        />
      </EditorContainer>
    </TextEditorContainer>
  );
};

export default TextEditor;
