import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card, Col } from 'antd';
import moment from 'moment';
import ParagraphBold from "../../../../../../shared/components/text/ParagraphBold";
import DangerButton from "../../../../../../shared/components/buttons/DangerButton";
import { FaUndo, FaSignOutAlt } from 'react-icons/fa';
import translation from "../../../../../../shared/utils/translation";
import ModalStore from "../../../../../../shared/stores/ModalStore";
import ProjectStore from "../../../../../../shared/stores/ProjectStore";

const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledCard = styled(Card)`
    margin: 10px;
    width: 80%;
    text-align: center;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-around;
`;

const TimeLeft = styled.div`
    margin-top: 10px;
    font-size: 16px;
    color: #666;
`;

const ControllerTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ControllerSpan = styled.span`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

const DeletedProjectCard = ({ object, canLeave = false, restoreOnClick, permDeleteOnClick, index }) => {
  const { deleted_at, role, thumbImageUrl, name } = object;

  
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    
    return () => clearInterval(interval);
  }, []);
  
  const calculateTimeLeft = () => {
    const duration = moment.duration(moment(deleted_at).add(24, 'hours').diff(moment()));
    if (duration.asSeconds() > 0) {
      return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
    } else {
      return 'Expired';
    }
  };
  
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
  const leaveProject = () => {
    ProjectStore.setSelectedProject(object);
    ModalStore.addModal({ modalTopic: "project", modalName: "leaveProject" });
  };
  
  return (
    <Col span={12} xs={24} md={12} key={index}>
      <CardWrapper>
        <StyledCard
          title={
            <ControllerTitleWrapper>
              <ControllerSpan>
                {(role === "owner" || role === "admin") &&
                  <FaUndo size="20" onClick={() => restoreOnClick(object)} />
                }
              </ControllerSpan>
              <ControllerSpan>
                {(canLeave && role !== "owner") &&
                  <FaSignOutAlt size="20" onClick={() => leaveProject()} />
                }
              </ControllerSpan>
            </ControllerTitleWrapper>
          }
          cover={<img alt="image" src={thumbImageUrl} style={{ width: '100%', height: 'auto', maxHeight: "250px" }} />}
        >
          <ParagraphBold>{name}</ParagraphBold>
          
          <TimeLeft>{translation("delete.timeLeft")} {timeLeft}</TimeLeft>
          <ButtonGroup>
            {(role === "admin" || role === "owner") && (
              <>
                <DangerButton onClick={() => permDeleteOnClick(object)}>{translation("common.delete")}</DangerButton>
              </>
            )}
          </ButtonGroup>
        </StyledCard>
      </CardWrapper>
    </Col>
  );
};

export default DeletedProjectCard;
