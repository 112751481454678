import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react";
import GroupStore from "../../../../../../shared/stores/GroupStore";
import {Row, Col, Typography, Tabs} from "antd";
import DisplayGroupInfo from "./DisplayGroupInfo";

const { TabPane } = Tabs;

const GroupInfoSectionWrapper = styled.div`

`

const GroupInfoSection = () => {
  const { groups } = GroupStore
  
  return (
    <GroupInfoSectionWrapper>
      {
        groups.length > 1 ?
          (
            <Tabs defaultActiveKey="1" type="card">
              {groups.map((group, index) => (
                <TabPane tab={group.group_name} key={index + 1}>
                  <DisplayGroupInfo group={group}/>
                </TabPane>
              ))}
            </Tabs>
          )
          :
          <DisplayGroupInfo group={groups[0]}/>
      }
    </GroupInfoSectionWrapper>
  );
};

export default observer(GroupInfoSection);
