import * as React from "react"
import styled from "styled-components"
import DefaultFormInput from "../../../../../../shared/components/input/DefaultFormInput";
import translation from "../../../../../../shared/utils/translation";
import CustomDivider from "../../../../../../shared/components/PageSectionDivider";
import ParagraphBold from "../../../../../../shared/components/text/ParagraphBold";
import {Form} from "antd";
import {observer} from "mobx-react";
import WhiteButton from "../../../../../../shared/components/buttons/WhiteButton";
import GroupStore from "../../../../../../shared/stores/GroupStore";

const CreateGroupSectionWrapper = styled.div`

`

const CreateGroupSection = () => {
  
  const onSubmit = (values) => {
    GroupStore.createGroup(values);
  }
  
  return (
    <CreateGroupSectionWrapper>
      <CustomDivider><ParagraphBold>{translation("profile.groups.createGroup")}</ParagraphBold></CustomDivider>
      <Form
        name="accountSettings"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <DefaultFormInput
          field={{
            name:"groupName",
            label:translation("form.label.groupName")
          }}
        />
        <DefaultFormInput
          field={{
            type: "textarea",
            maxLength: 200,
            name:"description",
            label:translation("form.label.description")
          }}
        />
        
        <WhiteButton type="primary" htmlType="submit">{translation("form.submit")}</WhiteButton>
      </Form>
    </CreateGroupSectionWrapper>
  )
}

export default observer(CreateGroupSection)
