import * as React from "react"
import { BsFillPersonFill } from "react-icons/bs";
import styled from "styled-components";


const PersonFill = styled(BsFillPersonFill)`
  font-size: 26px;
`

const FillPersonIcon = ({className, onClick}) => {
  return (
      <PersonFill onClick={onClick} className={className}/>
  )
}

export default FillPersonIcon