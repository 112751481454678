import React from 'react';
import { Breadcrumb } from "antd";
import { useNavigate } from 'react-router-dom';
import ComponentStore from "../stores/ComponentStore";
import { observer } from "mobx-react";
import LoadingSpinner from "./LoadingSpinner";
import styled from "styled-components";

const CustomBreadcrumbComponent = styled(Breadcrumb)`
    ol > li{
        cursor: pointer;
        &:hover {
            color: lightblue;
        }
    }

`;



const CustomBreadcrumb = observer(() => {
  const navigate = useNavigate();
  const { breadcrumbComponentList } = ComponentStore;
  
  const handleBreadcrumbClick = (item) => {
    navigate(item.path);
  };
  
  if (breadcrumbComponentList.length < 1) {
    return <LoadingSpinner />
  }
  
  const breadcrumbItems = breadcrumbComponentList.map((item, index) => ({
    key: index,
    title: item.title,
    onClick: () => handleBreadcrumbClick(item)
  }));
  
  
  return (
      <CustomBreadcrumbComponent items={breadcrumbItems}></CustomBreadcrumbComponent>
  );
});

export default CustomBreadcrumb;
