import * as React from "react"
import styled from "styled-components"
import CreateGroupSection from "./sections/CreateGroupSection";
import {useEffect, useState} from "react";
import GroupStore from "../../../../../shared/stores/GroupStore";
import LoadingSpinner from "../../../../../shared/components/LoadingSpinner";
import GroupInfoSection from "./sections/GroupInfoSection";
import {observer} from "mobx-react";
import CustomDivider from "../../../../../shared/components/PageSectionDivider";
import ParagraphBold from "../../../../../shared/components/text/ParagraphBold";
import translation from "../../../../../shared/utils/translation";
import OutlinePlusIcon from "../../../../../shared/components/icons/OutlinePlusIcon";
import ModalStore from "../../../../../shared/stores/ModalStore";
import {useQuery} from "../../../../../shared/utils/useQuery";
import DetailedWrapper from "./detailed/DetailedWrapper";
import {debounce} from "lodash";
import {Input} from "antd";

const GroupWrapper = styled.div`
    margin-top: 25px;
    width: 80%;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const CustomInput = styled(Input)`
  width: 200px;
    border-radius: 8px;
`

const ProfileSettingsWrapper = () => {
  const queryParams = useQuery();
  const groupId = queryParams.get("group");
  const activeTab = sessionStorage.getItem('groupActiveTab');
  
  const {isLoading, groups, currentGroup } = GroupStore
  
  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedSearch = debounce(() => {
    GroupStore.fetchGroupMembers(groupId, searchTerm)
  }, 500);
  
  useEffect(() => {
    if (searchTerm != null) {
      debouncedSearch();
    }
    
    return () => debouncedSearch.cancel();
  }, [searchTerm]);
  
  useEffect(() => {
    GroupStore.fetchUserGroups(groupId)
  }, []);
  
  const createGroupOnClick = () => {
    ModalStore.addModal({modalTopic: "profile", modalName: "createGroup"})
  }
  
  const loadGroupContent = () => {
    if(groups && groups.length > 0 && !groupId){
      return(
        <>
          <CustomDivider>
            <ParagraphBold onClick={() => createGroupOnClick()} pointer={true} small={true}>{translation("settings.group.createGroup")}
              <OutlinePlusIcon/>
            </ParagraphBold>
          </CustomDivider>
          <GroupInfoSection/>
        </>
      )
    }else if(groupId){
      return(
        <DetailedWrapper/>
      )
    }else{
      return(<CreateGroupSection/>)
    }
  }
  
  const onSearchInput = (value) => {
    setSearchTerm(value);
  };
  
  if(isLoading && !currentGroup){
    return <LoadingSpinner/>
  }
  
  return (
    <>
      <GroupWrapper>
        <TitleWrapper>
          <ParagraphBold>{(currentGroup && groupId) && currentGroup.group_name}</ParagraphBold>
          {(activeTab === "members" && groupId) &&
            <CustomInput
              placeholder={translation("input.placeholder.searchUser")}
              onChange={(e) => onSearchInput(e.target.value)}
            />}
        </TitleWrapper>
        {loadGroupContent()}
      </GroupWrapper>
    </>
  )
}

export default observer(ProfileSettingsWrapper)
