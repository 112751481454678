import * as React from "react"
import styled from "styled-components"
import CenteredBox from "../../shared/components/CenteredBox";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import ParagraphBold from "../../shared/components/text/ParagraphBold";
import {useQuery} from "../../shared/utils/useQuery";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {Form} from "antd";
import DefaultFormInput from "../../shared/components/input/DefaultFormInput";
import WhiteButton from "../../shared/components/buttons/WhiteButton";
import ProfileStore from "../../shared/stores/ProfileStore";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 48px;
`
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center !important;
`
const TitleWrapper = styled.div`
  margin: 10px 0 10px 0;
`

const UpdateEmailPage = () => {
  const queryParams = useQuery();
  const verificationToken = queryParams.get("token");
  const navigate = useNavigate();
  
  const onSubmitUpdate = (values) => {
    ProfileStore.verifyEmailUpdateForm(values, verificationToken, () => {
      setTimeout(() => {
        navigate('/');
      }, 5000);
    })
  }
  
  return (
    <Wrapper>
      <CenteredBox>
        <ColumnBox>
          <TitleWrapper>
            <TitlePrimary small={true}>{translation("verifyEmailChange.title")}</TitlePrimary>
          </TitleWrapper>
          <ParagraphBold >{translation("verifyEmailChange.paragraph")}</ParagraphBold>
          <Form
            name="updateEmail"
            onFinish={onSubmitUpdate}
            scrollToFirstError
          >
            <DefaultFormInput
              field={{
                name:"password",
                type:"password",
                label:translation("form.label.password")
              }}
            />
            <WhiteButton type="primary" htmlType="submit" >{translation("form.submit")}</WhiteButton>
          </Form>
        </ColumnBox>
      </CenteredBox>
    </Wrapper>
  )
}

export default observer(UpdateEmailPage)