import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react";
import PrimaryButton from "./buttons/PrimaryButton";
import WhiteButton from "./buttons/WhiteButton";
import { MdOutlineCookie } from "react-icons/md";
import ParagraphBold from "./text/ParagraphBold";
import ParagraphPrimary from "./text/ParagraphPrimary";
import TitlePrimary from "./text/TitlePrimary";
import {useEffect, useState} from "react";
import translation from "../utils/translation";
import {Checkbox} from "antd";
import {
  displayCookieModal,
  getCookiePreferences,
  saveCookiePreferences
} from "../stores/CookieStore";
import {sizes} from "../utils/media";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-y: auto; // Enable scrolling on the background
`;

const Modal = styled.div`
    padding: 20px; // Reduced padding for smaller screens
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 50%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    margin: 20px 0;
    text-align: center;
`;

const ModalResponsive = styled(Modal)`
    @media only screen and (max-width: ${sizes.phone}px)} {
    padding: 10px;
    width: 90%;
    max-height: 80vh;
  }
`;


const Header = styled.div`
  color: #333;
`;

const TextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CookieIcon = styled(MdOutlineCookie)`
  font-size: 100px;
`
const CookieOption = styled.div`
    text-align: left;
    margin-bottom: 20px;
    cursor: pointer;
`
const CookieOptionHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
`
const CookieOptionDescription = styled.div`
`
const CookieOptionCheckbox = styled(Checkbox)`
  margin-right: 10px;
`

const OpenCookieModalWrapper = styled.div`
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 9999;
`

const OpenCookieModalLink = styled.a`
    cursor: pointer;
    :hover{
        opacity: 0.5;
    }
`

const CookieConsent = () => {
  
  const [displayModal, setDisplayModal] = useState(false)
  const [displayOptions, setDisplayOptions] = useState(false)
  const [optionValues, setOptionValues] = useState({necessary: true, preference: true, indexedDB: true})
  
  useEffect(() => {
    if(getCookiePreferences()){
      setOptionValues(getCookiePreferences())
    }
    setDisplayModal(displayCookieModal.get())
  }, [displayCookieModal.get()])
  
  const cookieOptions = [
    {
      name: "necessary",
      title: translation("cookieConsent.necessary.title"),
      description: translation("cookieConsent.necessary.description"),
      disabled: true
    },
    {
      name: "preference",
      title: translation("cookieConsent.preference.title"),
      description: translation("cookieConsent.preference.description"),
    },
    {
      name: "indexedDB",
      title: translation("cookieConsent.indexedDB.title"),
      description: translation("cookieConsent.indexedDB.description"),
    },
  ]
  
  const renderCookieOptions = () => {
    
    return(
      <>
        {cookieOptions.map((option, index)=> {
          return(
            <CookieOption
              key={index}
              onClick={() => {
                if(option.name == "necessary") return
                setOptionValues(prev => ({
                  ...prev,
                  [option.name]: !prev[option.name]
                }))}
              }
            >
              <CookieOptionHeader>
                <CookieOptionCheckbox
                  defaultChecked={optionValues[option.name]}
                  checked={optionValues[option.name]}
                  disabled={option.disabled}
                />
                <ParagraphBold>
                  {option.title}
                </ParagraphBold>
              </CookieOptionHeader>
              <CookieOptionDescription>
                <ParagraphBold small={true}>
                  {option.description}
                </ParagraphBold>
              </CookieOptionDescription>
            </CookieOption>
          )
        })}
      </>
    )
  }
  
  const submitCookieOptions = () => {
    saveCookiePreferences(optionValues)
    setDisplayModal(false)
    setDisplayOptions(false);
  }
  
  const updateCookiesOnClick = () => {
    displayCookieModal.set(true)
  }
  
  if(!displayModal) {
    return(
      <OpenCookieModalWrapper>
        <OpenCookieModalLink onClick={() => updateCookiesOnClick()}>
          {translation("cookie.preference.update.cookies")}
        </OpenCookieModalLink>
      </OpenCookieModalWrapper>
    )
  }
  
  return (
    <Wrapper>
      <ModalResponsive>
        <Header>
          <CookieIcon/>
        </Header>
        <TextWrapper>
          <TitlePrimary small={true}>{translation("cookieConsent.title")}</TitlePrimary>
        </TextWrapper>
        <TextWrapper>
          <ParagraphPrimary small={true}>{translation("cookieConsent.description")}</ParagraphPrimary>
        </TextWrapper>
        {displayOptions && renderCookieOptions()}
        <PrimaryButton onClick={() => submitCookieOptions()}>{displayOptions ? translation("cookieConsent.accept.options") : translation("cookieConsent.accept.cookies")}</PrimaryButton>
        <WhiteButton onClick={() =>setDisplayOptions(!displayOptions)}>{translation("cookieConsent.handle.cookies")}</WhiteButton>
      </ModalResponsive>
    </Wrapper>
  );
};

export default observer(CookieConsent);