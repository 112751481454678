import styled from 'styled-components';
import { IoPersonRemoveSharp } from "react-icons/io5";
import ProjectStore from "../../../../../../shared/stores/ProjectStore";
import translation from "../../../../../../shared/utils/translation";
import {ADMINISTRATOR_ROLES} from "../../../../../../shared/utils/Constants";
import React from "react";
import { format } from "date-fns";

const UsersCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
`;

const UserInfo = styled.div`
  margin: 5px 0;
`;

const ActionWrapper = styled.div`
  display:flex;
  justify-content: space-between;
`

const InfoLabel = styled.span`
  font-weight: bold;
`;

const ActionButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const RemoveUserIcon = styled(IoPersonRemoveSharp)`
  font-size: 20px;
  cursor: pointer;
  margin-left: 25px;
`

const Timestamp = styled.div`
    font-size: 12px;
    text-align: right;
    color: #666;
`;

const ProjectInvitationsCard = ({projectInvitations, cancelInviteClick, status}) => {
  const { selectedProject } = ProjectStore
  
  return(
    <>
      {
        projectInvitations.map((invitation) => (
          <UsersCardWrapper key={invitation.invitations_id}>
            <UserInfo><InfoLabel>{translation("common.name")}:</InfoLabel> {invitation.invited_name}</UserInfo>
            <UserInfo><InfoLabel>{translation("common.email")}:</InfoLabel> {invitation.invited_email}</UserInfo>
            <UserInfo><InfoLabel>{translation("common.role")}:</InfoLabel> {invitation.role_name}</UserInfo>
            {
              status == "false" &&<UserInfo><InfoLabel>{translation("common.canceledBy")}:</InfoLabel> {invitation.canceled_by_name}</UserInfo>
              
            }
            {
              (invitation.role_name != "owner" && ADMINISTRATOR_ROLES.includes(selectedProject.role) && status != "false") &&
              <ActionWrapper><InfoLabel>{translation("common.actions")}:</InfoLabel>
                <ActionButtonContainer>
                  <RemoveUserIcon onClick={() => cancelInviteClick(invitation.invitation_id)} />
                </ActionButtonContainer>
              </ActionWrapper>
            }
            <Timestamp>{format(new Date(invitation.created_at), 'PPPpp')}</Timestamp>
          </UsersCardWrapper>
        ))
      }
    </>
  )
}

export default ProjectInvitationsCard