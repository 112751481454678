import * as React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

const StyledText = styled.div`
    padding: 10px 20px;
`;

const TextDisplay = observer(({ text }) => {
  const getHtmlContent = () => {
    try {
      const contentState = convertFromRaw(JSON.parse(text));
      
      const applyInlineStyles = (block) => {
        const text = block.getText();
        const charMetadata = block.getCharacterList();
        let styledText = '';
        let currentStyles = new Set();
        
        const openTags = (newStyles) => {
          return [...newStyles].reverse().map(style => {
            if (options.inlineStyles[style]) {
              const { element, style: cssStyles } = options.inlineStyles[style];
              const styleString = cssStyles ? ` style="${Object.entries(cssStyles).map(([key, value]) => `${key}: ${value}`).join('; ')}"` : '';
              return `<${element}${styleString}>`;
            }
            return '';
          }).join('');
        };
        
        const closeTags = (oldStyles) => {
          return [...oldStyles].map(style => {
            if (options.inlineStyles[style] && options.inlineStyles[style].element) {
              return `</${options.inlineStyles[style].element}>`;
            }
            return '';
          }).join('');
        };
        
        text.split('').forEach((char, index) => {
          const stylesAtThisChar = charMetadata.get(index).getStyle();
          const stylesToClose = [...currentStyles].filter(style => !stylesAtThisChar.has(style));
          const stylesToOpen = [...stylesAtThisChar].filter(style => !currentStyles.has(style));
          
          if (stylesToClose.length > 0) {
            styledText += closeTags(stylesToClose);
            stylesToClose.forEach(style => currentStyles.delete(style));
          }
          if (stylesToOpen.length > 0) {
            styledText += openTags(stylesToOpen);
            stylesToOpen.forEach(style => currentStyles.add(style));
          }
          
          styledText += char;
        });
        
        styledText += closeTags(currentStyles);
        
        return styledText;
      };
      
      const options = {
        blockRenderers: {
          'header-one': (block) => `<h1>${block.getText()}</h1>`,
          'header-two': (block) => `<h2>${block.getText()}</h2>`,
          'header-three': (block) => `<h3>${block.getText()}</h3>`,
          'blockquote': (block) => `<blockquote>${block.getText()}</blockquote>`,
          'code-block': (block) => `<pre><code>${block.getText()}</code></pre>`,
          'unordered-list-item': (block) => `<ul><li>${applyInlineStyles(block)}</li></ul>`,
          'ordered-list-item': (block) => `<ol><li>${applyInlineStyles(block)}</li></ol>`,
        },
        blockStyleFn: (block) => {
          if (block.getType() === 'unordered-list-item' || block.getType() === 'ordered-list-item') {
            return { element: 'li' };
          }
        },
        entityStyleFn: (entity) => {
          const entityType = entity.getType().toLowerCase();
          if (entityType === 'link') {
            const { url } = entity.getData();
            return {
              element: 'a',
              attributes: {
                href: url,
                target: '_blank',
                rel: 'noopener noreferrer'
              },
              style: {
                color: 'blue',
                textDecoration: 'underline'
              },
            };
          }
        },
        inlineStyles: {
          'BOLD': { element: 'strong' },
          'ITALIC': { element: 'em' },
          'UNDERLINE': { element: 'u' },
          'STRIKETHROUGH': { element: 's' },
          'CODE': {
            element: 'code',
            style: {
              padding: '2px 4px',
              backgroundColor: '#f4f4f4',
              borderRadius: '4px',
              fontFamily: '"Courier New", Courier, monospace'
            }
          },
          'HIGHLIGHT': {
            element: 'span',
            style: {
              backgroundColor: '#fffe0d',
              padding: '0.3em',
              color: 'black'
            }
          },
        }
      };
      
      return stateToHTML(contentState, options);
    } catch (e) {
      return '<p>Error displaying content.</p>';
    }
  };
  
  return (
    <StyledText dangerouslySetInnerHTML={{ __html: getHtmlContent() }} />
  );
});

export default TextDisplay;
