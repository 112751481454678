import * as React from "react"
import styled from "styled-components"
import {Form} from "antd";
import DefaultFormInput from "../../shared/components/input/DefaultFormInput";
import translation from "../../shared/utils/translation";
import WhiteButton from "../../shared/components/buttons/WhiteButton";
import {observer} from "mobx-react";
import CenteredBox from "../../shared/components/CenteredBox";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import ParagraphBold from "../../shared/components/text/ParagraphBold";
import {useQuery} from "../../shared/utils/useQuery";
import {useNavigate} from "react-router-dom";
import ProfileStore from "../../shared/stores/ProfileStore";


const Wrapper = styled.div`
    overflow: hidden;
    margin-top: 48px;
`
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const ChangePasswordPage = () => {
  const queryParams = useQuery();
  const resetToken = queryParams.get("token");
  const navigate = useNavigate();
  
  const onSubmitResetPassword = (values) => {
    ProfileStore.verifyUpdateUserPassword(values, resetToken, () => {
      setTimeout(() => {
        navigate('/');
      }, 5000);
    })
  }
  
  return (
    <Wrapper>
      <CenteredBox>
        <ColumnBox>
          <TitlePrimary small={true}>{translation("common.resetPassword")}</TitlePrimary>
          <ParagraphBold>{translation("passwordReset.description")}</ParagraphBold>
          <Form
            name="accountSettings"
            initialValues={{ remember: true }}
            onFinish={onSubmitResetPassword}
            scrollToFirstError
          >
            <DefaultFormInput
              field={{
                name:"password",
                type: "password",
                maxLength: 50,
                rules:[
                  { required: true, message: translation("form.field.error.message.empty") },
                  { min: 6, message: translation("form.password.notLongEnough") },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('confirm') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(translation("form.password.error.message.notMatch")));
                    },
                  }),
                ],
                label: translation("form.label.password")
              }}
            />
            <DefaultFormInput
              field={{
                name:"confirm",
                dependencies:['password'],
                maxLength: 50,
                type: "password",
                hasFeedback: true,
                rules:[
                  { required: true, message: translation("form.field.error.message.empty") },
                  { min: 6, message: translation("form.password.notLongEnough") },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(translation("form.password.error.message.notMatch")));
                    },
                  }),
                ],
                label: translation("common.password")
              }}
            
            />
            <WhiteButton type="primary" htmlType="submit" disabled={true}>{translation("button.submit ")}</WhiteButton>
          </Form>
        </ColumnBox>
      </CenteredBox>
    </Wrapper>
  )
}

export default observer(ChangePasswordPage)
