import * as React from "react"
import { AiOutlinePlusCircle } from "react-icons/ai";
import styled from "styled-components";
import {useState} from "react";


const PlusOutline = styled(AiOutlinePlusCircle)`
  cursor: pointer;
`

const OutlinePlusIcon = ({onClick = () => {}}) => {

  const IconOnClick = () => {
    onClick();
  }
  return (
    <PlusOutline onClick={IconOnClick } />
  )
}

export default OutlinePlusIcon