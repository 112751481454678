import * as React from "react"
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import CardList from "../CardList";
import ModalStore from "../../stores/ModalStore";
import {useQuery} from "../../utils/useQuery";
import styled from "styled-components";
import {Row} from "antd";
import OutlinePlusIcon from "../icons/OutlinePlusIcon";
import SectionStore from "../../stores/SectionStore";
import LoadingSpinner from "../LoadingSpinner";
import translation from "../../utils/translation";
import ParagraphBold from "../text/ParagraphBold";
import ParagraphPrimary from "../text/ParagraphPrimary";
import CustomDivider from "../CustomDivider";
import ProjectStore from "../../stores/ProjectStore";
import {ADMINISTRATOR_ROLES} from "../../utils/Constants";

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SectionRow = styled(Row)`
  margin-bottom: 25px;
`

const SectionComponentSection = observer(({canEdit}) => {
  let queryParams = useQuery();
  let projectId = queryParams.get("project_id");
  let componentId = queryParams.get("component_id");
  
  const { sectionPagination, totalCountBySection, sectionForIndex, sectionIsLoading, section } = SectionStore
  const { selectedProject } = ProjectStore
  
  useEffect(() => {
    SectionStore.loadSection(projectId, componentId);
  }, [projectId, componentId]);
  
  const addSection = async () => {
    ModalStore.addModal({ modalTopic: "section", modalName: "create" });
  };
  
  const addComponent = async (sectionId) => {
    SectionStore.selectedSectionId = sectionId;
    ModalStore.addModal({ modalTopic: "component", modalName: "create" });
  };
  
  const renderSection = () => {
    
    return (
      <>
        {section.map((value, index) => {
          const menuOnClick = (e) => {
            SectionStore.setSelectedSection(value);
            if (e.key === "edit") {
              ModalStore.addModal({ modalTopic: "section", modalName: "edit" });
            }
            if (e.key === "delete") {
              ModalStore.addModal({ modalTopic: "section", modalName: "softDelete" });
            }
          };
          
          const menuItems = [
            {
              key: "edit",
              label: translation("common.edit"),
              onClick: menuOnClick,
            },
            {
              key: "delete",
              label: translation("common.delete"),
              onClick: menuOnClick,
            },
          ];
          return (
            <SectionRow align="middle" key={index}>
              <CustomDivider menuItems={(canEdit && ADMINISTRATOR_ROLES.includes(selectedProject.role)) ? menuItems : false} leftContent={value.name} />
              <CardList
                canEdit={canEdit}
                small={true}
                object={value.components}
                actionOnClick={() => canEdit ? addComponent(value.section_id) : () => {}}
                sectionId={value.section_id.toString()}
                contentToLoad="component"
                pagination={totalCountBySection[value.section_id] > 6}
                paginationAction={sectionPagination}
                showRightPagination={sectionForIndex[value.section_id] + 6 <= totalCountBySection[value.section_id]}
                showLeftPagination={sectionForIndex[value.section_id] - 6 >= 0}
              />
            </SectionRow>
          );
        })}
      </>
    );
  };
  
  if (sectionIsLoading) {
    return <LoadingSpinner/>
  }
  
  return (
    <SectionWrapper>
      <Row align="middle">
        <CustomDivider main={true} rightContent={canEdit ? <ParagraphBold pointer={true} small={true} onClick={addSection}> <OutlinePlusIcon/> {translation("label.addSection")}</ParagraphBold> : false} leftContent={<ParagraphBold>{translation("common.section")}</ParagraphBold>} />
      </Row>
      <Row align="middle">
        {
          !canEdit && section.length == 0 ?
            <ParagraphPrimary>{translation("paragraph.noSections")}</ParagraphPrimary>
            :
            null
        }
      </Row>
      {renderSection()}
    </SectionWrapper>
  );
});

export default SectionComponentSection;