import {Divider} from "antd";
import styled from "styled-components";

const StyledDivider = styled(Divider)`
    display: flex;
    align-items: center;
  ::after, ::before{
      border-top: 2px solid black !important;
  }
`

const CustomDivider = ({children, orientation = "left"}) => {
  return(
    <StyledDivider orientation={orientation} >
      {children}
    </StyledDivider>
  )
}

export default CustomDivider