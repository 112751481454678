import React, {useEffect} from 'react';
import GroupStore from "../../../../../../shared/stores/GroupStore";
import {observer} from "mobx-react";
import {useQuery} from "../../../../../../shared/utils/useQuery";

import modalStore from "../../../../../../shared/stores/ModalStore";
import useWindowDimensions from "../../../../../../shared/utils/windowSize";
import {sizes} from "../../../../../../shared/utils/media";
import MemberTable from "../components/GroupMemberTable";
import MemberCard from "../components/GroupMemberCard";

const GroupMembers = () => {
  
  const queryParams = useQuery();
  const groupId = queryParams.get("group");
  
  const { width } = useWindowDimensions()
  
  const { groupMembers} = GroupStore
  
  useEffect(() => {
    GroupStore.fetchGroupMembers(groupId)
  }, [groupId]);
  
  const editMemberOnClick = (userId) => {
    GroupStore.setSelectedUser(userId)
    modalStore.addModal({modalTopic: "group", modalName: "editMemberRole"})
  }
  
  const removeMemberOnClick = (userId) => {
    GroupStore.setSelectedUser(userId)
    modalStore.addModal({modalTopic: "group", modalName: "removeMember"})
  }
  
  return (
    <>
      {
        width < sizes.tablet ?
            <MemberCard members={groupMembers} removeMemberOnClick={removeMemberOnClick} editMemberOnClick={editMemberOnClick}/>
          :
            <MemberTable members={groupMembers} removeMemberOnClick={removeMemberOnClick} editMemberOnClick={editMemberOnClick}/>
      }
    </>
  )
}

export default observer(GroupMembers);