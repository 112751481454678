import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import ProjectStore from "../../../../../../../shared/stores/ProjectStore";
import LoadingSpinner from "../../../../../../../shared/components/LoadingSpinner";
import {Row} from "antd";
import DeletedProjectCard from "../DeletedCard";
import SectionStore from "../../../../../../../shared/stores/SectionStore";
import ModalStore from "../../../../../../../shared/stores/ModalStore";


const DeletedWrapperComponent = styled.div`
    margin-top: 25px;
    width: 100%;
`;
const DeletedSectionTab = () => {
  
  const {isFetchingDeleted, deletedSections} = SectionStore
  
  useEffect(() => {
    SectionStore.getDeleted();
  }, []);
  
  if(isFetchingDeleted && !deletedSections && deletedSections == undefined){
    return <LoadingSpinner/>
  }
  
  const restoreSection = (section) => {
    SectionStore.setSelectedSection(section)
    ModalStore.addModal({ modalTopic: "section", modalName: "restoreSection" });
  }
  
  const permDeleteSection = (section) => {
    SectionStore.setSelectedSection(section)
    ModalStore.addModal({ modalTopic: "section", modalName: "permDeleteSection" });
  }
  
  return (
    <DeletedWrapperComponent>
      <Row>
        {(deletedSections && deletedSections.length > 0) &&
          deletedSections.map((section, index) =>{
            return (
              <DeletedProjectCard
                key={index}
                index={index}
                object={section}
                restoreOnClick={restoreSection}
                permDeleteOnClick={permDeleteSection}
              />
            )
          })
        }
      </Row>
    </DeletedWrapperComponent>
  )
}

export default observer(DeletedSectionTab)
