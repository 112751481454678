import styled from "styled-components";
import {useEffect} from "react";

const DropdownMenu = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease;
    z-index: 9999;
`;

const MenuItem = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  };
`;

const Dropdown = ({ isOpen, setIsOpen, options }) => {
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.getElementById('dropdown').contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    if (setIsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  
  return (
    <DropdownMenu isOpen={isOpen} id="dropdown">
      {options.map((option) => {
        return(
          <>
            {
              option.display && <MenuItem key={option.key} onClick={option.onClick}>{option.text}</MenuItem>
            }
          </>
        )
      })}
    </DropdownMenu>
  );
};

export default Dropdown