import * as React from "react"
import styled from "styled-components"
import DefaultFormInput from "../../../../../../shared/components/input/DefaultFormInput";
import translation from "../../../../../../shared/utils/translation";
import CustomDivider from "../../../../../../shared/components/PageSectionDivider";
import ParagraphBold from "../../../../../../shared/components/text/ParagraphBold";
import {Form} from "antd";
import {validateMessages} from "../../../../../../shared/utils/validateMessage";
import ProfileStore from "../../../../../../shared/stores/ProfileStore";
import UserStore from "../../../../../../shared/stores/UserStore";
import {observer} from "mobx-react";
import LoadingSpinner from "../../../../../../shared/components/LoadingSpinner";
import WhiteButton from "../../../../../../shared/components/buttons/WhiteButton";

const AccountSettingsSectionWrapper = styled.div`

`

const AccountSettingsSection = () => {
  
  const { userData, isFetchingUserData } = ProfileStore
  
  const onSubmit = (values) => {
    ProfileStore.updateUserDetails(values)
  }
  
  if(!userData.user_id || isFetchingUserData){
    return <LoadingSpinner/>
  }
  
  return (
    <AccountSettingsSectionWrapper>
      <CustomDivider><ParagraphBold>{translation("profile.settings.accountSettings")}</ParagraphBold></CustomDivider>
      <Form
        name="accountSettings"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <DefaultFormInput
          field={{
            maxLength: 50,
            name:"name",
            initialValue: () => userData.name,
            label:translation("form.label.name")
          }}
        />
        <DefaultFormInput
          field={{
            maxLength: 30,
            name:"username",
            initialValue: () => userData.username,
            label:translation("form.label.username")
          }}
        />
        <DefaultFormInput
          field={{
            maxLength: 75,
            initialValue: () => userData.email,
            name: "email",
            label: translation("form.label.email"),
            rules: [
              {
                type: 'email',
                message: validateMessages.email.invalid,
              },
              () => ({
                async validator(_, value) {
                  if(value){
                    if(value === userData.email){
                      return Promise.resolve();
                    }
                    if(value.length > 0 && value.includes("@")){
                      if (await UserStore.verifyEmailNotExist(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(validateMessages.unavailable.email));
                    }
                  }
                },
              })
            ]
          }}
        />
        
        <WhiteButton type="primary" htmlType="submit">{translation("form.submit")}</WhiteButton>
      </Form>
    </AccountSettingsSectionWrapper>
  )
}

export default observer(AccountSettingsSection)
