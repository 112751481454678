import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import OutlineCircleCloseIcon from "../icons/OutlineCircleCloseIcon";
import ReturnIcon from "../icons/ReturnIcon";
import TitleSecondary from "../text/TitleSecondary";
import modalStore from "../../stores/ModalStore";
import CloseButton from "../buttons/CloseButton";
import ReturnButton from "../buttons/ReturnButton";

const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: ${p => p.hasReturnOrTitleProp ? "space-between" : "end"};
  
`

const IconWrapper = styled.div`
  
`

/*
  Add send modalObject as prop instead of get modalStore
  Check currentStep is updated
 */

const ModalHeader = ({title, modalObject}) => {

  const {
    modal,
    currentStep,

  } = modalObject

  const {
    name= "",
    action= "",
    messages= {},
    type= "",
    fields = [],
    buttonText= {},
    multiStep= false,
    steps = [],
    text = ""
  } = modal.length > 0 ? modal[modal.length - 1] : modal

  const [displayReturnButton, setDisplayReturnButton] = useState(false)

  useEffect(() => {
    setDisplayReturnButton(modalStore.modal.length > 1 || currentStep > 0)
  }, [modal.length, currentStep])

  const hasReturnOrTitleProp = displayReturnButton != false || title != undefined;

  const close = () => {
    modalStore.hideModal()
  }

  const returnButton = () => {
    modalStore.returnModal();
  }

  return(
      <ModalHeaderWrapper hasReturnOrTitleProp={hasReturnOrTitleProp}>
        {displayReturnButton ?
            <ReturnButton onClick={() => returnButton()}/>
            :
            null
        }
        {title ?
            <TitleSecondary>{title}</TitleSecondary>
            :
            null
        }
        <CloseButton onClick={() => close()}/>
      </ModalHeaderWrapper>
  )
}


/*
    <Modal style={{textAlign: "center"}} footer={null} visible={ModalStore.modalVisible} onCancel={() => ModalStore.displayModal({})} destroyOnClose={true}>
      {children}
    </Modal>
 */
export default observer(ModalHeader)