import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import ProjectStore from "../../../../../../../shared/stores/ProjectStore";
import LoadingSpinner from "../../../../../../../shared/components/LoadingSpinner";
import {Row} from "antd";
import DeletedProjectCard from "../DeletedCard";
import ModalStore from "../../../../../../../shared/stores/ModalStore";
import ComponentStore from "../../../../../../../shared/stores/ComponentStore";


const DeletedWrapperComponent = styled.div`
    margin-top: 25px;
    width: 100%;
`;
const DeletedComponentTab = () => {
  
  const {isLoadingDeleted, deletedComponents} = ComponentStore
  
  useEffect(() => {
    ComponentStore.getDeleted();
  }, []);
  
  if(isLoadingDeleted && !deletedComponents && deletedComponents == undefined){
    return <LoadingSpinner/>
  }
  
  const restoreComponent = (project) => {
    ComponentStore.setSelectedComponent(project)
    ModalStore.addModal({ modalTopic: "component", modalName: "restoreComponent" });
  }
  
  const permDeleteComponent = (project) => {
    ComponentStore.setSelectedComponent(project)
    ModalStore.addModal({ modalTopic: "component", modalName: "permDeleteComponent" });
  }
  
  return (
    <DeletedWrapperComponent>
      <Row>
        {(deletedComponents && deletedComponents.length > 0) &&
          deletedComponents.map((component, index) =>{
            return (
              <DeletedProjectCard
                key={index}
                index={index}
                restoreOnClick={restoreComponent}
                permDeleteOnClick={permDeleteComponent}
                canLeave={true}
                object={component}
              />
            )
          })
        }
      </Row>
    </DeletedWrapperComponent>
  )
}

export default observer(DeletedComponentTab)
