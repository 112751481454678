import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import ActionCard from "../../shared/components/cards/ActionCard";
import {Row, Col} from "antd";
import DisplayCard from "../../shared/components/cards/DisplayCards";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: ${p => p.small ? "10px" : "48px"};
`
const CardWrapper = styled.div`
    display: flex;
    justify-self: center;
`

const PaginationController = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`
const RightArrowIcon = styled(AiFillCaretRight)`
  font-size: 26px;
`
const LeftArrowIcon = styled(AiFillCaretLeft)`
  font-size: 26px;
`
const CardList = ({ actionOnClick, object, canEdit = false, small = false, pagination, specialColSize, showActionCard = true, contentToLoad, paginationAction, sectionId, showRightPagination, showLeftPagination }) => {
  const renderPaginationController = () => (
      <PaginationController>
        {showRightPagination && <span aria-label="Next Page" onClick={() => paginationAction("right", sectionId)}><RightArrowIcon/></span>}
        {showLeftPagination && <span aria-label="Previous Page" onClick={() => paginationAction("left", sectionId)}><LeftArrowIcon/></span>}
      </PaginationController>
  );

  return (
      <Wrapper small={small.toString()}>
        <CardWrapper>
          <Row justify="space-evenly">
            {(canEdit && showActionCard) && <Col><ActionCard onClick={() => actionOnClick(sectionId)} sectionId={sectionId} small={small}/></Col>}
            {object && <DisplayCard specialColSize={specialColSize} object={object} small={small} contentToLoad={contentToLoad}/>}
            {pagination && renderPaginationController()}
          </Row>
        </CardWrapper>
      </Wrapper>
  );
};

CardList.propTypes = {
  actionOnClick: PropTypes.func.isRequired,
  object: PropTypes.array, // add more specific shape if possible
  small: PropTypes.bool,
  pagination: PropTypes.bool,
  contentToLoad: PropTypes.string,
  paginationAction: PropTypes.func.isRequired,
  sectionId: PropTypes.string,
};

export default observer(CardList);