import React from "react"
import styled from "styled-components"

const Text = styled.p`
  font-size: ${p => p.small ? "14px" : "16px"};
  line-height: 1.75;
  font-weight: 500;
  overflow-wrap: break-word;
  margin: ${p => p.margin ? p.margin : 0};
    ${p => p.styling};
`

const ParagraphPrimary = ({children, margin, small = false, styling }) => {
  return(
    <Text styling={styling} margin={margin} small={small}>
      {children}
    </Text>
  )
}

export default ParagraphPrimary