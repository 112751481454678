import React, {useEffect, useLayoutEffect, useRef, useState} from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {menuIsOpen, setMenuIsOpen} from "../../../stores/ConfigStore";
import ModalStore from "../../../stores/ModalStore";
import CloseButton from "../../buttons/CloseButton";
import FocusTrap from "focus-trap-react";
import MenuContent from "./RenderMenuContent";
import MenuHeader from "./MenuHeader";
import {menuBackgroundColor} from "../../../utils/vars";
import userStore from "../../../stores/UserStore";
import {sizes} from "../../../utils/media";

const MenuContainer = styled.div`
  position: fixed;
  right: 0;
  width: ${p => p.isOpen ? 360 : 0}px;
  height: 100%;
  z-index: 9999;
  background-color: ${menuBackgroundColor};
  display: flex;
  flex-direction: column;
  transition: width 0.35s ease-in-out;
  @media only screen and (max-width:${sizes.tablet}px){
    width: ${p => p.isOpen ? 100 : 0}%;
  }
`

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menu = useRef(null)

  const {isLoggedIn} = userStore

  useLayoutEffect(() => {
    function handleEvent(event) {
      if(event.x > 0 || event.key == "Escape"){
        if(menu.current && !menu.current.contains(event.target)){
          setMenuIsOpen(false)
        }
      }
    }

    document.addEventListener('mousedown', handleEvent);
    document.addEventListener('keydown', handleEvent);

    return () => {
      document.removeEventListener('mousedown', handleEvent);
      document.removeEventListener('keydown', handleEvent);
    };
  }, []);

  useEffect(() => {
    setIsOpen(menuIsOpen.get())
  }, [menuIsOpen.get()])

  const openModal = ({modalTopic, modalName}) => {
    ModalStore.addModal({modalTopic: modalTopic, modalName:modalName})
    closeMenu();
  }

  const closeMenu = () => {
    setMenuIsOpen(false)
    setIsOpen(false)
  }

  return (
    <>
      <FocusTrap active={isOpen}>
        <MenuContainer isOpen={isOpen} ref={menu}>
          <MenuHeader isOpen={isOpen}>
            <CloseButton onClick={() => closeMenu()} position={"menu"}/>
          </MenuHeader>
          <MenuContent isOpen={isOpen} isLoggedIn={isLoggedIn} closeMenu={closeMenu} openModal={openModal}/>
        </MenuContainer>
      </FocusTrap>
    </>
  );
}

export default observer(Menu);
