import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useQuery} from "../../../../../../shared/utils/useQuery";
import ParagraphBold from "../../../../../../shared/components/text/ParagraphBold";
import translation from "../../../../../../shared/utils/translation";

const Wrapper = styled.div`
    margin: 25px 0;
    display: flex;
    justify-content: space-around;
`

const OptionWrapper = styled.div`
    cursor: pointer;
    width: 150px;
    text-align: center;
`

const UnderLine = styled.div`
    border: 2px solid ${p => p.isActive ? "lightblue" : "black"};
    border-radius: 8px;
    ${OptionWrapper}:hover & {
        border: 2px solid lightblue;
    }
`

const GroupTabsSection = () => {
  const queryParams = useQuery();
  const groupId = queryParams.get("group");
  const activeTab = sessionStorage.getItem('groupActiveTab');
  const [currentActiveTab, setCurrentProjects] = useState(activeTab == "detailed" ? null : activeTab)
  const navigate = useNavigate();
  
  const onTabClick = (groupTypeToFetch) => {
    sessionStorage.setItem('groupActiveTab', groupTypeToFetch);
    if(groupTypeToFetch == "detailed"){
      setCurrentProjects(null)
      navigate(`/profile?content=groups&group=${groupId}`)
      return
    }
    setCurrentProjects(groupTypeToFetch)
    navigate(`/profile?content=groups&group=${groupId}&activeTab=${groupTypeToFetch}`)
  }
  
  return (
    <Wrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("detailed")}>{translation("profile.group.tab.detailed")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == null}/>
      </OptionWrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("members")}>{translation("profile.group.tab.members")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == "members"}/>
      </OptionWrapper>
      <OptionWrapper>
        <ParagraphBold onClick={() => onTabClick("history")}>{translation("profile.group.tab.history")}</ParagraphBold>
        <UnderLine isActive={currentActiveTab == "history"}/>
      </OptionWrapper>
    </Wrapper>
  );
};

export default observer(GroupTabsSection);