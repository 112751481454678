import React, {useEffect} from "react";
import ProjectStore from "../../../../shared/stores/ProjectStore";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import {observer} from "mobx-react";
import modalStore from "../../../../shared/stores/ModalStore";
import useWindowDimensions from "../../../../shared/utils/windowSize";
import ProjectUsersTable from "./components/users-list/ProjectUsersTable";
import ProjectUsersCard from "./components/users-list/ProjectUsersCard";
import {sizes} from "../../../../shared/utils/media";


const ProjectUsersContent = ({projectId}) => {
  let {isFetchingUsers, projectUsers} = ProjectStore;
  const { width } = useWindowDimensions()
  
  useEffect(() => {
    getUsers()
  }, []);
  
  const getUsers = async() => {
    await ProjectStore.getProjectUsers(projectId)
  }
  
  const removeUserOnClick = (userId) => {
    ProjectStore.setSelectedUser(userId);
    modalStore.addModal({modalTopic: "project", modalName: "removeUser"})
  }
  
  const editUserOnClick = (userId) => {
    ProjectStore.setSelectedUser(userId);
    modalStore.addModal({modalTopic: "project", modalName: "editUserRole"})
  }
  
  if(isFetchingUsers || !projectUsers || projectUsers === undefined){
    return <LoadingSpinner/>
  }
  
  return (
    <>
      {
        width > sizes.tablet ?
          <ProjectUsersTable projectUsers={projectUsers} editUserOnClick={editUserOnClick} removeUserOnClick={removeUserOnClick}/>
          :
          <ProjectUsersCard projectUsers={projectUsers} editUserOnClick={editUserOnClick} removeUserOnClick={removeUserOnClick}/>
      }
    </>
  );
}

export default observer(ProjectUsersContent)