import {makeAutoObservable} from "mobx";
import ApiGateway from "../api/ApiGateway";
import SectionStore from "./SectionStore";
import ProjectStore from "./ProjectStore";
import UserStore from "./UserStore";
import ModalStore from "./ModalStore";
import Notifications from "../utils/Notifications";

class ComponentStore {

  fromIndex = {}
  totalComponents = {}
  components = {}
  isLoadingComponents = {}
  selectedComponent = {}
  currentNote = ""
  
  currentComponent = []
  componentList = []
  
  isLoading = false
  isLoadingDeleted = false;
  deletedComponents = []
  
  breadcrumbComponentList = []
  isLoadingComponent = false;
  
  constructor() {
    makeAutoObservable(this)
  }
  
  async loadSectionComponents(sectionId, fromIndex){
    if(this.isLoading) return
    this.isLoading = true
    let body = Array.isArray(sectionId.sectionIds) ? sectionId.sectionIds.join(',') : sectionId;
    const result = await ApiGateway.component.loadSectionComponents(body, fromIndex);
    this.isLoading = false
    return result;
  }
  
  async addComponent(data){
    if(this.isLoading) return
    this.isLoading = true;
    ModalStore.setModalLoading(true)
    
    const formData = new FormData();
    formData.append('projectId', ProjectStore.selectedProject.project_id);
    formData.append('parentComponentId', this.currentComponent.component_id || null);
    formData.append('sectionId', SectionStore.selectedSectionId);
    formData.append('name', data.values.name)
    formData.append('description', data.values.description || "")
    
    if(data.values.image) {
      formData.append('image', data.values.image[0].originFileObj);
    }
    
    let result = await ApiGateway.component.addComponent(formData);
    if(result.success){
      const newObj = {
        description: data.values.description,
        component_id: result.insertId,
        image_name: result.image_name,
        name: data.values.name,
        parent_component_id: data.values.parent_component_id,
        project_id: data.values.project_id,
        section_id: data.values.section_id,
        user_id: UserStore.user.userId,
        thumbImageUrl: data.values.image ? result.thumbImageUrl : "",
        fullImageUrl: data.values.image ? result.fullImageUrl : "",
      }
      
      let foundSection = SectionStore.section.find(section => section.section_id === SectionStore.selectedSectionId);
      
      if (foundSection) {
        if (!foundSection.components) {
          foundSection.components = [];
        }
        if(foundSection.components.length < 6){
          foundSection.components.push(newObj);
        }
      } else {
        console.log("Section not found");
      }
    }
    
    ModalStore.setModalLoading(false)
    this.isLoading = false;
    return result;
  }
  
  async getComponent(id) {
    if(this.isLoadingComponent) return
    this.isLoadingComponent = true;
    this.breadcrumbComponentList = []
    try {
      let result = await ApiGateway.component.getComponent(id);
      this.currentComponent = result.objects[0];
      
      this.updateBreadcrumbList(result.breadcrumbList)
      
    } catch (error) {
      console.error("Error fetching component hierarchy:", error);
    } finally {
      this.isLoadingComponent = false;
    }
  }
  
  updateBreadcrumbList(breadcrumbList) {
    if(!this.breadcrumbComponentList[0]){
      this.breadcrumbComponentList[0] = {
        title: ProjectStore.selectedProject.name,
        path: `/project?project_id=${ProjectStore.selectedProject.project_id}`,
        id: ProjectStore.selectedProject.project_id,
        type: "project"
      }
    }
    const preservedList = [this.breadcrumbComponentList[0]];

    breadcrumbList.forEach(item => {
      const newItem = {
        title: item.name,
        path: `/project?project_id=${item.project_id}&component_id=${item.id}`,
        id: item.id,
        type: "component",
      };
      
      const itemExists = this.breadcrumbComponentList.some(breadcrumb => breadcrumb.id === newItem.id);
      if (!itemExists) {
        preservedList.push(newItem);
      }
    });
    
    this.breadcrumbComponentList.replace(preservedList);
  }
  
  async editComponent(data) {
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    let changes = 0;
    
    const formData = new FormData();
    
    if(data.name !== this.currentComponent.name){
      formData.append('name', data.name)
      changes++;
    }
    if(data.description !== this.currentComponent.description){
      formData.append('description', data.description)
      changes++
    }
    
    if(data.image && data.image.length > 0) {
      formData.append('image', data.image[0].originFileObj);
      changes++
    }else if(data.image && data.image.length == 0){
      formData.append('image', 'REMOVE_IMAGE');
      changes++
    }
    
    if(changes < 1){
      ModalStore.setModalLoading(false)
      this.isLoading = false
      Notifications("warning", `notification.warning.NO_UPDATES`)
      return {success: false}
    }
    
    let result = await ApiGateway.component.editComponent(formData, this.currentComponent.component_id);
    
    let updatedComponent = {
      component_id: this.currentComponent.component_id,
      name: data.name ? data.name : this.currentComponent.name,
      image_name: data.image ? result.image_name : this.currentComponent.image_name,
      description: data.description || this.currentComponent.description || "",
      thumbImageUrl: data.image ? result.thumbImageUrl : this.currentComponent.thumbImageUrl ? this.currentComponent.thumbImageUrl : "",
      fullImageUrl: data.image ? result.fullImageUrl : this.currentComponent.fullImageUrl ? this.currentComponent.fullImageUrl : "",
      parent_component_id: this.currentComponent.parent_component_id,
      component_deleted: this.currentComponent.component_deleted,
      project_deleted: this.currentComponent.project_deleted
    }
    
    
    this.currentComponent = updatedComponent
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }

  async addImage(data){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    const formData = new FormData();
    formData.append('image', data.values.image[0].originFileObj)
    
    let result = await ApiGateway.component.addComponentImage(formData, this.currentComponent.component_id);
    
    let updatedComponent = {
      component_id: this.currentComponent.component_id,
      name: this.currentComponent.name,
      image_name: result.newImageName,
      thumbImageUrl: result.thumbImageUrl,
      fullImageUrl: result.fullImageUrl,
      description: this.currentComponent.description,
      parent_component_id: this.currentComponent.parent_component_id,
      component_deleted: this.currentComponent.component_deleted,
      project_deleted: this.currentComponent.project_deleted
    }
    
    this.currentComponent = updatedComponent;
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async returnComponent(){
    let returnId = this.currentComponent.parent_component_id
    this.currentComponent = []
    return returnId;
  }
  
  setSelectedComponent(component){
    this.selectedComponent = component
  }
  
  async softDeleteComponent(){
    if(this.isLoading) return
    this.isLoading = true;
    ModalStore.setModalLoading(true)
    const result = await ApiGateway.component.deleteComponent(this.currentComponent.component_id);
    
    if(result.success){
      this.currentComponent.component_deleted = true;
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false;
    return result
  }
  
  async getDeleted(){
    if(this.isLoadingDeleted) return
    this.isLoadingDeleted = true;
    const result = await ApiGateway.component.getDeleted();
    
    this.deletedComponents = result.result;
    
    this.isLoadingDeleted = false;
    return result
  }

  async restoreComponent(){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    let result = await ApiGateway.component.restoreComponent(this.selectedComponent.component_id);
    
    if(result.success){
      this.deletedComponents = this.deletedComponents.filter(component => component.component_id !== this.selectedComponent.component_id);
      this.selectedComponent = null;
    }
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async permDeleteComponent(){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    let result = await ApiGateway.component.permDeleteComponent(this.selectedComponent.component_id);
    
    if(result.success){
      this.deletedComponents = this.deletedComponents.filter(component => component.component_id !== this.selectedComponent.component_id);
      this.selectedComponent = null;
    }
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async addNote({values}){
    if(this.isAddingNote) return;
    this.isAddingNote = true;
    ModalStore.setModalLoading(true)
    
    const result = await ApiGateway.component.addNote(this.currentComponent.component_id, values);
    
    ModalStore.setModalLoading(false)
    this.isAddingNote = false;
    return result;
  }
  
  async editNote({values}){
    if(values.note === this.currentNote){
      console.log('No changes detected, no request sent.');
      return
    }
    if(this.isAddingNote) return;
    this.isAddingNote = true;
    ModalStore.setModalLoading(true)
    
    const result = await ApiGateway.component.editNote(this.currentComponent.component_id, values);
    
    if(result.success){
      this.currentNote = values.note
    }
    
    ModalStore.setModalLoading(false)
    this.isAddingNote = false;
    return result;
  }
  
  async getNote(componentId){
    if(this.isFetching) return;
    this.isFetching = true;
    ModalStore.setModalLoading(true)
    
    const result = await ApiGateway.component.getNote(componentId);
    this.currentNote = result.note || "";
    
    ModalStore.setModalLoading(false)
    this.isFetching = false;
    return result;
  }
  
  async reset(){
    this.fromIndex = {}
    this.totalComponents = {}
    this.components = {}
    this.isLoadingComponents = {}
    this.currentComponent = []
    this.componentList = []
    this.breadcrumbComponentList = [];
  }

}

export default new ComponentStore()