import ProfileMenuContent from "./ProfileMenuContent";
import * as React from "react";
import styled from "styled-components";
import {sizes} from "../../../../shared/utils/media";

const LeftMenu = styled.div`
    min-width: 17%;
    z-index: 2;
    min-height: calc(100vh - 148px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    margin-top: 60px;
    @media only screen and (max-width:${sizes.lDesktop}px){
        width: 220px;
    }
    @media only screen and (max-width:${sizes.tablet}px){
        box-shadow: none;
    }
    @media only screen and (max-width:${sizes.tablet}px){
        min-height: 50px;
    }
`;

const MenuDivider = styled.div`
    width: 100%;
    height: 2px;
    margin-top: 30px;
`

const ProfileMenu = () => {
  return(
    <LeftMenu>
      <MenuDivider/>
      <ProfileMenuContent/>
    </LeftMenu>
  )
}
export default ProfileMenu