import * as React from "react";
import styled from "styled-components";
import {ErrorColor} from "../utils/vars";

const RequiredComponent = styled.span`
  color: ${ErrorColor};
  font-size: 25px;
  margin-left: 15px;
`

const Required = ({}) => {

  return (
      <RequiredComponent>*</RequiredComponent>
  )
}

export default Required