import * as React from "react"
import { IoIosClose } from "react-icons/io";
import styled from "styled-components";

const CloseIcon = styled(IoIosClose)`
  font-size: 28px;
`

const OutlineCloseIcon = ({ onClick, className}) => {
  return (
      <CloseIcon onClick={onClick} className={className}/>
  )
}

export default OutlineCloseIcon