import * as React from "react"
import { AiOutlineCloseCircle } from "react-icons/ai";
import styled from "styled-components";

const CloseIcon = styled(AiOutlineCloseCircle)`
  font-size: ${p => p.small ? 18 : 26}px;
`

const OutlineCircleCloseIcon = ({small = false, onClick}) => {
  return (
      <CloseIcon small={small.toString()} onClick={onClick}/>
  )
}

export default OutlineCircleCloseIcon