import React from "react"
import styled from "styled-components"
import {linkFocusColor, textHoverColor} from "../../utils/vars";

const LinkTextComponent = styled.button`
  background-color: inherit;
  font-size: 14px;
  border: none;
  cursor: pointer;
  color: black;
  &:hover{
    color: ${textHoverColor}
  }
  &:focus{
    color: ${linkFocusColor};
    text-decoration: underline;
  }
`

const LinkText = ({ children, icon = false, onClick}) => {
  return(
      <LinkTextComponent icon={icon} onClick={onClick}>
        {children}
      </LinkTextComponent>
  )
}

export default LinkText