import * as React from "react"
import { AiOutlineMenu } from "react-icons/ai";
import styled from "styled-components";

const MenuIcon = styled(AiOutlineMenu)`
  font-size: 26px;
    filter: drop-shadow(1px 0 0 ${p => p.theme.primaryColor}) drop-shadow(-1px 0 0 ${p => p.theme.primaryColor}) drop-shadow(0 1px 0 ${p => p.theme.primaryColor}) drop-shadow(0 -1px 0 ${p => p.theme.primaryColor});

`

const OutlineMenuIcon = ({className, onClick}) => {
  return (
      <MenuIcon onClick={onClick} className={className}/>
  )
}

export default OutlineMenuIcon