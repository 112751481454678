import React, {useEffect} from "react";
import ProjectStore from "../../../../shared/stores/ProjectStore";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import {observer} from "mobx-react";
import modalStore from "../../../../shared/stores/ModalStore";
import CustomTab from "../../../../shared/components/CustomTab";
import {useNavigate} from "react-router-dom";
import {useQuery} from "../../../../shared/utils/useQuery";

import ProjectInvitationsTable from "./components/invitations-list/ProjectInvitationsTable";
import useWindowDimensions from "../../../../shared/utils/windowSize";
import {sizes} from "../../../../shared/utils/media";
import ProjectInvitationsCard from "./components/invitations-list/ProjectInvitationsCard";

const ProjectInvitationsContent = ({}) => {
  let {isFetchingInvitations, selectedProject, projectInvitations} = ProjectStore;
  const queryParams = useQuery();
  let status = queryParams.get("active");
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  
  useEffect(() => {
    getInvitations()
  }, [status]);
  
  const getInvitations = async() => {
    let result = await ProjectStore.getInvitedUsers(status)
    if(result){
      if(!result.success && result.message == "MISSING_PERMISSION"){
        navigate("/")
      }
    }
    
  }
  
  const cancelInviteClick = (invitationId) => {
    ProjectStore.setSelectedInvitation(invitationId)
    modalStore.addModal({modalTopic: "project", modalName: "cancelInvite"})
  }
  
  const tabs = [
    {
      key: 0,
      text: "Active",
      isActiveQuery: "active",
      resultToBeActive: "true",
      onClick: () => {
        navigate(`/project?project_id=${selectedProject.project_id}&content=invitations&active=true`)
      }
    },
    {
      key: 1,
      text: "Inactive",
      isActiveQuery: "active",
      resultToBeActive: "false",
      onClick: () => {
        navigate(`/project?project_id=${selectedProject.project_id}&content=invitations&active=false`)
      }
    }
  ]
  
  if(isFetchingInvitations || !projectInvitations){
    return <LoadingSpinner/>
  }
  
  return (
    <>
      <CustomTab
        tabs={tabs}
      />
      {
        width > sizes.tablet ?
          <ProjectInvitationsTable projectInvitations={projectInvitations} status={status} cancelInviteClick={cancelInviteClick}/>
          :
          <ProjectInvitationsCard projectInvitations={projectInvitations} status={status} cancelInviteClick={cancelInviteClick}/>
      }
    </>
    
  );
}

export default observer(ProjectInvitationsContent)