import React from "react"
import styled from "styled-components"
import TitlePrimary from "../text/TitlePrimary";
import TitleSecondary from "../text/TitleSecondary";
import BaseButton from "./BaseButton";
import {baseButtonBackgroundColor, baseButtonHoverColor} from "../../utils/vars";

const PrimaryButtonComponent = styled(BaseButton)`
  
`

const PrimaryButton = ({onClick, text, disabled, loading, children, type = "button", small = false, props}) => {
  const disableClick = disabled || loading;
  const buttonClick = disableClick ? undefined : onClick;

  const renderButtonContent = () => {
    return text ? (
        <TitleSecondary small={small}>
          {text}
        </TitleSecondary>
    ) :(
        <span>{children}</span>
    )
  }

  return (
      <PrimaryButtonComponent {...props} type={type} onClick={buttonClick}>
        {renderButtonContent()}
      </PrimaryButtonComponent>
  )
}

export default PrimaryButton;
