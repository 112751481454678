import * as React from "react"
import styled from "styled-components"
import GroupTabsSection from "../components/GroupTabsSection";
import GroupDetailed from "./GroupDetailed";
import GroupMembers from "./GroupMembers";
import GroupHistoryContent from "./GroupHistoryContent";
import {useEffect} from "react";
import GroupStore from "../../../../../../shared/stores/GroupStore";
import {useQuery} from "../../../../../../shared/utils/useQuery";

const DetailedWrapperContainer = styled.div`

`

const DetailedWrapper = () => {
  const queryParams = useQuery();
  const groupId = queryParams.get("group");
  
  const {currentGroup} = GroupStore;
  const activeTab = sessionStorage.getItem('groupActiveTab');
  
  useEffect(() => {
    GroupStore.getGroup(groupId)
  }, [currentGroup]);
  
  const loadDetailedView = () => {
    switch(activeTab){
      case "members":
        return <GroupMembers/>
      case "history":
        return <GroupHistoryContent/>
      case "detailed":
      default:
        return <GroupDetailed/>
    }
  }
  
  return(
    <DetailedWrapperContainer>
      <GroupTabsSection/>
      {loadDetailedView()}
    </DetailedWrapperContainer>
  )
}

export default DetailedWrapper