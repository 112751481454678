import { useEffect } from 'react';
import { observer } from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import errorStore from "../stores/ErrorStore";

const ErrorHandler = observer(() => {
  const navigate = useNavigate();
  
  useEffect(() => {
    if (errorStore.navigatePath) {
      navigate(errorStore.navigatePath);
      errorStore.resetNavigatePath(); // Reset path to prevent repeated navigation
    }
  }, [errorStore.navigatePath, navigate]);
  
  return null;
});

export default ErrorHandler;