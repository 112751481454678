import {action, makeAutoObservable, observable} from "mobx";
import ApiGateway from "../api/ApiGateway";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import {setUserLoggedInCookie} from "./CookieStore";
import {setUserIsLoggedIn} from "./ConfigStore";
import {blankUser} from "../utils/blankUser";
import projectStore from "./ProjectStore";
import {clearUserData} from "../../services/indexedDBService";
import componentStore from "./ComponentStore";
import ModalStore from "./ModalStore";

class UserStore {

  user = observable(blankUser)
  isLoggedIn = false
  username = ""
  isVerifying = false;
  
  isAuthenticating = false;
  isFetching = false;
  isSendingVerification = false;
  
  userDataToBeAdded = null
  
  notVerifiedEmail = null

  constructor() {
    makeAutoObservable(this)
  }

  setUserData = action((token)=>{
    let decoded = jwt_decode(token);
    let newUser = {
      accessLevel: decoded.level,
      user_id: decoded.user_id,
      name: decoded.name,
      firstName: decoded.name.split(' ')[0]
    }
    this.user = newUser;
  })

  async loadUserData(){
    let sessionToken = {token: Cookie.get("SID")};
    if(!sessionToken){
      this.setIsLoggedIn(false)
      return
    }
    let status = await this.authenticateUser(sessionToken)

    if(status.auth === false){
      Cookie.remove('logged_in')
      Cookie.remove('SID')
      this.setUser(blankUser)

      setUserIsLoggedIn(false)
    }

    if(status.auth === true){
      this.setUserData(sessionToken.token)
      this.setIsLoggedIn(true)

      setUserIsLoggedIn(true)
    }
  }
  
  async setUser(){
    this.user = blankUser
  }
  
  setIsLoggedIn(value) {
    this.isLoggedIn = value
  }

  async authenticateUser(sessionToken){
    if(this.isAuthenticating) return false
    this.isAuthenticating = true;
    let result = await ApiGateway.auth.authToken(sessionToken);
    this.isAuthenticating = false;
    return result;
  }

  async signUp(data){
    if(this.isFetching) return;
    ModalStore.setModalLoading(true)
    this.isFetching = true;
    let result = await ApiGateway.user.register(data);

    this.isFetching = false;
    ModalStore.setModalLoading(false)
    return result;
  }

  async signIn(data){
    if(this.isFetching) return
    this.isFetching = true;
    ModalStore.setModalLoading(true)
    let result = await ApiGateway.user.login(data);

    if(result.success){
      setUserLoggedInCookie(result.token)
      this.setUserData(result.token);
      this.setIsLoggedIn(true)
      setUserIsLoggedIn(true);
    }
    if(result && result.email){
      this.notVerifiedEmail = result.email
    }
    ModalStore.updateModal("emailNotVerified", true)
    ModalStore.setModalLoading(false)
    this.isFetching = false;
    return result;
  }

  async verifyUsername(value){
    let data = {username: value}

    let result = await ApiGateway.user.verifyUsername(data);

    return result.result[0].users <= 0;
  }

  async verifyEmailNotExist(value){

    let result = await ApiGateway.user.verifyEmailExist(value);

    return result.result[0].users <= 0;
  }
  
  async verifyUserEmail(verificationToken, onSuccessCallback){
    if(this.isVerifying) return;
    this.isVerifying = true;
    
    let result = await ApiGateway.user.verifyEmail(verificationToken);
    
    if (result.success && onSuccessCallback) {
      onSuccessCallback();
    }
    this.isVerifying = false;
  }
  
  async requestResetPassword(data){
    await ApiGateway.user.requestResetPassword(data)
  }
  
  async verifyUserExist(values) {
    let result = await ApiGateway.user.verifyUserExist(values);
    
    this.userDataToBeAdded = result.result[0]
    return result.result[0]
  }
  
  async resetPassword(data, onSuccessCallback){
    let result = await ApiGateway.user.resetPassword(data);
    if (result.success && onSuccessCallback) {
      onSuccessCallback();
    }
  }
  
  async resendEmailVerification(){
    if(this.isSendingVerification) return
    this.isSendingVerification = true;
    ModalStore.setModalLoading(true)
    
    
    let result = await ApiGateway.user.resendEmailVerification({email: this.notVerifiedEmail});
    
    ModalStore.setModalLoading(false)
    this.isSendingVerification = false
    return result;
  }
  
  clearStoredData(){
    clearUserData()
    localStorage.removeItem("userProfileBannerData");
    localStorage.removeItem("userProfilePictureData");
  }

  async signOut(){
    ModalStore.setModalLoading(true)
    Cookie.remove('logged_in')
    Cookie.remove('SID')
    setUserIsLoggedIn(false)
    this.user=blankUser;
    this.setIsLoggedIn(false)
    projectStore.reset()
    componentStore.reset()
    this.clearStoredData()
    ModalStore.setModalLoading(false)
    return {success: true, navigateTo: "/"};
  }

}

export default new UserStore()