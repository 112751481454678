import React from 'react';
import { observer } from 'mobx-react';
import styled from "styled-components"
import {sizes} from "../../../../../shared/utils/media";
import ParagraphBold from "../../../../../shared/components/text/ParagraphBold";
import translation from "../../../../../shared/utils/translation";
import {primaryShadow} from "../../../../../shared/utils/vars";
import {useNavigate} from "react-router-dom";

const ProfileProjectsDisplayTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
 
`

const RowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 350px;
    height: 60px;
    border: 1px solid black;
    margin-bottom: 5px;
    @media only screen and (max-width:${sizes.tablet}px){
        width: 90%;
    }
    cursor: pointer;
`

const RowContentWrapper = styled.div`
`

const ProfileProjectsDisplayTable = observer(({projects, type}) => {
  const navigate = useNavigate();
  const onProjectClick = (projectId) => {
    navigate(`/project?project_id=${projectId}`)
  }
  
  const getFormattedDate = (unFormattedDate) => {
    const dateString = unFormattedDate;
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    return formattedDate;
  }
  
  return (
    <ProfileProjectsDisplayTableWrapper>
      {projects.map((project) => (
        <RowContainer
          onClick={() => onProjectClick(project.project_id)}
          key={project.project_id}
          style={{
            backgroundImage: `url(${project.fullImageUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        >
          <RowContentWrapper>
            <ParagraphBold textShadow={primaryShadow} small={true}>{translation("common.title")}</ParagraphBold>
            <ParagraphBold textShadow={primaryShadow}>{project.name}</ParagraphBold>
          </RowContentWrapper>
          <RowContentWrapper>
            {type == "latestUpdated" ?(
                <>
                  <ParagraphBold textShadow={primaryShadow} small={true}>{translation("common.updated")}</ParagraphBold>
                  <ParagraphBold textShadow={primaryShadow}>{getFormattedDate(project.last_updated)}</ParagraphBold>
                </>
                
              )
              :
              (
                <>
                  <ParagraphBold textShadow={primaryShadow} small={true}>{translation("common.created")}</ParagraphBold>
                  <ParagraphBold textShadow={primaryShadow}>{getFormattedDate(project.created_at)}</ParagraphBold>
                </>
              )
              
            }
            
          </RowContentWrapper>
   
          
        </RowContainer>
      ))}
    </ProfileProjectsDisplayTableWrapper>
  );
});

export default ProfileProjectsDisplayTable;