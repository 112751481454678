import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import ProjectStore from "../../shared/stores/ProjectStore";
import CardList from "../../shared/components/CardList";
import UserStore from "../../shared/stores/UserStore";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import translation from "../../shared/utils/translation";
import ProjectTabsSection from "./sections/ProjectTabsSection";
import AddProjectSection from "./sections/AddProjectSection";
import {useNavigate} from "react-router-dom";
import {Input} from "antd";
import {debounce} from "lodash";

const Wrapper = styled.div`
  overflow: hidden;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const CustomInput = styled(Input)`
  width: 200px;
    border-radius: 8px;
`

const ProjectsList = () => {
  const { projects } = ProjectStore;
  const { isLoggedIn } = UserStore;
  const navigate = useNavigate();
  const activeTab = sessionStorage.getItem('projectActiveTab');
  
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = debounce(() => {
    ProjectStore.loadUserProjects(activeTab, searchTerm)
  }, 500);
  
  useEffect(() => {
    debouncedSearch();
    
    return () => debouncedSearch.cancel();
  }, [searchTerm]);
  
  useEffect(() => {
    
    if (isLoggedIn) {
      ProjectStore.loadUserProjects(activeTab);
      if(activeTab != "all"){
        navigate(`/project?activeTab=${activeTab}`)
      }
    }
  }, [isLoggedIn, activeTab]);
  
  const onSearchInput = (value) => {
    setSearchTerm(value);
  };
  
  return (
    <Wrapper>
      <TitleWrapper>
        <TitlePrimary small={true}>{translation("common.project")}</TitlePrimary>
        <CustomInput
          placeholder={translation("input.placeholder.searchProject")}
          onChange={(e) => onSearchInput(e.target.value)}
        />
      </TitleWrapper>
      <ProjectTabsSection/>
      <AddProjectSection/>
      <CardList specialColSize={{ xs: 24, sm: 12, md: 12, lg: 6}} showActionCard={false} actionOnClick={() => {}} contentToLoad={"project"} object={projects} paginationAction={() => {}} />
    </Wrapper>
  );
};

export default observer(ProjectsList);