import {addProjectToDB, clearUserData, saveProjectsToIndexedDB, updateIndexedDB} from "./indexedDBService";
import {getCookiePreferences} from "../shared/stores/CookieStore";
import Cookie from "js-cookie";

const storageMapping = {
  preference: {
    localStorage: ["userProfilePictureData", "userProfileBannerData"],
    sessionStorage: [],
    cookieStorage: [],
    indexedDB: []
  },
  indexedDb: {
    localStorage: [],
    sessionStorage: [],
    cookieStorage: [],
    indexedDB: ["projects"]
  }
};

export async function setStorage(name, data, type, preference, saveFunction) {
  const preferences = name == "cookiePreferences" ? data : getCookiePreferences();
  if(preferences == null) return
  
  if (preferences[preference] !== true) {
    console.log(`Storage operation blocked: User has opted out of ${preference} cookies.`);
    return;
  }

  switch (type) {
    case "localStorage":
      localStorage.setItem(name, JSON.stringify(data));
      break;
    case "sessionStorage":
      sessionStorage.setItem(name, JSON.stringify(data));
      break;
    case "cookieStorage":
      Cookie.set(name, data, { expires: 1, path: '/' });
      break;
    case "indexedDB":
      if(name == "save"){
        saveProjectsToIndexedDB(data)
      }
      if(name == "update"){
        updateIndexedDB(data)
      }
      if(name == "add"){
        addProjectToDB(data)
      }
      break;
    default:
      throw new Error("Unsupported storage type");
  }
}

export function clearDataBasedOnPreferences(preferences) {
  Object.keys(storageMapping).forEach(preferenceKey => {
    if (!preferences[preferenceKey]) {
      const types = storageMapping[preferenceKey];
      Object.keys(types).forEach(type => {
        types[type].forEach(name => {
          switch (type) {
            case "localStorage":
              localStorage.removeItem(name);
              break;
            case "sessionStorage":
              sessionStorage.removeItem(name);
              break;
            case "cookieStorage":
              console.log("hello")
              break;
            case "indexedDB":
              clearUserData();
              break;
          }
        });
      });
    }
  });
}