import React from "react"
import styled from "styled-components"
import PrimaryButton from "../../buttons/PrimaryButton";
import translation from "../../../utils/translation";
import {useNavigate} from "react-router-dom";
import TextButton from "../../buttons/TextButton";
import OutlineProjectsIcon from "../../icons/OutlineProjectsIcon";
import {dangerColor, gutter, menuTextColor} from "../../../utils/vars";
import PersonIcon from "../../icons/PersonIcon";
import SignOutIcon from "../../icons/SignOutIcon";
import {sizes} from "../../../utils/media";

const MenuContentContainer = styled.div`
  display: ${p => p.isOpen ? "flex" : "none"};
  flex-direction: column;
  margin: 35px 20px;
  @media only screen and (max-width:${sizes.tablet}px){
    align-items: center;
  }
`

const MenuTextButton = styled(TextButton)`
  color: ${p => p.color ? p.color : menuTextColor};
  font-size: 24px;
  margin-bottom: ${gutter * 2}px;
  &:hover {
    opacity: .6;
  }
  &:focus {
    outline-offset: 5px;
    outline: 1px solid ${p => p.color ? p.color : menuTextColor};
  }
`

const MenuContent = ({isOpen, isLoggedIn, openModal, closeMenu}) => {
  let navigate = useNavigate();

  const handleMenuNavigateClick = (navigateUrl) => {
    navigate(navigateUrl)
    closeMenu()
  }

  const renderContent = () => {
    if(isLoggedIn ){
      return(
          <>
            <MenuTextButton onClick={() =>  handleMenuNavigateClick("/profile")} text={translation("common.profile")} icon={<PersonIcon/>}/>
            <MenuTextButton onClick={() =>  handleMenuNavigateClick("/project")} text={translation("common.project")} icon={<OutlineProjectsIcon/>}/>
            <MenuTextButton onClick={() => openModal({modalTopic: "user", modalName: "signOut"})} text={translation("common.signOut")} icon={<SignOutIcon/>} color={dangerColor}/>
          </>
      )
    }
    else{
      return(
          <>
            <PrimaryButton onClick={() => openModal({modalTopic: "user", modalName: "signIn"})} text={translation("common.signIn")}/>
            <PrimaryButton onClick={() => openModal({modalTopic: "user", modalName: "signUp"})} text={translation("common.signUp")}/>
          </>
      )
    }
  }


  return (
      <MenuContentContainer isOpen={isOpen} isLoggedIn={isLoggedIn}>
        {renderContent()}
      </MenuContentContainer>
  );
}

export default MenuContent;
