import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import ProjectsList from "../project/ProjectsList";
import OverviewDisplay from "../overview/OverviewDisplay";
import {useQuery} from "../../shared/utils/useQuery";

const Wrapper = styled.div`
  overflow: hidden;
`

const ProjectWrapper = () => {
  let queryParams = useQuery();
  let projectId = queryParams.get("project_id")
  let componentId = queryParams.get("component_id")

    return (
      <Wrapper>
        {
          projectId || componentId ?
            <OverviewDisplay />
            :
            <ProjectsList/>
        }
      </Wrapper>

    )
}

export default observer(ProjectWrapper)