import { openDB } from 'idb';
import {getCookiePreferences} from "../shared/stores/CookieStore";

const DATABASE_NAME = 'userProjectsDB';
const DATABASE_VERSION = 1;
const STORE_NAME = 'projects';

async function getDB() {
  return openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    },
  });
}

export async function addProjectToDB(project) {
  if(getCookiePreferences() == null) return
  if(getCookiePreferences().indexedDB == false)return
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  
  const expiration = new Date().getTime() + (90 * 60 * 1000);
  const projectWithExpiration = {...project, id: project.project_id, expiration };
  const request = store.add(projectWithExpiration);
  
  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      reject('Error adding project to DB:', event.target.errorCode);
    };
    
    request.onsuccess = () => {
      resolve(request.result);
    };
  });
}

export async function saveProjectsToIndexedDB(projects) {
  if(getCookiePreferences() == null) return
  if(getCookiePreferences().indexedDB == false)return
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  const expiration = new Date().getTime() + (90 * 60 * 1000);
  
  projects.forEach(project => {
    const projectWithExpiration = {...project, id: project.project_id, expiration };
    store.put(projectWithExpiration);
  });

  await tx.done;
}

export async function deleteExpiredProjects() {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  const allRecords = await store.getAll();
  const currentTime = new Date().getTime();
  
  allRecords.forEach(async (record) => {
    if (record.expiration && record.expiration < currentTime) {
      await store.delete(record.id);
    }
  });

  await tx.done;
}

export async function updateIndexedDB(project) {
  const db = await getDB(); // Assuming getDB() opens and upgrades the database as needed
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  
  const expiration = new Date().getTime() + (90 * 60 * 1000);
  
  store.put({id: project.project_id, ...project, expiration});
  
  await tx.done;
}

export async function clearUserData() {
  const db = await openDB(DATABASE_NAME, 1);
  const tx = db.transaction(STORE_NAME, 'readwrite')
  await tx.objectStore(STORE_NAME).clear();
  await tx.done;
}

export async function loadProjectsFromIndexedDB() {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  const allProjects = await store.getAll();
  
  
  return allProjects;
}
