import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite";
import {Form} from "antd";
import styled from "styled-components";
import {modalBackgroundColor} from "../../utils/vars";
import ModalHeader from "./ModalHeader";
import modalStore from "../../stores/ModalStore";
import {toJS} from "mobx";
import InputModalTemplate from "./content/InputModalTemplate";
import ConfirmationModalTemplate from "./content/ConfirmationModalTemplate";
import FocusTrap from "focus-trap-react";
import {sizes} from "../../utils/media";

const ModalWrapper = styled.div`
  position: relative;
  background-color: ${modalBackgroundColor};
  max-width: 500px;
  min-height: 450px;
  z-index: 9999;
  margin: 1.5rem auto;
  border-radius: 2%;
  border: 1px solid black;
`

const ModalContentWrapper = styled.div`
  padding: 25px 50px;
  text-align: center;
    @media only screen and (max-width:${sizes.phone}px){
        padding: 25px 5px;
    }
`

const ModalContainer = () => {
  const [form] = Form.useForm();

  const [modalType, setModalType] = useState("")

  useEffect(() => {
    modalStore.getModalData().then((data) =>{
      setModalType(toJS(data.type))
    })
  }, [modalStore.modal])

  const renderModal = () => {
    switch (modalType) {
      case "input":
        return <InputModalTemplate form={form} modalObject={modalStore} />;
      case "confirmation":
        return <ConfirmationModalTemplate modalObject={modalStore} />;
      default:
        return null;
    }
  };

  return (
      <FocusTrap>
        <ModalWrapper>
          <ModalHeader modalObject={modalStore} />
          <ModalContentWrapper>{renderModal()}</ModalContentWrapper>
        </ModalWrapper>
      </FocusTrap>
  );
};

export default observer(ModalContainer);