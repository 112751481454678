import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Image} from "antd";
import {AiOutlinePlus} from "react-icons/ai";
import {lightBgHover} from "../../utils/vars";
import { FaRegImage } from "react-icons/fa";
const SectionWrapper = styled.div`
  display: flex;
    flex-direction: column;
    justify-items: center;
    margin: auto;
    
`

const ImageWrapper = styled.div`
  display: flex;
  width: ${p => p.width};
  
  cursor: pointer;
  justify-content: center;
    align-items: center;
  ${p => !p.hasImage && `border: 2px solid black;`};
  :hover{
    background-color: ${lightBgHover};
  }
`
const CustomImage = styled(Image)`
  max-height: 300px;
  width: 100%;
`
const PlusIcon = styled(AiOutlinePlus)`
  font-size: 65px;
  margin: auto;
`
const ProjectNameText = styled.span`
  font-size: 30px;
  margin: auto;
  text-decoration: underline;
`

const NoImageIcon = styled(FaRegImage)`
  font-size: 80px;
`

const ImageNameSection = observer(({ object, addImageOnClick, type }) => {
  
  return (
    <SectionWrapper>
      <ProjectNameText>{object.name}</ProjectNameText>
      <ImageWrapper hasImage={object.image_name} onClick={!object.image_name ? addImageOnClick : undefined} width={object.imageName ? "100%" : "250px"}>
        {
          object.image_name ? (
              <CustomImage src={object.fullImageUrl}/>
          ) : object.canEdit ?
            (
              <PlusIcon/>
            )
            :
            (
              <NoImageIcon/>
            )
        }
      </ImageWrapper>
    </SectionWrapper>
  );
});

export default ImageNameSection;
