import React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {sizes} from "../utils/media";
import {useNavigate} from "react-router-dom";
import {setMenuIsOpen} from "../stores/ConfigStore";
import OutlineMenuIcon from "./icons/OutlineMenuIcon";
import userStore from "../stores/UserStore";
import {primaryShadow} from "../utils/vars";
import ParagraphBold from "./text/ParagraphBold";

const HeaderWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 80px);
  height: 48px;
  background-color: inherit;
  padding: 20px 40px;
    z-index: 99;
  
  @media only screen and (max-width:${sizes.desktop}px){
    height: 40px;
    padding: 8px 25px;
    width: calc(100% - 50px);
  }
`

const Right = styled.div`
`

const MenuIconWrapper = styled.div`
  display: flex;
`

const MenuIconButton = styled.button`
  background-color: inherit;
  border: none;
  cursor: pointer;
  margin: ${p => p.margin ? p.margin : 0};
  text-shadow: ${primaryShadow};

  svg {
    border-radius: ${p => p.circle ? "100%" : 0};
  }

  &:hover ${MenuIconWrapper}{
    opacity: .7;
  }

  &:focus ${MenuIconWrapper}{
    ${p => p.isLoggedIn ?
      `border: 2px solid ${p.theme.complementaryColor};`
            :
      `svg{border: 2px solid ${p.theme.complementaryColor};}`
    }
  }
`

const Header = ({}) => {
  let navigate = useNavigate();

  const {user, isLoggedIn} = userStore

  return(
    <>
      <HeaderWrapper id="header">
        <h2 onClick={() => navigate(`/`)}>Logo</h2>
        <Right>
          <MenuIconButton onClick={() => setMenuIsOpen(true)} isLoggedIn={isLoggedIn} light={true} circle={false}>
            <MenuIconWrapper>
              <ParagraphBold margin={"auto 10px"}>{user.username != null ? user.username : user.firstName}</ParagraphBold>
              <OutlineMenuIcon/>
            </MenuIconWrapper>
          </MenuIconButton>
        </Right>
      </HeaderWrapper>
    </>

  )
}

export default observer(Header)