import React, {useCallback, useEffect, useLayoutEffect, useRef} from "react"
import styled from "styled-components"
import Menu from "./Menu"

const MenuContainer = styled.div`
`

const MenuWrapper = ({children}) => {
  return (
    <MenuContainer>
      <Menu/>
    </MenuContainer>
  );
}

export default MenuWrapper;
