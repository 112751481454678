import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import ProjectStore from "../../../../../../../shared/stores/ProjectStore";
import LoadingSpinner from "../../../../../../../shared/components/LoadingSpinner";
import {Row} from "antd";
import DeletedProjectCard from "../DeletedCard";
import ModalStore from "../../../../../../../shared/stores/ModalStore";


const DeletedWrapperComponent = styled.div`
    margin-top: 25px;
    width: 100%;
`;
const DeletedProjectTab = () => {
  
  const {isFetchingDeleted, deletedProjects} = ProjectStore
  
  useEffect(() => {
    ProjectStore.getDeleted();
  }, []);
  
  if(isFetchingDeleted && !deletedProjects && deletedProjects == undefined){
    return <LoadingSpinner/>
  }
  
  const restoreProject = (project) => {
    ProjectStore.setSelectedProject(project)
    ModalStore.addModal({ modalTopic: "project", modalName: "restoreProject" });
  }
  
  const permDeleteProject = (project) => {
    ProjectStore.setSelectedProject(project)
    ModalStore.addModal({ modalTopic: "project", modalName: "permDeleteProject" });
  }
  
  return (
    <DeletedWrapperComponent>
      <Row>
        {(deletedProjects && deletedProjects.length > 0) &&
          deletedProjects.map((project, index) =>{
            return (
              <DeletedProjectCard
                key={index}
                index={index}
                restoreOnClick={restoreProject}
                permDeleteOnClick={permDeleteProject}
                canLeave={true}
                object={project}
              />
            )
          })
        }
      </Row>
    </DeletedWrapperComponent>
  )
}

export default observer(DeletedProjectTab)
