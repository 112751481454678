import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import TitleSection from "./sections/TitleSection";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 48px;
`

const HomeWrapper = () => {

  return (
    <Wrapper>
      <TitleSection/>
    </Wrapper>
  )
}

export default observer(HomeWrapper)