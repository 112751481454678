import React, {useEffect} from 'react';
import styled from 'styled-components';
import GroupStore from "../../../../../../shared/stores/GroupStore";
import {useQuery} from "../../../../../../shared/utils/useQuery";
import {observer} from "mobx-react";
import translation from "../../../../../../shared/utils/translation";
import { format } from "date-fns";
import CustomDivider from "../../../../../../shared/components/PageSectionDivider";
import ParagraphBold from "../../../../../../shared/components/text/ParagraphBold";
import {Form} from "antd";
import DefaultFormInput from "../../../../../../shared/components/input/DefaultFormInput";
import {validateMessages} from "../../../../../../shared/utils/validateMessage";
import WhiteButton from "../../../../../../shared/components/buttons/WhiteButton";
import {ADMINISTRATOR_ROLES} from "../../../../../../shared/utils/Constants";
import DangerButton from "../../../../../../shared/components/buttons/DangerButton";
import ModalStore from "../../../../../../shared/stores/ModalStore";

const GroupDetailContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const GroupDetailCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
`;

const GroupName = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
`;

const GroupDescription = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

const GroupMeta = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`

`

const GroupDetailed = () => {
  const queryParams = useQuery();
  const groupId = queryParams.get("group");
  
  const {currentGroup} = GroupStore;
  
  useEffect(() => {
    GroupStore.fetchUserGroups(groupId)
    GroupStore.getGroup(groupId)
  }, []);
  
  
  const onSubmitAddUser = (values) => {
    GroupStore.inviteUserToGroup(values);
  }
  
  const leaveGroupOnClick = () => {
    ModalStore.addModal({modalTopic:"group", modalName:"leaveGroup"})
  }
  
  return(
    <>
      {
        currentGroup != null &&
        <>
          <GroupDetailContainer>
            <GroupDetailCard>
              <GroupMeta>
                <GroupName>{currentGroup.group_id} - {currentGroup.group_name}</GroupName>
                {
                  currentGroup.role_name != "owner" &&
                  <ButtonWrapper>
                    <DangerButton onClick={() => leaveGroupOnClick()}>{translation("profile.group.detailed.leaveGroup")}</DangerButton>
                  </ButtonWrapper>
                }
              </GroupMeta>
              <GroupMeta>
                <GroupDescription>{currentGroup.description}</GroupDescription>
                <GroupDescription>{translation("profile.group.detailed.role")}: {currentGroup.role_name}</GroupDescription>
              </GroupMeta>
              <GroupMeta>
                <span>{translation("profile.group.tab.members")}: {currentGroup.total_members}</span>
                <span>{translation("settings.group.latestActivity")}: {format(new Date(currentGroup.last_activity), 'PPPpp')}</span>
              </GroupMeta>
            </GroupDetailCard>
          </GroupDetailContainer>
          {
            ADMINISTRATOR_ROLES.includes(currentGroup.role_name) &&
            <>
              <CustomDivider><ParagraphBold>{translation("profile.settings.group.inviteUser")}</ParagraphBold></CustomDivider>
              <Form
                name="accountSettings"
                initialValues={{ remember: true }}
                onFinish={onSubmitAddUser}
                scrollToFirstError
              >
                <DefaultFormInput
                  field={{
                    name:"userToInvite",
                    rules:[
                      { required: true, message: translation("form.field.error.message.empty") },
                    ],
                    label: translation("form.label.userToInvite")
                  }}
                />
                <DefaultFormInput
                  field={{
                    name:"groupRole",
                    roleType: "groups",
                    rules:[
                      { required: true, message: translation("form.field.error.message.empty") },
                      () => ({
                        async validator(_, value) {
                          if(value){
                            if(value < 0 || value > 3){
                              return Promise.reject(new Error(validateMessages.role.doesNotExist));
                            }
                            return Promise.resolve();
                          }
                        },
                      })
                    ],
                    label: translation("form.label.groupRole"),
                    displayRoleInformation: true
                  }}
                />
                <WhiteButton type="primary" htmlType="submit" disabled={true}>{translation("form.title.inviteUser")}</WhiteButton>
              </Form>
            </>
          }
        </>
      }
    </>

  )
}

export default observer(GroupDetailed);