import React from 'react';
import { Spin } from 'antd';
import styled from "styled-components";

const Spinner = styled(Spin)`
      ${p => p.small && `
      span{
      font-size: 15px;
      }
      i {
          height: 6px;
          width: 6px;
      }
    `}
`

const LoadingSpinner = ({small = false}) => {
  return (
    <div>
      <Spinner small={small.toString()}/>
    </div>
  );
};

export default LoadingSpinner;