import { makeAutoObservable } from "mobx";

class ErrorStore {
  navigatePath = "";
  
  constructor() {
    makeAutoObservable(this);
  }
  
  setNavigatePath(path) {
    this.navigatePath = path;
  }
  
  resetNavigatePath() {
    this.navigatePath = "";
  }
}

export default new ErrorStore();