import React from "react"
import styled from "styled-components"
import TitlePrimary from "../text/TitlePrimary";
import TitleSecondary from "../text/TitleSecondary";
import BaseButton from "./BaseButton";
import {
  darkFocusBorder,
  gutter,
  redButtonBackgroundColor,
  redButtonHoverColor,
  whiteButtonBackgroundColor,
  whiteButtonHoverColor
} from "../../utils/vars";
import LoadingSpinner from "../LoadingSpinner";
import WhiteButton from "./WhiteButton";

const SubmitComponent = styled(WhiteButton)`
    background-color: ${p => p.clear ? whiteButtonBackgroundColor : redButtonBackgroundColor};
    border: 1px solid black;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    text-align: center;
    transition: background 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
    padding: ${gutter}px 0;
    width: 100%;
    max-width: 100%;
    margin: 10px auto;
    font-size: 18px;
    font-weight: bold;

    &:disabled,
    &[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
    }

    &:not(:disabled):hover, &:not(:disabled):focus {
        background-color: ${p => p.clear ? whiteButtonHoverColor : redButtonHoverColor};
    }

    &:focus {
        border: ${darkFocusBorder};
    }
`;
const SubmitButton = ({ onClick, text, loading, type = "button", clear = true }) => {
  return (
    <SubmitComponent
      type="submit"
      disabled={loading}
      onClick={onClick}
      clear={clear}
    >
      {loading ? <LoadingSpinner small={true} /> : text}
    </SubmitComponent>
  );
};

export default SubmitButton;
