import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import CenteredBox from "../../shared/components/CenteredBox";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import ParagraphBold from "../../shared/components/text/ParagraphBold";
import UserStore from "../../shared/stores/UserStore";
import {useQuery} from "../../shared/utils/useQuery";
import {Form} from "antd";
import WhiteButton from "../../shared/components/buttons/WhiteButton";
import DefaultFormInput from "../../shared/components/input/DefaultFormInput";
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 48px;
`
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const ResetPasswordPage = () => {
  const queryParams = useQuery();
  const resetToken = queryParams.get("token");
  const navigate = useNavigate();
  
  const resetPassword = async(values) => {
    let data = {newPassword: values.password, token: resetToken}
    await UserStore.resetPassword(data, () => navigate('/'));
  }
  
  return (
    <Wrapper>
      <CenteredBox>
        <ColumnBox>
          <TitlePrimary small={true}>{translation("common.resetPassword")}</TitlePrimary>
          <ParagraphBold>{translation("passwordReset.description")}</ParagraphBold>
          <Form
            name="reset_password"
            initialValues={{ remember: true }}
            onFinish={resetPassword}
            scrollToFirstError
          >
            <DefaultFormInput
              field={{
                maxLength: 50,
                name:"password",
                type: "password",
                rules:[
                  { required: true, message: translation("form.field.error.message.empty") },
                  { min: 6, message: translation("form.password.notLongEnough") },
                ],
                hasFeedback: true,
                label:translation("common.password")
              }}
            
            />
            <DefaultFormInput
              field={{
                name:"confirm",
                dependencies:['password'],
                type: "password",
                hasFeedback: true,
                rules:[
                    { required: true, message: translation("form.field.error.message.empty") },
                    { min: 6, message: translation("form.password.notLongEnough") },
                    ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                    }
                  return Promise.reject(new Error(translation("form.password.error.message.notMatch")));
                  },
                  }),
                ],
                label: translation("common.password")
              }}
              
            />
            
            
            <Form.Item>
              <WhiteButton type="primary" htmlType="submit">
                Reset Password
              </WhiteButton>
            </Form.Item>
          </Form>
        </ColumnBox>
      </CenteredBox>
    </Wrapper>
  )
}

export default observer(ResetPasswordPage)