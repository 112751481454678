import React from "react"
import { ThemeProvider } from "styled-components"
import {gutter} from "../utils/vars";
import {observer} from "mobx-react-lite";

function ThemeContainer(props){

    const gridSpacingConfig = {
        space: [0, gutter, gutter * 1.5, gutter * 2]
    }

    const themeValues ={
        primaryColor: "#FFFFF4",
        complementaryColor: "#71716C",
        secondaryColor: "#31312F",
        titleColor: "#181816",
        bodyTextColor: "#AAC4E2",
    }

    return (
     <ThemeProvider theme={{ ...themeValues, ...gridSpacingConfig }}>
       {props.children}
     </ThemeProvider>
    )
}

export default observer(ThemeContainer)