import React, {useEffect, useCallback, useState} from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {Alert, Row} from "antd";
import {IoReturnUpBack, IoSettingsSharp} from "react-icons/io5";
import { AiFillEdit, AiFillHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../shared/utils/useQuery";
import DescriptionSection from "../../shared/components/sections/DescriptionSection";
import ComponentListSection from "../../shared/components/sections/SectionComponentSection";
import ProjectStore from "../../shared/stores/ProjectStore";
import ComponentStore from "../../shared/stores/ComponentStore";
import ModalStore from "../../shared/stores/ModalStore";
import { debounce } from 'lodash';
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ImageNameSection from "../../shared/components/sections/ImageNameSection";
import translation from "../../shared/utils/translation";
import {MdDelete} from "react-icons/md";
import FileSection from "../../shared/components/sections/FileSection";
import Dropdown from "../../shared/components/Dropdown";
import {ADMINISTRATOR_ROLES} from "../../shared/utils/Constants";
import ProjectUsersContent from "./content/project/ProjectUsersContent";
import ProjectHistoryContent from "./content/project/ProjectHistoryContent";
import ProjectInvitationsContent from "./content/project/ProjectInvitationsContent";
import NotesContent from "./content/shared/NotesContent";
import ProjectOverviewContent from "./content/project/ProjectOverviewContent";
import ComponentOverviewContent from "./content/component/ComponentOverviewContent";

const Wrapper = styled.div`
  margin-top: 48px;
`
const ReturnIcon = styled(IoReturnUpBack)`
  font-size: 25px;
  cursor: pointer;
`
const EditIcon = styled(AiFillEdit)`
  font-size: 25px;
  cursor: pointer;
  margin-left: 25px;
`
const ReturnToProjectIcon = styled(AiFillHome)`
  font-size: 25px;
  cursor: pointer;
  margin-left: 25px;
`
const SettingsIcon = styled(IoSettingsSharp)`
    font-size: 22px;
    cursor: pointer;
    margin-left: 25px;
`

const AlertWrapper = styled.div`
  margin-bottom: 25px;
`

const IconWrapper = styled.div`
  display: flex;
    flex-direction: row;
    margin-bottom: 25px;
`

const ProjectHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

`

const HeaderLeftSide = styled.div`
  display:flex;
  flex-direction: row;
`
const HeaderRightSide = styled.div`
`

const DeleteIcon = styled(MdDelete)`
    font-size: 25px;
`

const DeleteWrapper = styled.div`
    display: flex;
    align-content: center;
    cursor: pointer;
    font-weight: 800;
`

const DropdownWrapper = styled.div`

`

const ComponentOverview = observer(() => {
  const navigate = useNavigate();
  const queryParams = useQuery();
  const projectId = queryParams.get("project_id");
  const componentId = queryParams.get("component_id");
  const pageContent = queryParams.get("content");
  
  const [componentLoaded, setComponentLoaded] = useState(false)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  
  const { selectedProject } = ProjectStore
  
  const { currentComponent } = ComponentStore
  
  const getComponentDebounced = useCallback(debounce((id) => {
    ComponentStore.getComponent(id);
    setComponentLoaded(true)
  }, 300), []);
  
  useEffect(() => {
    if (!selectedProject.project_id) {
      ProjectStore.getProject(projectId);
    }
    if (componentId) {
      getComponentDebounced(componentId);
    }
  }, [projectId, componentId, getComponentDebounced]);
  
  const editComponent = useCallback(() => {
    ModalStore.addModal({ modalTopic: "component", modalName: "edit" });
  }, [ComponentStore.currentComponent]);
  
  const addImageOnClick = useCallback(() => {
    ModalStore.addModal({modalTopic: "component", modalName: "addImage"})
  }, []);
  
  const returnIconClick = useCallback((returnToProject) => {
    ComponentStore.returnComponent().then((id) => {
      const targetPath = returnToProject || id === null
        ? `/project?project_id=${ProjectStore.selectedProject.project_id}`
        : `/project?project_id=${ProjectStore.selectedProject.project_id}&component_id=${id}`;
      navigate(targetPath);
    });
  }, [navigate]);
  
  const deleteComponent = () => {
    ModalStore.addModal({ modalTopic: "component", modalName: "deleteComponent" });
  }
  
  const dropdownOptions = [
    {
      key: 1,
      text: translation("dropdown.project.notes"),
      onClick: () => {
        navigate(`/project?project_id=${projectId}&component_id=${componentId}&content=notes`)
      },
      display: selectedProject && selectedProject.role
    }
  ]
  
  const loadPageContent = () => {
    switch(pageContent){
      case "notes":
        return <NotesContent/>
      default:
        return <ComponentOverviewContent currentComponent={currentComponent}/>
    }
  }
  
  if (ComponentStore.isLoadingComponent && !componentLoaded && !currentComponent) {
    return <LoadingSpinner/>;
  }
  
  return (
    <>
      {currentComponent && (
        <Wrapper>
          {
            currentComponent.project_deleted === 1 &&
            <AlertWrapper>
              <Alert
                message={translation("alert.project.being.deleted")}
                type={"error"}
                description={translation("alert.project.being.deleted.description")}
              />
            </AlertWrapper>
          }
          {
            currentComponent.component_deleted === 1 &&
            <AlertWrapper>
              <Alert
                message={translation("alert.component.being.deleted")}
                type={"error"}
                description={translation("alert.component.being.deleted.description")}
              />
            </AlertWrapper>
          }
          
          <IconWrapper>
            <ProjectHeaderWrapper>
              <HeaderLeftSide>
                <ReturnIcon onClick={() => returnIconClick(false)}/>
                {((currentComponent.project_deleted === 0 && currentComponent.component_deleted === 0) && selectedProject.canEdit) &&
                  <EditIcon onClick={editComponent}/>}
                
                {ComponentStore.componentList.length > 1 &&
                  <ReturnToProjectIcon onClick={() => returnIconClick(true)}/>}
                <DropdownWrapper>
                  <SettingsIcon onClick={() => setDropdownIsOpen(true)}/>
                  <Dropdown isOpen={dropdownIsOpen} setIsOpen={setDropdownIsOpen} options={dropdownOptions}/>
                </DropdownWrapper>
              </HeaderLeftSide>
              <HeaderRightSide>
                {(currentComponent.project_deleted === 0 && currentComponent.component_deleted === 0) &&
                  <DeleteWrapper onClick={() => deleteComponent()}>
                    <DeleteIcon/>
                    {translation("common.delete")}
                  </DeleteWrapper>
                }
              </HeaderRightSide>
            </ProjectHeaderWrapper>
          </IconWrapper>
          <div>
            {loadPageContent()}
          </div>
        </Wrapper>
      )}
    </>
  );
});

export default ComponentOverview;