import * as React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Row } from "antd";
import ParagraphBold from "../text/ParagraphBold";
import translation from "../../utils/translation";
import CustomDivider from "../CustomDivider";
import TextDisplay from "../TextDisplay";

const DescriptionWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const StyledText = styled.div`
    padding: 10px 20px;
`;

const DescriptionSection = observer(({ object }) => {
 
  return (
    <DescriptionWrapper>
      <Row align={"middle"}>
        <CustomDivider main={true} leftContent={<ParagraphBold>{translation("common.description")}</ParagraphBold>}/>
      </Row>
      <TextDisplay text={object.description}/>
    </DescriptionWrapper>
  );
});

export default DescriptionSection;
