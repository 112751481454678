import * as React from "react";
import FormInput from "./FormInput";
import styled from "styled-components";
import {CustomInputBackgroundColor, CustomInputHoverBackgroundColor, gutter} from "../../utils/vars";
import Required from "../Required";
import FormLabel from "../text/FormLabel";
import translation from "../../utils/translation";
import {useEffect, useState} from "react";
import ProjectStore from "../../stores/ProjectStore";
import {Select} from "antd";
import ModalStore from "../../stores/ModalStore";
import {AVAILABLE_ROLES} from "../../utils/Constants";

const CustomInputContainer = styled.div`
  position: relative;
  width: 100%; 
`

const CustomInput = styled(FormInput)`
  border: 1px solid black;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${CustomInputBackgroundColor} !important;
  padding: ${gutter * 1.5}px 5px ${gutter}px 5px;
  height: ${p => p.inputType == "textarea" ? "auto" : "60px !important"} ;
  
  &:focus{
    cursor: text;
    background-color: ${CustomInputHoverBackgroundColor} !important;
  }
`

const LabelSuffix = styled(FormLabel)`
  display: flex;
  justify-content: end;
  width: calc(100% - 10px);
`

const ColumnLabelSuffix = styled.span`
    display: flex;
    flex-direction: column;
`

const Label = styled.div`
  pointer-events: none;
  opacity: ${p => (p.disabled ? 0.3 : 1)};
`
export const InputArea = styled.div`
  margin-top: 15px;
  overflow: hidden;
`

const DefaultFormInput = ({field, form}) => {

  const [extraLabelActive, setExtraLabelActive] = useState(false)

  const {
      label,
      maxLength,
      validateTrigger,
      required,
      name,
      value,
      type,
      rules,
      textFieldSize,
      showCount,
      extraLabel,
      initialValue,
      onChange,
      displayRoleInformation,
    roleType
  } = field
  
  const displayExtraLabel = () => {
    if(name == "name" && extraLabel){
      setExtraLabelActive(!extraLabelActive)
    }
  }

  return (
    <CustomInputContainer>
      <InputArea>
        <CustomInput
          maxLength={maxLength}
          name={name}
          form={form}
          value={value}
          initialValue={initialValue && initialValue()}
          inputType={type}
          rules={rules}
          validateTrigger={validateTrigger}
          onFocus={(e) => displayExtraLabel(e, name)}
          onBlur={(e) => displayExtraLabel(e, name)}
          textFieldSize={textFieldSize}
          showCount={showCount}
          onChange={onChange}
        />
        <Label>
          <FormLabel small={true} alignLine={true}>
            {label ? label : translation(`form.label.${name}`)}
            {extraLabelActive && translation("form.label.name.extra")}
          </FormLabel>
          <LabelSuffix small={true}>
            {maxLength && `${translation("form.label.maxLength")} - ${maxLength}`}
            {required && <Required/>}
            {displayRoleInformation &&
              <ColumnLabelSuffix>
                {AVAILABLE_ROLES[roleType].map((roles, index) => {
                  return(
                    <div key={index} id={roles.key}>
                      {roles.value}
                    </div>
                  )
                })}
              </ColumnLabelSuffix>
            }
           
          </LabelSuffix>
        </Label>
      </InputArea>
    </CustomInputContainer>

  )

}

export default DefaultFormInput