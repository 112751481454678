import {makeAutoObservable, runInAction, toJS} from "mobx";
import ApiGateway from "../api/ApiGateway";
import {
  clearUserData,
  loadProjectsFromIndexedDB,
} from "../../services/indexedDBService";
import ComponentStore from "./ComponentStore";
import {setStorage} from "../../services/setStorage";
import ModalStore from "./ModalStore";

class ProjectStore {
  projects = [];
  selectedProject = {};
  latestUpdatedProjects = [];
  latestCreatedProjects = [];
  projectUsers = []
  projectHistory = []
  projectInvitations = []
  deletedProjects = []
  currentNote = ""
  
  selectedInvitation = null
  selectedUser = null
  
  projectsLoaded = false;
  isLoading = false;
  isAccepting = false
  isFetching = false;
  isFetchingUsers = false
  isFetchingInvitations = false
  isFetchingDeleted = false;
  isAddingFiles = false;
  isAddingNote = false;
  
  constructor() {
    makeAutoObservable(this);
  }
  
  async loadUserProjects(projectType = 'all', name = '') {
    if(this.isLoading) return;
    this.projectsLoaded = false;
    this.isLoading = true;
    
    let data = { projectType, name };
    
    try {
      const userProjectIds = await ApiGateway.project.getProjectIds(projectType, name);
      const storedProjects = await loadProjectsFromIndexedDB();
      
      const loadedProjectIds = storedProjects.map(project => project.project_id);
      const backendIds = userProjectIds.map(id => id);
      
      const missingIds = backendIds.filter(id => !loadedProjectIds.includes(id));
      const extraIds = loadedProjectIds.filter(id => !backendIds.includes(id));
      
      let projectsOutdated = false;
      
      if(storedProjects.length > 0 && missingIds.length === 0 && extraIds.length === 0) {
        let projectData = []
        storedProjects.forEach((project) => {
          projectData.push({last_updated: project.last_updated, project_id: project.project_id})
        });
        data.projectData = projectData;
        let projectsOutdatedFetch = await ApiGateway.project.compareStoredProjectsLastUpdate(data);
        projectsOutdated = projectsOutdatedFetch.needUpdate;
      }
      
      if (storedProjects.length > 0 && missingIds.length === 0 && extraIds.length === 0 && !projectsOutdated) {
        runInAction(() => {
          this.projects = storedProjects;
          this.projectsLoaded = true;
          this.isLoading = false;
        });
      } else {
        await clearUserData();
        const result = await ApiGateway.project.loadProject(projectType, name) // Fetch from backend if not adequate locally
        
        runInAction(() => {
          this.projects = result.objects;
          this.projectsLoaded = true;
          this.isLoading = false;
        });
        setStorage("save", result.objects, "indexedDB", "indexedDB")
      }
    } catch (error) {
      console.error("Failed to load user projects", error);
      this.isLoading = false;
    }
  }
  
  async getProject(id) {
    if (this.isLoading) return;
    this.projectsLoaded = false;
    this.isLoading = true;
    
    try {
      // First, try to get the project from IndexedDB.
      const projects = await loadProjectsFromIndexedDB();
      const project = projects.find(project => project.id === id);
      const isProjectInBreadcrumb = ComponentStore.breadcrumbComponentList.some(item => item.id === id && item.type === "project");
      
      if (project) {
        runInAction(() => {
          this.selectedProject = project;
          if (!isProjectInBreadcrumb) {
            ComponentStore.breadcrumbComponentList.push({ title: this.selectedProject.name, path: `/project?project_id=${this.selectedProject.project_id}`, id: this.selectedProject.project_id, type: "project" });
          }
          this.isLoading = false;
        });
        this.projectsLoaded = true;
        return this.selectedProject;
      }
      
      const result = await ApiGateway.project.getProject(id);
      
      runInAction(() => {
        this.selectedProject = result.objects[0];
        if (!isProjectInBreadcrumb) {
          ComponentStore.breadcrumbComponentList.push({ title: result.objects[0].name, path: `/project?project_id=${result.objects[0].project_id}`, id: result.objects[0].project_id, type: "project" });
        }
        this.isLoading = false;
      });
      
      setStorage("save", result.objects, "indexedDB", "indexedDB")
      
      this.projectsLoaded = true;
      return this.selectedProject;
      
    } catch (error) {
      console.error("Failed to get project from API", error);
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
  
  async editProject(data = {}) {
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    const formData = new FormData();
    formData.append('projectId', this.selectedProject.project_id);
    
    let hasChanges = false;
    
    if(data.image && data.image.length === 1) {
      formData.append('image', data.image[0].originFileObj);
      formData.append('oldImageName', this.selectedProject.image_name);
      hasChanges = true;
    }else if(data.image && data.image.length === 0){
      formData.append('image', data.image);
      formData.append('oldImageName', this.selectedProject.image_name);
      hasChanges = true;
    }
    

    
    Object.keys(data).forEach(key => {
      if (toJS(this.selectedProject).hasOwnProperty(key) && data[key] !== toJS(this.selectedProject[key])) {
        if (key === 'image') {
          hasChanges = true;
        } else {
          formData.append(key, data[key]);
          hasChanges = true;
        }
      }
    });
    
    if (hasChanges) {
      let result = await ApiGateway.project.editProject(formData);
      
      let updatedProperties = ["last_updated"]
      let updatedChanges = {last_updated: result.last_updated}
      
      if(data.image){
        updatedProperties.push("thumbImageUrl")
        updatedProperties.push("fullImageUrl")
        updatedProperties.push("image_name")
        updatedChanges.thumbImageUrl = result.thumbImageUrl;
        updatedChanges.fullImageUrl = result.fullImageUrl;
        updatedChanges.image_name = result.image_name
      }
      if(data.description){
        updatedProperties.push("description")
        updatedChanges.description = data.description
      }
      if(data.name){
        updatedProperties.push("name")
        updatedChanges.name = data.name
      }
      
      let updatedProject = await this.updateProjectData(updatedProperties, updatedChanges)
      
      setStorage("update", updatedProject, "indexedDB", "indexedDB")
      
      ModalStore.setModalLoading(false)
      this.isLoading = false
      return result;
    } else {
      ModalStore.setModalLoading(false)
      this.isLoading = false
      console.log('No changes detected, no request sent.');
      return null;
    }
  }
  
  async createProject(data) {
    if (this.isLoading) return;
    this.isLoading = true;
    ModalStore.setModalLoading(true);
    
    const formData = new FormData();
    
    formData.append('name', data.values.name);
    formData.append('description', data.values.description || "");
    formData.append('isPublic', data.values.isPublic || false);
    
    if (data.values.image) {
      formData.append('image', data.values.image[0].originFileObj);
    }
    
    let result = await ApiGateway.project.createProject(formData); // Update the API method to handle formData if necessary
    if(result.success){
      let newProject = {
        project_id: result.project_id,
        name: data.values.name,
        image_name: result.image_name,
        fullImageUrl: data.values.image ? result.fullImageUrl : "",
        thumbImageUrl: data.values.image ? result.thumbImageUrl : "",
        description: data.values.description || ""
      };
      
      await setStorage("add", newProject, "indexedDB", "indexedDB");
      this.projects.push(newProject);
    }
    
    ModalStore.setModalLoading(false);
    this.isLoading = false;
    return result;
  }
  
  async addImage(data){
    if(this.isLoading) return
    this.isLoading = true;
    ModalStore.setModalLoading(true)
    const formData = new FormData();
    
    formData.append('image', data.values.image[0].originFileObj);
    let result = await ApiGateway.project.addProjectImage(formData, this.selectedProject.project_id);
    
    let updatedProject = {
      project_id: this.selectedProject.project_id,
      name: this.selectedProject.name,
      image_name: result.image_name,
      thumbImageUrl: result.thumbImageUrl,
      fullImageUrl: result.fullImageUrl,
      description: this.selectedProject.description,
      
    }
    
    this.selectedProject = updatedProject;
    setStorage("update", updatedProject, "indexedDB", "indexedDB")
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async getLatestProjects(){
    if(this.isLoading) return;
    this.isLoading = true;
    let result = await ApiGateway.project.getLatestProjects();
    this.setLatestProjects(result.result.latestCreatedProjects, result.result.latestUpdatedProjects)
    this.isLoading = false;
  }
  
  setLatestProjects(latestCreated, latestUpdated){
    this.latestUpdatedProjects = latestUpdated;
    this.latestCreatedProjects = latestCreated
  }
  
  
  async inviteUserToProject(values) {
    if(this.isLoading) return
    this.isLoading = true;
    ModalStore.setModalLoading(true)
    const data = {
      userToInvite: values.values.userToInvite,
      projectRole: values.values.projectRole
    }
    
    let result = await ApiGateway.project.inviteUserToProject(data, this.selectedProject.project_id);
    ModalStore.setModalLoading(false)
    this.isLoading = false;
    return result;
  }
  
  async acceptProjectInvitation(token, onSuccessCallback) {
    if(this.isAccepting) return
    this.isAccepting = true
    const data = {
      token
    }
    
    let result = await ApiGateway.project.acceptProjectInvitation(data);
    this.isAccepting = false;
    if (result.success && onSuccessCallback) {
      onSuccessCallback();
    }
  }
  
  async updateProjectData(updates, changes) {
    for(const update of updates){
      this.selectedProject[update] = changes[update]
    }
    return this.selectedProject
  }
  
  async getProjectUsers(projectId, user){
    if(this.isFetchingUsers){
      return
    }
    this.isFetchingUsers = true
    let result = await ApiGateway.project.getProjectUsers(projectId, user);
    this.projectUsers = result.users
    
    this.isFetchingUsers = false;
  }

  setSelectedUser(userId){
    this.selectedUser = userId
  }
  
  setSelectedInvitation(invitationId){
    this.selectedInvitation = invitationId;
  }
  
  async removeUser(){
    if(this.isLoading) return
    this.isLoading = true;
    ModalStore.setModalLoading(true)
    let result = await ApiGateway.project.removeUser(this.selectedProject.project_id, this.selectedUser);
    ModalStore.setModalLoading(false)
    this.isLoading = false;
    return result;
  }
  
  async editUserRole(values){
    if(this.isLoading) return
    this.isLoading = true;
    ModalStore.setModalLoading(true)
    let data = {newRole: values.values.projectRole}
    let result = await ApiGateway.project.editUserRole(data, this.selectedProject.project_id, this.selectedUser);
    
    const userIndex = this.projectUsers.findIndex(user => user.user_id === this.selectedUser);
    if(userIndex !== -1) {
      this.projectUsers[userIndex].role = result.newRoleName;
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false;
    return result;
  }
  
  async getProjectHistory(){
    if(this.isFetching){
      return
    }
    this.isFetching = true
    let result = await ApiGateway.project.getProjectHistory(this.selectedProject.project_id);
    this.projectHistory = result.history;
    this.isFetching = false
  }
  
  async getInvitedUsers(status, user){
    if(this.isFetchingInvitations){
      return
    }
    this.isFetchingInvitations = true
    this.projectInvitations = [];
    let result = await ApiGateway.project.getInvitedUsers(this.selectedProject.project_id, status, user);
    this.projectInvitations = result.invitations
    this.isFetchingInvitations = false
    return result;
  }
  
  async cancelInvitation(){
    if(this.isLoading){
      return
    }
    this.isLoading = true
    ModalStore.setModalLoading(true)
    let result = await ApiGateway.project.cancelInvite(this.selectedProject.project_id, this.selectedInvitation);
    if(result.success){
      this.projectInvitations = this.projectInvitations.filter(invitations => invitations.invitation_id != this.selectedInvitation);
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async leaveProject(){
    if(this.isLoading){
      return
    }
    this.isLoading = true
    ModalStore.setModalLoading(true)
    let result = await ApiGateway.project.leaveProject(this.selectedProject.project_id);
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async deleteProject(){
    if(this.isLoading){
      return
    }
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    const result = await ApiGateway.project.deleteProject(this.selectedProject.project_id);
    
    this.selectedProject.is_deleted = 1;
    
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async getDeleted(){
    if(this.isFetchingDeleted) return;
    this.isFetchingDeleted = true
    
    const result = await ApiGateway.project.getDeleted();
   
    this.deletedProjects = result.projects;
    
    this.isFetchingDeleted = false
  }
  
  async restoreProject(){
    if(this.isLoading) return;
    this.isLoading = true
    try {
      const result = await ApiGateway.project.restoreProject(this.selectedProject.project_id);
      
      runInAction(() => {
        if (result.success) {
          this.deletedProjects = this.deletedProjects.filter(project => project.project_id !== this.selectedProject.project_id);
          this.selectedProject = null;
        }
        ModalStore.setModalLoading(false);
        this.isLoading = false;
      });
      
      return result;
    } catch (error) {
      runInAction(() => {
        ModalStore.setModalLoading(false);
        this.isLoading = false;
      });
      return { success: false};
    }
  }
  async permDeleteProject(){
    if(this.isLoading) return;
    this.isLoading = true
    try {
      const result = await ApiGateway.project.permDeleteProject(this.selectedProject.project_id);
      
      runInAction(() => {
        if (result.success) {
          this.deletedProjects = this.deletedProjects.filter(project => project.project_id !== this.selectedProject.project_id);
          this.selectedProject = null;
        }
        ModalStore.setModalLoading(false);
        this.isLoading = false;
      });
      
      return result;
    } catch (error) {
      runInAction(() => {
        ModalStore.setModalLoading(false);
        this.isLoading = false;
      });
      return { success: false};
    }
  }
  
  setSelectedProject(project){
    this.selectedProject = project
  }
  
  async addFile(values){
    if(this.isAddingFiles) return;
    this.isAddingFiles = true;
    ModalStore.setModalLoading(true)
    
    const result = await ApiGateway.project.addFile(this.selectedProject.project_id ,values);
    
    ModalStore.setModalLoading(false)
    this.isAddingFiles = false;
  }
  
  async addNote({values}){
    if(this.isAddingNote) return;
    this.isAddingNote = true;
    ModalStore.setModalLoading(true)
    
    const result = await ApiGateway.project.addNote(this.selectedProject.project_id, values);
    
    ModalStore.setModalLoading(false)
    this.isAddingNote = false;
    return result;
  }
  
  async editNote({values}){
    if(values.note === this.currentNote){
      console.log('No changes detected, no request sent.');
      return
    }
    if(this.isAddingNote) return;
    this.isAddingNote = true;
    ModalStore.setModalLoading(true)
    
    const result = await ApiGateway.project.editNote(this.selectedProject.project_id, values);
    
    if(result.success){
      this.currentNote = values.note
    }
    
    ModalStore.setModalLoading(false)
    this.isAddingNote = false;
    return result;
  }
  
  async getNote(){
    if(this.isFetching) return;
    this.isFetching = true;
    ModalStore.setModalLoading(true)
    
    const result = await ApiGateway.project.getNote(this.selectedProject.project_id);
    this.currentNote = result.note;
    
    ModalStore.setModalLoading(false)
    this.isFetching = false;
    return result;
  }
  
  reset() {
    this.projects = [];
    this.selectedProject = {};
    this.projectsLoaded = false;
  }
}

export default new ProjectStore()