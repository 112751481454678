import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import ProjectStore from "../../../shared/stores/ProjectStore";
import {useQuery} from "../../../shared/utils/useQuery";
import {useNavigate} from "react-router-dom";
import CenteredBox from "../../../shared/components/CenteredBox";
import TitlePrimary from "../../../shared/components/text/TitlePrimary";
import translation from "../../../shared/utils/translation";
import ParagraphBold from "../../../shared/components/text/ParagraphBold";
import ParagraphPrimary from "../../../shared/components/text/ParagraphPrimary";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 48px;
`
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center !important;
`
const TitleWrapper = styled.div`
  margin: 10px 0 10px 0;
`

const ProjectAcceptInvitationPage = () => {
  const queryParams = useQuery();
  const verificationToken = queryParams.get("token");
  const navigate = useNavigate();
  
  useEffect(() => {
    acceptInvitation();
  }, []);
  
  const acceptInvitation = async() => {
    await ProjectStore.acceptProjectInvitation(verificationToken, () => {
      setTimeout(() => {
        navigate('/');
      }, 5000);
    })
  }
  
  return (
    <Wrapper>
      <CenteredBox>
        <ColumnBox>
          <TitleWrapper>
            <TitlePrimary small={true}>{translation("common.accepted")}</TitlePrimary>
          </TitleWrapper>
          <ParagraphBold>{translation("project.invitation.accepted")}</ParagraphBold>
        </ColumnBox>
      </CenteredBox>
    </Wrapper>
  )
}

export default observer(ProjectAcceptInvitationPage);