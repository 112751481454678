import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import CenteredBox from "../../shared/components/CenteredBox";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import ParagraphPrimary from "../../shared/components/text/ParagraphPrimary";
import ParagraphBold from "../../shared/components/text/ParagraphBold";
import {useEffect} from "react";
import UserStore from "../../shared/stores/UserStore";
import {useQuery} from "../../shared/utils/useQuery";
import {useNavigate} from "react-router-dom";
import GroupStore from "../../shared/stores/GroupStore";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 48px;
`
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center !important;
`
const TitleWrapper = styled.div`
  margin: 10px 0 10px 0;
`

const AcceptGroupInvitePage = () => {
  const queryParams = useQuery();
  const verificationToken = queryParams.get("token");
  const navigate = useNavigate();
  
  useEffect(() => {
    verifyInvitation();
  }, []);
  
  const verifyInvitation = async() => {
    await GroupStore.acceptInvitation(verificationToken, () => {
      setTimeout(() => {
        navigate('/');
      }, 5000);
    })
  }
  
  return (
    <Wrapper>
      <CenteredBox>
        <ColumnBox>
          <TitleWrapper>
            <TitlePrimary small={true}>{translation("common.welcome")}</TitlePrimary>
          </TitleWrapper>
          <ParagraphBold >{translation("verify.paragraph.emailVerified")}</ParagraphBold>
          <ParagraphPrimary margin={"10px 0 10px 0"}>{translation( "verify.paragraph.thankYou")}</ParagraphPrimary>
        </ColumnBox>
      </CenteredBox>
    </Wrapper>
  )
}

export default observer(AcceptGroupInvitePage)