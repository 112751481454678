import { makeAutoObservable } from "mobx";
import { ModalContent } from "../utils/ModalContent";

class ModalStore {
  modalVisible = false;
  modal = [];
  stepContent = [];
  currentStep = 0;
  modalTopic = "";
  isLoading = false

  constructor() {
    makeAutoObservable(this);
  }
  
  async resetModal() {
    this.modalVisible = false;
    this.modal = [];
    this.currentStep = 0;
    this.stepContent = [];
  }

  async hideModal() {
    this.resetModal();
  }

  async displayModal() {
    this.modalVisible = true;
  }

  async getModalData() {
    if (this.modal.length < 1) {
      return false;
    }
    return this.modal[this.modal.length - 1];
  }

 async addModal({ modalTopic, modalName }) {
    const modalContent = ModalContent[modalTopic][modalName];
    this.modalTopic = modalTopic;
    
    if (modalName === "signIn" || modalName === "signUp") {
      this.resetModal();
    }
    if (!modalContent) {
      console.error("No modal found");
      return;
    }

    if (modalContent.multiStep) {
      this.stepContent.push(modalContent.steps);
    }

    this.modal.push(modalContent);

    this.displayModal();
  }

  async continueModal() {
    this.currentStep = this.currentStep + 1;
  }
  async updateModal(update, value){
    this.modal[0][update] = value;
  }
  
  async setModalLoading(loading){
    this.isLoading = loading
  }

 async returnModal() {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
      return;
    }
    this.modal.pop();
    if (this.modal.length < 1) {
      this.hideModal();
    }
  }
}

export default new ModalStore();