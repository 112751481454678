import * as React from "react"
import styled from "styled-components";
import {AiFillEdit} from "react-icons/ai";


const EditIcon = styled(AiFillEdit)`
  font-size: ${p => p.small ? 18 : 26}px;
  cursor: pointer;
`

const EditIconFillPen = ({className, small = false, bold = false, onClick}) => {
    return (
        <EditIcon small={small.toString()} bold={bold} onClick={onClick} className={className}/>
    )
}

export default EditIconFillPen