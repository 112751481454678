import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import ProfileStore from "../../../../shared/stores/ProfileStore";
import {useEffect} from "react";
import ModalStore from "../../../../shared/stores/ModalStore";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";

const ProfileBannerContainer = styled.div`
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
     height: 150px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
`

const ProfileBannerComponent = () => {
  
  const {userProfileBanner, isFetchingProfileBanner} = ProfileStore
  
  useEffect(() => {
    if (!userProfileBanner) {
      ProfileStore.fetchProfileBanner();
    }
  }, [userProfileBanner]);
  
  const addProfileBanner = () => {
    ModalStore.addModal({ modalTopic: "profile", modalName: "addProfileBanner" });
  };
  const editProfileBanner = () => {
    ModalStore.addModal({ modalTopic: "profile", modalName: "editProfileBanner" });
  };
  
  if(isFetchingProfileBanner){
    return <LoadingSpinner/>
  }
  
  return (
      <ProfileBannerContainer
        onClick={() => !userProfileBanner ? addProfileBanner() : editProfileBanner()}
        style={{
          backgroundImage: userProfileBanner.length > 0 ? `url(${userProfileBanner})` : undefined,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundColor: userProfileBanner.length > 0 ? undefined : "#FFFFF4",
        }}
        />
  )
}

export default observer(ProfileBannerComponent)
