import {Row} from "antd";
import ImageNameSection from "../../../../shared/components/sections/ImageNameSection";
import ProjectStore from "../../../../shared/stores/ProjectStore";
import SectionComponentSection from "../../../../shared/components/sections/SectionComponentSection";
import DescriptionSection from "../../../../shared/components/sections/DescriptionSection";
import React, {useCallback} from "react";
import ModalStore from "../../../../shared/stores/ModalStore";
import FileSection from "../../../../shared/components/sections/FileSection";

const ProjectOverviewContent = ({currentProject}) => {
  
  const addImageOnClick = useCallback(() => {
    ModalStore.addModal({modalTopic: "project", modalName: "addImage"});
  }, []);
  
  return(
    <>
      <Row>
        <ImageNameSection addImageOnClick={currentProject.canEdit && addImageOnClick} object={ProjectStore.selectedProject} type={"project"}/>
      </Row>
      <Row>
        <SectionComponentSection canEdit={currentProject.is_deleted === 0 && currentProject.canEdit} project={currentProject} />
      </Row>
      <Row>
        <DescriptionSection object={currentProject} />
      </Row>
    </>
  )
}

export default ProjectOverviewContent