import styled from 'styled-components';
import {ADMINISTRATOR_ROLES} from "../../../../../../shared/utils/Constants";
import GroupStore from "../../../../../../shared/stores/GroupStore";
import { IoPersonRemoveSharp } from "react-icons/io5";
import { AiFillEdit } from "react-icons/ai";
import translation from "../../../../../../shared/utils/translation";

const MemberCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
`;

const MemberInfo = styled.div`
  margin: 5px 0;
`;

const ActionWrapper = styled.div`
  display:flex;
  justify-content: space-between;
`

const InfoLabel = styled.span`
  font-weight: bold;
`;

const ActionButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const RemoveUserIcon = styled(IoPersonRemoveSharp)`
  font-size: 20px;
  cursor: pointer;
  margin-left: 25px;
`

const EditUserIcon = styled(AiFillEdit)`
  font-size: 20px;
  cursor: pointer;
`

const MemberCard = ({members, editMemberOnClick, removeMemberOnClick}) => {
  const { currentGroup } = GroupStore
  
  return(
    <>
      {
        members.map((member) => (
          <MemberCardWrapper key={member.user_id}>
            <MemberInfo><InfoLabel>{translation("common.name")}:</InfoLabel> {member.name}</MemberInfo>
            <MemberInfo><InfoLabel>{translation("common.email")}:</InfoLabel> {member.email}</MemberInfo>
            <MemberInfo><InfoLabel>{translation("common.role")}:</InfoLabel> {member.role_name}</MemberInfo>
            {
              member.role_name != "owner" &&
              <ActionWrapper><InfoLabel>{translation("common.actions")}:</InfoLabel>
                <ActionButtonContainer>
                  {ADMINISTRATOR_ROLES.includes(currentGroup.role_name) && <>
                    <EditUserIcon onClick={() => editMemberOnClick(member.user_id)} />
                    <RemoveUserIcon onClick={() => removeMemberOnClick(member.user_id)} />
                  </>}
                </ActionButtonContainer>
              </ActionWrapper>
            }
        
          </MemberCardWrapper>
        ))
      }
    </>
  )
}

export default MemberCard