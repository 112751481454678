import React from "react"
import styled from "styled-components"
import {gutter} from "../utils/vars";

const SpacerComponent = styled.div`
  margin: auto ${gutter}px;
`

const Spacer = ({}) => {
  return <SpacerComponent />
}

export default Spacer
