export const gutter = 12
export const gridWidth = 1140

export const rowHeight = 55
export const sectionMargin = "50px 0"

// fonts
export const mainFont = "'Montserrat', sans-serif;"
export const semi = "600"
export const bold = "bold"
export const extraBold = 800
export const smallerFontSize = "14px"

// colors

export const formHoverColor = "#C8C8C8";
export const lightBgHover = "#d3d3d3"
export const baseButtonBackgroundColor = "#aac4e2"; //Base color
export const baseButtonHoverColor = "#6f9bce" //Hover base color
export const whiteButtonBackgroundColor = "white";
export const whiteButtonHoverColor = "lightgray"
export const redButtonBackgroundColor = "red"
export const redButtonHoverColor = "darkred"
export const modalBackgroundColor = "white"
export const CustomInputBackgroundColor = "#E8E8E8"
export const CustomInputHoverBackgroundColor = "whitesmoke"

export const textHoverColor = "lightgray"
export const linkFocusColor = "#aac4e2"

export const ErrorColor = "red";


export const menuTextColor = "white";
export const menuBackgroundColor = "#31312F"
export const dangerColor = "#CD0C22"


export const darkFocusBorder = "2px solid black";
export const lightFocusBorder = "2px solid white";

export const darkHoverColor = "gray"
export const lightHoverColor = "whitesmoke"

// Placements

export const notificationPlacement = "bottomRight"

// Shadows

export const blackShadow = "0.05em 0 black, 0 0.05em black, -0.05em 0 black, 0 -0.05em black, -0.05em -0.05em black, -0.05em 0.05em black, 0.05em -0.05em black, 0.05em 0.05em black;"
export const whiteShadow = "0.05em 0 white, 0 0.05em white, -0.05em 0 white, 0 -0.05em white, -0.05em -0.05em white, -0.05em 0.05em white, 0.05em -0.05em white, 0.05em 0.05em white;"
export const primaryShadow = "0.05em 0 #FFFFF4, 0 0.05em #FFFFF4, -0.05em 0 #FFFFF4, 0 -0.05em #FFFFF4, -0.05em -0.05em #FFFFF4, -0.05em 0.05em #FFFFF4, 0.05em -0.05em #FFFFF4, 0.05em 0.05em #FFFFF4;"

// Effects
export const animationTransition = "0.2 ease-in-out"
export const rotateTransform = "transform: rotate(90deg)"
