import React from "react"
import styled from "styled-components"
import {baseButtonBackgroundColor, baseButtonHoverColor} from "../../utils/vars";

const Text = styled.span`
  display: flex;
  flex-direction: row;
  position: ${p => p.labelInside ? "absolute" : "relative"} ; 
  top: 5px;
  left: 5px;
  font-size: ${p => p.small ? 14 : 18}px;
  line-height: 1.25;
  font-weight: 700;
  overflow-wrap: break-word;
  margin: ${p => p.margin ? p.margin : 0};
  color: ${baseButtonHoverColor};
  pointer-events: none;

`

const FormLabel = ({ children, margin = false, small = false, labelInside = true, ...rest }) => {
  return(
      <Text margin={margin} small={small.toString()} labelInside={labelInside} {...rest}>
        {children}
      </Text>
  )
}

export default FormLabel