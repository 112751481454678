import React, {forwardRef, useState} from "react"
import styled from "styled-components"
import {gutter} from "../../utils/vars";
import {Input as AntdInput, Form, Switch, Select} from "antd"
import TextEditor from "../TextEditor";

const InputElem = styled(AntdInput)`
  background-color: inherit;
  width: 100%;
  text-overflow: ellipsis;
  -webkit-appearance: textfield;
  padding: ${gutter}px;
  border: none;
`
const InputTextAreaElem = styled(AntdInput.TextArea)`
  background-color: inherit;
  width: 100%;
  text-overflow: ellipsis;
  padding: ${gutter}px;
  border: none;
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
`

const FormInput = forwardRef((props, ref) => {
    const {
        validateTrigger = "onBlur",
        form,
        maxLength,
        onChange,
        showCount,
        rules,
        inputType,
        name,
        textFieldSize,
        required,
        initialValue,
        ...rest
    } = props;
    
    const [checked, setChecked] = useState(initialValue ? initialValue : false)
    
    return (
      <Form.Item
        name={name}
        initialValue={initialValue}
        rules={rules}
        validateTrigger={validateTrigger}
      >
          {
              inputType === "textarea" ?
                <InputTextAreaElem
                  {...rest}
                  showCount={showCount}
                  onChange={!rest.disabled ? onChange : undefined}
                  autoSize={textFieldSize}
                  ref={ref}
                  value={initialValue}
                />
                :
                inputType === "switch" ?
                  <Switch
                    checked={checked}
                    defaultChecked={initialValue}
                    onChange={(value) => setChecked(value)}
                    ref={ref}
                  />
                  :
                  inputType === "textEditor" ?
                    <TextEditor
                      initialValue={initialValue}
                      name={name}
                      form={form}
                      maxLength={maxLength}
                    />
                    :
                    <InputElem
                      {...rest}
                      onChange={!rest.disabled ? onChange : undefined}
                      ref={ref}
                      type={inputType}
                      value={initialValue}
                    />
          }
      </Form.Item>
    );
});

export default FormInput;