import {notification} from "antd";
import {notificationPlacement} from "./vars";
import translation from "./translation";

const Notifications = (status, title, description, extraTitle, extraDescription) => {
  notification[status]({
    message: translation(title, extraTitle),
    description: translation(description, extraDescription),
    placement: notificationPlacement,
  });
}
export default Notifications;
