import { createGlobalStyle } from "styled-components"
import {baseButtonBackgroundColor, formHoverColor} from "../utils/vars"
export default createGlobalStyle`
  html {
    --antd-wave-shadow-color: none;
  }
  html, body, #__next {
    height: 100%;
    width: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  * {
    padding: 0;
    margin: 0;
    outline: none;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    background: ${p => p.theme.primaryColor};
  }
  p{
    margin: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  input {
    border: none;
    outline: none;
    font-size: 16px;
  }
  h1 {
    font-weight: 800;
    font-size: 50px;
  }
  .ant-row {
    margin: 0;
  }
  .ant-input:hover{
    border-color: inherit;
  }
  .ant-input:focus{
    height: inherit;
  }

`