import styled from 'styled-components';
import {ADMINISTRATOR_ROLES} from "../../../../../../shared/utils/Constants";
import GroupStore from "../../../../../../shared/stores/GroupStore";
import translation from "../../../../../../shared/utils/translation";
import React from "react";
import { IoPersonRemoveSharp } from "react-icons/io5";
import { AiFillEdit } from "react-icons/ai";
import UserStore from "../../../../../../shared/stores/UserStore";
import LoadingSpinner from "../../../../../../shared/components/LoadingSpinner";

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: center;

`;

const Tr = styled.tr`
  ${p => p.isCurrentUser && `
    background-color: #ECECEC;
  `}
`

const Th = styled.th`
    background-color: #f4f4f4;
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const RemoveUserIcon = styled(IoPersonRemoveSharp)`
    font-size: 20px;
    cursor: pointer;
`

const EditUserIcon = styled(AiFillEdit)`
    font-size: 20px;
    cursor: pointer;
`

const ActionButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const MemberTable = ({members, editMemberOnClick, removeMemberOnClick}) => {
  const { currentGroup } = GroupStore
  
  return(
    <Table>
      <thead>
      <tr>
        <Th>{translation("common.name")}</Th>
        <Th>{translation("common.email")}</Th>
        <Th>{translation("common.role")}</Th>
        <Th>{translation("common.actions")}</Th>
      </tr>
      </thead>
      <tbody>
      {members.map((member) => (
        <Tr key={member.user_id} isCurrentUser={member.user_id === UserStore.user.user_id}>
          <Td>{member.name}</Td>
          <Td>{member.email}</Td>
          <Td>{member.role_name}</Td>
          <Td>
            {
              member.role_name != "owner" &&
              <ActionButtonWrapper>
                {ADMINISTRATOR_ROLES.includes(currentGroup && currentGroup.role_name) && <EditUserIcon onClick={() => editMemberOnClick(member.user_id)}/>}
                {ADMINISTRATOR_ROLES.includes(currentGroup && currentGroup.role_name) && <RemoveUserIcon onClick={() => removeMemberOnClick(member.user_id)}/>}
              </ActionButtonWrapper>
            }
          </Td>
        </Tr>
      ))}
      </tbody>
    </Table>
  )
}

export default MemberTable