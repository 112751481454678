import {makeAutoObservable, toJS} from "mobx";
import ApiGateway from "../api/ApiGateway";
import UserStore from "./UserStore";
import {setStorage} from "../../services/setStorage";
import ModalStore from "./ModalStore";
import {setUserLoggedInCookie} from "./CookieStore";

class ProfileStore {
  
  userData = {}
  userProfilePicture = "";
  userProfileBanner = "";
  
  isLoading = false
  isFetchingUserData = false
  isFetchingProfilePicture = false
  isFetchingProfileBanner = false
  
  constructor() {
    makeAutoObservable(this);
  }
  
  async fetchUserDetails() {
    if(this.isFetchingUserData) return
    this.setFetchingUserData(true)
    let result = await ApiGateway.profile.getUserData();
    this.setUserData(result.result[0])
    this.setFetchingUserData(false)
  }
  
  setFetchingUserData(value){
    this.isFetchingUserData = value
  }
  
  setUserData(data){
    this.userData = data;
  }
  
  async fetchProfilePicture() {
    if (this.isFetchingProfilePicture) return;
    
    const cachedData = localStorage.getItem('userProfilePictureData');
    const now = new Date().getTime();
    
    if (cachedData) {
      const { url, expiry } = JSON.parse(cachedData);
      
      if (url && expiry && now < expiry) {
        this.setUserProfilePicture(url);
        return;
      }
    }
    
    this.isFetchingProfilePicture = true;
    try {
      let result = await ApiGateway.profile.fetchProfilePicture();
      if (result.url) {
        this.setUserProfilePicture(result.url)
        
        const expiryTime = now + (30 * 60 * 1000);
        setStorage("userProfilePictureData", JSON.stringify({ url: result.url, expiry: expiryTime }), "localStorage", "preference")
      }
    } catch (error) {
      console.error("Failed to fetch profile picture:", error);
    } finally {
      this.isFetchingProfilePicture = false;
      
    }
  }
  
  setUserProfilePicture(url){
    this.userProfilePicture = url
  }
  
  async fetchProfileBanner(){
    if(this.isFetchingProfileBanner) return;
    
    const cachedData = localStorage.getItem('userProfileBannerData');
    const now = new Date().getTime();
    
    if (cachedData) {
      const { url, expiry } = JSON.parse(cachedData);
      
      if (url && expiry && now < expiry) {
        this.setUserBanner(url);
        return;
      }
    }
    
    this.isFetchingProfileBanner = true;
    try {
      let result = await ApiGateway.profile.fetchProfileBanner();
      if (result.url) {
        this.setUserBanner(result.url);
        
        const expiryTime = now + (30 * 60 * 1000);
        setStorage("userProfileBannerData", { url: result.url, expiry: expiryTime }, "localStorage", "preference")
        
      }
    } catch (error) {
      console.error("Failed to fetch profile banner:", error);
    } finally {
      this.isFetchingProfileBanner = false;
    }
  }
  
  setUserBanner(url){
    this.userProfileBanner = url
  }
  
  async editProfileBanner(values) {
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    const formData = new FormData();
    formData.append('image', values.values.image ? values.values.image[0].originFileObj : undefined)
    
    let result = await ApiGateway.profile.editProfileBanner(formData);
    
    if (result.success && result.bannerUrl) {
      const now = new Date().getTime();
      const expiryTime = now + (30 * 60 * 1000);
      
      setStorage("userProfileBannerData", JSON.stringify({ url: result.url, expiry: expiryTime }), "localStorage", "preference")
      
      this.userProfileBanner = result.bannerUrl;
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async editProfilePicture(values) {
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    const formData = new FormData();
    formData.append('image', values.values.image ? values.values.image[0].originFileObj : undefined)
    let result = await ApiGateway.profile.editProfilePicture(formData);
    
    if (result.success && result.profileUrl) {
      const now = new Date().getTime();
      const expiryTime = now + (30 * 60 * 1000);
      
      setStorage("userProfilePictureData", JSON.stringify({ url: result.url, expiry: expiryTime }), "localStorage", "preference")
      
      this.setUserProfilePicture(result.profileUrl);
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result;
  }
  
  async updateUserDetails(values){
    if(this.isLoading) return
    this.isLoading = true
    let changes = {}
    
    Object.keys(values).forEach(key => {
      if (toJS(this.userData).hasOwnProperty(key) && values[key] !== toJS(this.userData[key])) {
        changes[key] = values[key];
      }
    });
    
    let result = await ApiGateway.profile.updateUserData(changes);
    
    UserStore.setUserData(result.token)
    setUserLoggedInCookie(result.token)
    
    this.isLoading = false
  }
  
  async verifyEmailUpdateForm(values, token, onSuccessCallback){
    if(this.isLoading) return
    this.isLoading = true
    let data = {
      password: values.password,
      token: token
    }
    
    let result = await ApiGateway.profile.verifyUpdateUserEmail(data);
    
    if(onSuccessCallback && result.success){
      onSuccessCallback()
    }
    this.isLoading = false
  }
  
  async updateUserPassword(values){
    if(this.isLoading) return
    this.isLoading = true
    await ApiGateway.profile.updateUserPassword(values);
    this.isLoading = false
  }
  
  async verifyUpdateUserPassword(values, token, onSuccessCallback){
    if(this.isLoading) return
    this.isLoading = true
    let data = {
      password: values.password,
      token: token
    }
    
    let result = await ApiGateway.profile.verifyUpdateUserPassword(data);
    if(result.success){
      UserStore.signOut()
    }
    if(onSuccessCallback && result.success){
      onSuccessCallback()
    }
    this.isLoading = false
  }
  
  async addProfilePicture(values){
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    const formData = new FormData();
    formData.append('image', values.values.image ? values.values.image[0].originFileObj : undefined)
    let result = await ApiGateway.profile.addProfilePicture(formData);
    
    if (result.success && result.profileUrl) {
      const now = new Date().getTime();
      const expiryTime = now + (30 * 60 * 1000);
      
      setStorage("userProfilePictureData", JSON.stringify({ url: result.url, expiry: expiryTime }), "localStorage", "preference")
      
      this.setUserProfilePicture(result.profileUrl);
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result
  }
  
  async addProfileBanner(values) {
    if(this.isLoading) return
    this.isLoading = true
    ModalStore.setModalLoading(true)
    
    const formData = new FormData();
    formData.append('image', values.values.image ? values.values.image[0].originFileObj : undefined)
    let result = await ApiGateway.profile.addProfileBanner(formData);
    
    if (result.success && result.bannerUrl) {
      const now = new Date().getTime();
      const expiryTime = now + (30 * 60 * 1000);
      
      setStorage("userProfileBannerData", JSON.stringify({ url: result.url, expiry: expiryTime }), "localStorage", "preference")
      
      this.userProfileBanner = result.bannerUrl;
    }
    ModalStore.setModalLoading(false)
    this.isLoading = false
    return result
  }
}

export default new ProfileStore();